import React from 'react'
import LoginForm from '@mmd/block-app/login-form'
import styled from 'styled-components'

export const FormTitle = styled.div`
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 8px;
`

export const FormSubheading = styled.div`
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 4px;
`

const RecovibLoginForm = (props) => {
	const { whenAuthenticateAttempt, authError } = props
	return <LoginForm {...{ whenAuthenticateAttempt, authError }} Skins={{ FormTitle, FormSubheading }} />
}

export default RecovibLoginForm
