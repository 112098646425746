import { expandIsoString, periodic } from '@mmd/fns/datetime-iso/isoDateString'
import { tlmTime } from '@mmd/fns/tlm/tlm'
import { getRows } from '@mmd/fns/tlm/utils'
import { getBurstChannels, getBurstData, isBurst } from './utils'

export const burstAsTsv = (tlm) => {
  if (isBurst(tlm)) {
    const { adc_fs } = tlm
    const channels = getBurstChannels(tlm)
    const ms = parseInt(adc_fs, 10)
    const isotime = expandIsoString(tlmTime(tlm))
    const addPeriods = periodic(isotime.dt, ms)
    const rows = getRows(getBurstData(tlm), channels, { decimals: 5 }).map((d, i) => {
      return [addPeriods(i), ...d]
    })
    return {
      rows,
      fields: [
        'isotime',
        ...channels.map(({ name, unit }) => {
          return `${name} (${unit})`
        }),
      ],
    }
  }
}

export const burstAsDownload = (channels) => {
  const rowsD = channels.reduce((acc, d, cIdx) => {
    const { data } = d
    data.forEach((d, i) => {
      const [v, t] = d
      if (!acc[t]) {
        acc[t] = [t]
      }
      acc[t][cIdx + 1] = v
    })
    return acc
  }, {})
  const rows = Object.values(rowsD)
  const fields = ['ms', ...channels.map((d) => d.name)]
  return { rows, fields }
}
