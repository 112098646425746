export const lineColors = ['#28A138', '#F9971E', '#3B5998']

export const identity = d => d

export const defaultTooltip = () => {
  return {
    enabled: true,
    interesect: false,
    fillSeriesColor: false,
    style: { fontSize: '0.6rem' },
    x: {
      show: true,
      format: 'dd MMM HH:mm:ss',
      formatter: undefined
    },
    y: {
      title: {
        formatter: identity
      },
      formatter: undefined
    }
  }
}

export const xTimeseries = () => {
  return {
    type: 'datetime',
    labels: {
      show: true,
      formatter: undefined
    },
    tickAmount: 10,
    title: {}
  }
}

export const yNumeric = () => {
  return {
    min: 0,
    max: undefined,
    labels: {
      show: true,
      formatter: undefined
    },
    title: {}
  }
}

export const buildOptions = config => {
  const options = {
    chart: {
      id: config.id || 'apex-chart',
      toolbar: {
        autoSelected: 'zoom',
        show: true
      },
      fontFamily: 'Roboto Condensed, Helvetica, Arial, sans-serif',
      zoom: {
        enabled: true,
        type: 'xy'
      }
    },
    brush: {
      enabled: false
    },

    title: {
      text: config.title,
      align: 'middle',
      offsetX: 0
    },
    animations: {
      enabled: false
    },
    markers: {
      size: 0
    },
    colors: lineColors,
    stroke: {
      width: 1
    },
    tooltip: config.tooltip || defaultTooltip(),
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    xaxis: config.xaxis || xTimeseries(),
    yaxis: config.yaxis || yNumeric(),
    annotations: {
      yaxis: []
    }
  }

  options.xaxis.title.text = config.xTitle
  options.xaxis.labels.formatter = config.xFormatter
  options.tooltip.x.formatter = config.xTooltip

  options.yaxis.title.text = config.yTitle
  options.yaxis.labels.formatter = config.yFormatter
  options.tooltip.y.formatter = config.yTooltip

  return options
}

/*

annotations: {
  position: "back",
  yaxis: [
    {
      label: {
        text: " "
      },
      y: 8200,
      y2: 9600,
      fillColor: "#00E396"
    },
    {
      label: {
        text: " "
      },
      y: 7800,
      y2: 8200,
      fillColor: "yellow"
    }
  ]
},

annotations: {
yaxis: [
  {
    y: 8200,
    borderColor: "#00E396",
    label: {
      borderColor: "#00E396",
      style: {
        color: "#fff",
        background: "#00E396"
      },
      text: "Y Axis Annotation"
    }
  }
],
xaxis: [
  {
    // in a datetime series, the x value should be a timestamp, just like it is generated below
    x: new Date("11/17/2017").getTime(),
    strokeDashArray: 0,
    borderColor: "#775DD0",
    label: {
      borderColor: "#775DD0",
      style: {
        color: "#fff",
        background: "#775DD0"
      },
      text: "X Axis Anno Vertical"
    }
  },
  {
    x: new Date("03 Dec 2017").getTime(),
    borderColor: "#FEB019",
    label: {
      borderColor: "#FEB019",
      style: {
        color: "#fff",
        background: "#FEB019"
      },
      orientation: "horizontal",
      text: "X Axis Anno Horizonal"
    }
  }
],
points: [
  {
    x: new Date("27 Nov 2017").getTime(),
    y: 8500.9,
    marker: {
      size: 6,
      fillColor: "#fff",
      strokeColor: "#2698FF",
      radius: 2
    },
    label: {
      borderColor: "#FF4560",
      offsetY: 0,
      style: {
        color: "#fff",
        background: "#FF4560"
      },

      text: "Point Annotation (XY)"
    }
  }
]
},

tooltip: {
custom: function({ series, seriesIndex, dataPointIndex, w }) {
  return (
    '<div class="custom-tooltip">' +
    '<div class="group">' +
    "<div><span>Current Week</span> <br />" +
    w.config.series[0].categories[dataPointIndex] +
    "</div>" +
    '<div class="group1">Views: ' +
    series[0][dataPointIndex] +
    "</div>" +
    "</div>" +
    '<div class="group">' +
    "<div><span>Previous Week</span> <br />" +
    w.config.series[1].categories[dataPointIndex] +
    "</div>" +
    '<div class="group2">Views: ' +
    series[1][dataPointIndex] +
    "</div>" +
    "</div>" +
    "</div>"
  );
}
},

fill: {

  type: "image",
  opacity: 0.85,
  image: {
    src: ["https://i.ibb.co/xhMYYRD/Screen-Shot-2019-03-04-at-4-45-20-PM.png"],
    width: 30,
    height: 30
  }

*/

/*
optionsMain.selection = {
  enabled: true,
  type: 'x',
  selectedPoints: [undefined, [3]], // default datapoints that should be selected automatically
  fill: {
    color: '#24292e',
    opacity: 0.1
  },
  stroke: {
    width: 1,
    color: '#24292e',
    opacity: 0.4,
    dashArray: 3
  },
  xaxis: {
    min: undefined,
    max: undefined
  },
  yaxis: {
    min: undefined,
    max: undefined
  }
}
*/

/*
optionsMain.chart.selection = {
  enabled: true,
  type: 'xy',
  fill: {
    color: '#ff00ff',
    opacity: 0.4
  },
  stroke: {
    color: '#0D47A1'
  }
}
*/
