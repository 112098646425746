const createReducer = (initialState, actionReducers) => {
  return (state, action) => {
    const { type, payload } = action
    state = state || initialState
    const fn = actionReducers[type]
    return typeof fn === 'function' ? fn(state, payload) : state
  }
}

export default createReducer
