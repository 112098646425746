import React from 'react'
import Chart from 'react-apexcharts'
import { buildOptions } from '../chart-defaults'
import { fftFrequencyAndMagnitude } from '@mmd/fns/signal-fft'

const optionsFFT = buildOptions({
  id: 'burst-ppv',
  title: 'Normalized Spectrum',
  yTitle: undefined, //'valeur F',
  xTitle: 'Frequency [Hz]',
  yTooltip: function (value) {
    return value.toFixed(5)
  },
  xFormatter: function (value) {
    return value.toFixed(1)
  },
  yFormatter: function (value) {
    return (value || 0).toFixed(2)
  },
})

const asSeries = (channels) => {
  return channels.map(({ name, data }) => {
    const { freqMag } = fftFrequencyAndMagnitude(data)
    return { name, data: freqMag }
  })
}

const Fourier = (props) => {
  const { channels } = props
  const series = asSeries(channels)

  optionsFFT.xaxis.type = 'numeric'
  return <Chart options={optionsFFT} series={series} type="line" height="360" />
}

export default Fourier
