export const table = [{
        slug: 'lho-1-vib',
        setups: [
            'recJw9zCGL5Nt5jL2',
            'recvi6K7hjyHVXte5',
            'recRjuilDzlaQJAc5',
            'rec5C7qLS5TqNTf21',
            'recwCTLr02Utv6VSP',
            'recVjDucqFnmaRfkt',
            'recSEAzZVY930Oiya',
            'recFPBVxJiksZZNok',
            'recCadj8O6EUp9coB',
            'recDhAvQ8ELGeSemH',
            'recj8FWZ78SQh6J2P',
            'recWGjntBIfHUKuuU',
            'recBHOV38yf9MhtDq',
            'rec1Eh4y1l9HdxKJb',
            'recHnZlNSSqkbU2dj',
            'recHHszEdfubcrfRO',
            'recvldM9fR51q177e',
        ],
        devices: ['rec3xFxLIWGJL6M56'],
        lenses: ['recChHriPInnrgGfd'],
        status: 'Active',
        Description: 'Building Vibrations DIN4150-3/L2 VB2 (mm/s)',
        lens_blocks: [
            'recPTfw2A21x9qOEa',
            'recPUseYNPQL9BTl5',
            'recgk2bXYBVPpfpsm',
            'reczOW6qLNtcX50ck',
            'recTxunbSD9INcIfd',
            'recydTKen4q0KJQ1y',
            'recfOZFjKdACFqWY6',
            'recFh592hkReNJyT1',
            'rec20DiBkrRT2fVWc',
        ],
        app: '{\n  "beat-name": "10A16",\n  "burst-name": "10A17",\n  "dump-name": "10A|d+",\n  "partitionKey": "[\\"10\\"]",\n  "conditions": [{\n    "before": "2020-02-24T23:00:00.000Z",\n    "beat-name": "10A16",\n    "burst-name": "10A17",\n    "dump-name": "10A|d+",\n    "partitionKey": "[\\"10\\"]",\n    "formatter": "vibretro"\n  }]\n}\n',
        configs: ['recwGQDJONJj7o0ZT', 'rec9yWxj4aItDnfya', 'recOOVn6wO7RBmTau'],
        _id: 'rec3qPv4HciTjEOHr',
    },
    {
        slug: 'lho-2-air',
        setups: [
            'recvi6K7hjyHVXte5',
            'recMLuhzWct4sjpHA',
            'recl8J4Cdxwt3IEdH',
            'recVjDucqFnmaRfkt',
            'recSEAzZVY930Oiya',
            'recFPBVxJiksZZNok',
            'recCadj8O6EUp9coB',
            'recDhAvQ8ELGeSemH',
            'recj8FWZ78SQh6J2P',
            'recWGjntBIfHUKuuU',
            'recxKPXIHR7OtcW49',
            'recq1bAZDdUNGzdqr',
            'recRjuilDzlaQJAc5',
            'recHHszEdfubcrfRO',
            'reccfvGuXmEQtb4Zb',
        ],
        devices: ['recRKt9A9OemBW2jl'],
        lenses: ['rec4B9zT5dueMZZBs'],
        status: 'Active',
        Description: 'Peak Air Pressure (Pa)',
        lens_blocks: ['recN1A94MeNEYUveo', 'recQZZkN3ygOB5yf4'],
        app: '{\n  "beat-name": "Air fast",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['rec1gTgGJM0BsP071', 'recI5ZBHgXmt90O9k'],
        _id: 'recERx3ECNc8ToSKm',
    },
    {
        slug: 'nyh-1-vib',
        setups: ['recxKPXIHR7OtcW49', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr'],
        status: 'Inactive',
        Description: 'accelerometer BottomLeft',
        _id: 'recjOVrHfGx4WT4Li',
    },
    {
        slug: 'pom-1-vib',
        setups: ['rec1Eh4y1l9HdxKJb', 'recHnZlNSSqkbU2dj', 'recxKPXIHR7OtcW49', 'recHHszEdfubcrfRO', 'recvldM9fR51q177e'],
        devices: ['recl71LQzwM9QtU4f'],
        lenses: ['rec8oMkgLRpAooIMW'],
        status: 'Active',
        Description: 'Vibrations DIN4150-3/L2 VB2 (mm/s)',
        lens_blocks: [
            'rec4XAMHeuA3zSy9b',
            'reci9oayDp5k4yfyw',
            'rece809uqKvd4Z5Qp',
            'recn7pkfesyi29X7s',
            'recvZBNJUDtnDcFbe',
            'recxAxbPqHTfxVG3d',
            'rec8LVEO6Oc2ONnEg',
            'recXhXQORrO6GFzzp',
        ],
        app: '{\n  "beat-name": "Railways",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['rec1gTgGJM0BsP071', 'rec9yWxj4aItDnfya'],
        _id: 'recvu5jV57eospwDA',
    },
    {
        slug: 'mbh-24-ac',
        setups: ['recubEWVnHVEGJX3z', 'recxKPXIHR7OtcW49'],
        status: 'Inactive',
        Description: 'Air pressure (infrasound from 2Hz to 250Hz).',
        _id: 'rec5xcVRiCs8Xplfk',
    },
    {
        slug: 'nyh-1-acc',
        setups: ['recxKPXIHR7OtcW49', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr'],
        devices: ['recz3ySgv9xvN0BMs'],
        lenses: ['rec3OHQXn3S4zP1Gl'],
        status: 'Active',
        Description: 'Peak Acceleration (g)',
        lens_blocks: ['rec2S9vOjJwGKPn0l', 'recMsdFdJVqp6RJ7N', 'recB62L0hhkNNJeY3'],
        app: '{\n  "beat-name": "steam|equal|chair|cargo",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['recsHRPxIqHFuQaz4', 'rec9yWxj4aItDnfya'],
        _id: 'recupOFUOqXIO08o2',
    },
    {
        slug: 'demo-1-vib',
        setups: [
            'recJw9zCGL5Nt5jL2',
            'recvi6K7hjyHVXte5',
            'recRjuilDzlaQJAc5',
            'rec5C7qLS5TqNTf21',
            'recwCTLr02Utv6VSP',
            'recVjDucqFnmaRfkt',
            'recSEAzZVY930Oiya',
            'recFPBVxJiksZZNok',
            'recCadj8O6EUp9coB',
            'recDhAvQ8ELGeSemH',
            'recj8FWZ78SQh6J2P',
            'recWGjntBIfHUKuuU',
            'recBHOV38yf9MhtDq',
            'rec1Eh4y1l9HdxKJb',
            'recHnZlNSSqkbU2dj',
            'recHHszEdfubcrfRO',
            'recvldM9fR51q177e',
        ],
        devices: ['rec3xFxLIWGJL6M56'],
        lenses: ['recB8ZKzjdZr3V4wr'],
        status: 'Active',
        Description: 'Warnings and alarms if vibration exceeds given levels. Periodic bursts for FFT computations ',
        lens_blocks: [
            'recnBqJ7zYcLg3tiR',
            'recOllgucunDvvBVK',
            'rec2qh8NpqEEAgljQ',
            'recKgJ7RTcFBOschy',
            'recdPSRxWfQR1mlJW',
            'recId1qBgt1RgUuIm',
            'recZmAygCMHpRw9sr',
        ],
        app: '{\n  "beat-name": "10A16",\n  "burst-name": "10A17",\n  "dump-name": "10A|d+",\n  "partitionKey": "[\\"10\\"]",\n  "exceptions": [{\n    "before": "2020-02-24T23:00:00.000Z",\n    "beat-name": "10A16",\n    "burst-name": "10A17",\n    "dump-name": "10A|d+",\n    "partitionKey": "[\\"10\\"]",\n    "formatter": "vibretro"\n  }]\n}\n',
        configs: ['recwGQDJONJj7o0ZT', 'rec9yWxj4aItDnfya'],
        _id: 'recFJoSnlbArdFIVp',
    },
    {
        slug: 'demo-2-air',
        setups: [
            'recvi6K7hjyHVXte5',
            'recMLuhzWct4sjpHA',
            'recl8J4Cdxwt3IEdH',
            'recVjDucqFnmaRfkt',
            'recSEAzZVY930Oiya',
            'recFPBVxJiksZZNok',
            'recCadj8O6EUp9coB',
            'recDhAvQ8ELGeSemH',
            'recj8FWZ78SQh6J2P',
            'recWGjntBIfHUKuuU',
            'recxKPXIHR7OtcW49',
            'recq1bAZDdUNGzdqr',
            'recRjuilDzlaQJAc5',
            'recHHszEdfubcrfRO',
            'reccfvGuXmEQtb4Zb',
        ],
        devices: ['recRKt9A9OemBW2jl'],
        lenses: ['recY9ILAwR4dApQDH'],
        status: 'Active',
        Description: 'DIN4150-3/L2 with 1-250Hz bandpass filter ',
        lens_blocks: ['rec2xxeJS5sYLN4Gm', 'recVduxiQMdsMZahz'],
        app: '{\n  "beat-name": "Air fast",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['rec1gTgGJM0BsP071', 'recI5ZBHgXmt90O9k'],
        _id: 'rec9AgBsXNRy5DdtD',
    },
    {
        slug: 'cem-1-vib',
        setups: [
            'recJw9zCGL5Nt5jL2',
            'recvi6K7hjyHVXte5',
            'recRjuilDzlaQJAc5',
            'rec5C7qLS5TqNTf21',
            'recwCTLr02Utv6VSP',
            'recVjDucqFnmaRfkt',
            'recSEAzZVY930Oiya',
            'recFPBVxJiksZZNok',
            'recCadj8O6EUp9coB',
            'recDhAvQ8ELGeSemH',
            'recj8FWZ78SQh6J2P',
            'recWGjntBIfHUKuuU',
            'recBHOV38yf9MhtDq',
            'rec1Eh4y1l9HdxKJb',
            'recHnZlNSSqkbU2dj',
            'recHHszEdfubcrfRO',
            'recvldM9fR51q177e',
        ],
        devices: ['recpXBuW428k6rNR8'],
        lenses: ['recNwLhQe8at4lT0Y'],
        status: 'Active',
        Description: 'Peak Vibration Velocity [1-100Hz] (mm/s)',
        lens_blocks: ['recir80lq8pKo1rl5', 'rec18gxqVrDyPhyO4', 'recwBnULAcIsye8ov'],
        app: '{\n  "beat-name": "femur",\n  "partitionKey": "[\\"99\\"]"\n}\n',
        configs: ['recRrQCU0YTB11s61', 'rec9yWxj4aItDnfya'],
        _id: 'reccR5SJ0fwvkpWS1',
    },
    {
        slug: 'vmv-1-vib',
        setups: [
            'recJw9zCGL5Nt5jL2',
            'recvi6K7hjyHVXte5',
            'recRjuilDzlaQJAc5',
            'rec5C7qLS5TqNTf21',
            'recwCTLr02Utv6VSP',
            'recVjDucqFnmaRfkt',
            'recSEAzZVY930Oiya',
            'recFPBVxJiksZZNok',
            'recCadj8O6EUp9coB',
            'recDhAvQ8ELGeSemH',
            'recj8FWZ78SQh6J2P',
            'recWGjntBIfHUKuuU',
            'recBHOV38yf9MhtDq',
            'rec1Eh4y1l9HdxKJb',
            'recHnZlNSSqkbU2dj',
            'recHHszEdfubcrfRO',
            'recvldM9fR51q177e',
        ],
        lenses: ['rec7zofqzJOnuGOZl'],
        status: 'Active',
        Description: 'Peak Vibration Velocity [1-100Hz] (mm/s)',
        lens_blocks: ['recjGgxDvtG3krOtd', 'recySjWIfGalVmmqp', 'rec6syxQh89N7JDr8'],
        app: '{\n  "beat-name": "worry",\n  "partitionKey": "[\\"99\\"]"\n}\n',
        configs: ['reciXUcwwFdkKBlWU', 'rec9yWxj4aItDnfya'],
        _id: 'recWlZLp0RGsQigc5',
    },
    {
        slug: 'tvc-1-vib',
        devices: ['recRYjJZYRuFQshLo'],
        lenses: ['recKJxhecJd0Mzc82'],
        status: 'Active',
        Description: 'Velocity RMS [10-1000Hz] (mm/s)',
        lens_blocks: ['recEyXZpKWR6z2LNG', 'recGMp6hxmBOopwQn', 'recwHnLeyBfsR7MZk', 'recbIU6feKHkGCVGt'],
        app: '{\n  "beat-name": "agony1",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['rec9yWxj4aItDnfya', 'recsHRPxIqHFuQaz4'],
        _id: 'recbYb2tktySgph7B',
    },
    {
        slug: 'tvc-2-vib',
        devices: ['recRYjJZYRuFQshLo'],
        lenses: ['recKJxhecJd0Mzc82'],
        status: 'Active',
        Description: 'Velocity RMS [10-1000Hz] (mm/s)',
        lens_blocks: ['recEyXZpKWR6z2LNG', 'recGMp6hxmBOopwQn', 'recwHnLeyBfsR7MZk', 'recbIU6feKHkGCVGt'],
        app: '{\n  "beat-name": "agony2",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['rec9yWxj4aItDnfya', 'recsHRPxIqHFuQaz4'],
        _id: 'recQa7NRAUqc15lLU',
    },
    {
        slug: 'tvc-3-vib',
        devices: ['recRYjJZYRuFQshLo'],
        lenses: ['recKJxhecJd0Mzc82'],
        status: 'Active',
        Description: 'Velocity RMS [10-1000Hz] (mm/s)',
        lens_blocks: ['recEyXZpKWR6z2LNG', 'recGMp6hxmBOopwQn', 'recwHnLeyBfsR7MZk', 'recbIU6feKHkGCVGt'],
        app: '{\n  "beat-name": "agony3",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['rec9yWxj4aItDnfya', 'recsHRPxIqHFuQaz4'],
        _id: 'recSbjrQnKCaBmSvv',
    },
    {
        slug: 'tvc-4-vib',
        devices: ['recRYjJZYRuFQshLo'],
        lenses: ['recKJxhecJd0Mzc82'],
        status: 'Active',
        Description: 'Velocity RMS [10-1000Hz] (mm/s)',
        lens_blocks: ['recEyXZpKWR6z2LNG', 'recGMp6hxmBOopwQn', 'recwHnLeyBfsR7MZk', 'recbIU6feKHkGCVGt'],
        app: '{\n  "beat-name": "agony4",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['rec9yWxj4aItDnfya', 'recsHRPxIqHFuQaz4'],
        _id: 'rec5I1b6y0lTOJz77',
    },
    {
        slug: 'bie-1-vib',
        setups: ['recJw9zCGL5Nt5jL2', 'rec6LcuWGiJRQliZo', 'rec5C7qLS5TqNTf21', 'recHnZlNSSqkbU2dj', 'recHHszEdfubcrfRO', 'recvldM9fR51q177e'],
        devices: ['recCbbKythJq0lZIf'],
        lenses: ['recj1etG31fBFMKWN'],
        status: 'Inactive',
        Description: 'Warning and Alarms (actions vs stop work)',
        lens_blocks: ['recKvyc2A5Ac73R9V', 'recJ9FL90xJP1pT3F'],
        configs: ['recwGQDJONJj7o0ZT', 'recLogqTxGu8aCgEF'],
        _id: 'reco3oDXuWSWU6qPO',
    },
    {
        slug: 'thp-1-vib',
        setups: [
            'recJw9zCGL5Nt5jL2',
            'recBVp97aEfg8CuuI',
            'rec5C7qLS5TqNTf21',
            'recBHOV38yf9MhtDq',
            'rec1Eh4y1l9HdxKJb',
            'recHnZlNSSqkbU2dj',
            'recHHszEdfubcrfRO',
            'recvldM9fR51q177e',
        ],
        devices: ['recCCnOvrn4KPcOy6'],
        lenses: ['recn1WwfZrCQvoDes'],
        status: 'Inactive',
        Description: 'Monitoring following DIN4150-3/L2 (normal buildings). Comparison with ACOEM Orion ',
        lens_blocks: ['recj5VfS4l4vbVblv', 'rech6Il1KBTEejipC'],
        configs: ['recwGQDJONJj7o0ZT', 'recLogqTxGu8aCgEF'],
        _id: 'recLV3zmV5oGx9ZYT',
    },
    {
        slug: 'thp-2-vib',
        setups: [
            'recJw9zCGL5Nt5jL2',
            'recBVp97aEfg8CuuI',
            'rec5C7qLS5TqNTf21',
            'recBHOV38yf9MhtDq',
            'recmEz2FjDx3yyEvK',
            'recHnZlNSSqkbU2dj',
            'recHHszEdfubcrfRO',
            'recvldM9fR51q177e',
        ],
        lenses: ['recn1WwfZrCQvoDes'],
        status: 'Inactive',
        Description: 'Monitoring following DIN4150-3/L3 (sensitive buildings). Comparison with ACOEM Orion.',
        configs: ['recwGQDJONJj7o0ZT', 'recLogqTxGu8aCgEF'],
        _id: 'rec19pIldKM58hQlD',
    },
    {
        slug: 'ecm-1-vib',
        setups: [
            'recJw9zCGL5Nt5jL2',
            'recfo8It2xJGQng92',
            'recqw9voDDq7e9dLX',
            'rec0dqfeM5fsr8Oh7',
            'recCadj8O6EUp9coB',
            'recj8FWZ78SQh6J2P',
            'recDhAvQ8ELGeSemH',
            'recFPBVxJiksZZNok',
            'rec5C7qLS5TqNTf21',
            'recCfY5CyrjFblPfD',
            'recwdU85kAtePJEHf',
            'recCxOSD5camzVnSc',
            'recHHszEdfubcrfRO',
            'rec1A5t5HuKIYACFU',
            'recmEz2FjDx3yyEvK',
        ],
        lenses: ['reclpckuaJEkxeRbI'],
        status: 'Inactive',
        Description: 'Building vibration according to DIN4150-3/L3 (sensitive buildings). ',
        lens_blocks: ['recV64yB1eElugjzd', 'recvZFHksIcEKKGw1'],
        configs: ['recwGQDJONJj7o0ZT', 'recLogqTxGu8aCgEF'],
        _id: 'rech9XerLoitdmkRP',
    },
    {
        slug: 'ecm-2-dst',
        setups: [
            'recfo8It2xJGQng92',
            'recqw9voDDq7e9dLX',
            'rec0dqfeM5fsr8Oh7',
            'recCadj8O6EUp9coB',
            'recFPBVxJiksZZNok',
            'recj8FWZ78SQh6J2P',
            'recDhAvQ8ELGeSemH',
            'recEty49PcIU3KrHd',
            'recCfY5CyrjFblPfD',
            'recwdU85kAtePJEHf',
            'recCxOSD5camzVnSc',
            'recHHszEdfubcrfRO',
            'rec1A5t5HuKIYACFU',
            'recpJJH7DdQM45pWf',
        ],
        lenses: ['reclpckuaJEkxeRbI'],
        status: 'Inactive',
        Description: 'Voltage input for a Casella Dust Monitoring Device. ',
        configs: ['recwGQDJONJj7o0ZT', 'recLogqTxGu8aCgEF'],
        _id: 'recOLVd6kEzXhNhBu',
    },
    {
        slug: 'ecv-1-vib',
        setups: [
            'recDhAvQ8ELGeSemH',
            'rec6LcuWGiJRQliZo',
            'recImaw6uQJeuInq8',
            'recwdU85kAtePJEHf',
            'recCadj8O6EUp9coB',
            'recj8FWZ78SQh6J2P',
            'rec5C7qLS5TqNTf21',
            'recq1bAZDdUNGzdqr',
            'recBHOV38yf9MhtDq',
            'recmEz2FjDx3yyEvK',
        ],
        lenses: ['recWFDWTcFuxQRj4a'],
        status: 'Inactive',
        Description: 'Building Vibration Measurement according to DIN4150-3/L3',
        lens_blocks: ['recoqAl3L6mCAbjpj', 'recNAvIkXMSte43Eq'],
        configs: ['recwGQDJONJj7o0ZT', 'recLogqTxGu8aCgEF'],
        _id: 'rec2l1ben6EFobl73',
    },
    {
        slug: 'wnl-1-tmd',
        lenses: ['recooDFaLJt17BvPi'],
        status: 'Inactive',
        Description: 'Accelerometer #1 on the Roller Coaster Structure ',
        _id: 'recq9A4n1RMMEMpyk',
    },
    {
        slug: 'wnl-2-tmd',
        lenses: ['recooDFaLJt17BvPi'],
        status: 'Inactive',
        Description: 'Accelerometer #2 on the Roller Coaster Structure ',
        _id: 'recPi21QB1DbdAsu5',
    },
    {
        slug: 'wnl-3-tmd',
        setups: [
            'recCs4x0LYnUZUTo1',
            'recSEAzZVY930Oiya',
            'recCadj8O6EUp9coB',
            'recFPBVxJiksZZNok',
            'recj8FWZ78SQh6J2P',
            'recDhAvQ8ELGeSemH',
            'recBHOV38yf9MhtDq',
            'recq1bAZDdUNGzdqr',
            'recGty6pWOEu8NKeh',
        ],
        lenses: ['recooDFaLJt17BvPi'],
        status: 'Inactive',
        Description: 'Accelerometer on the TMD moving mass  ',
        _id: 'recvl7jdalep3Cw7u',
    },
    {
        slug: 'fgp-1-acc',
        lenses: ['reczeOLkIVWcWjZVB'],
        status: 'Inactive',
        Description: 'Accelerations measurements on Flagpole',
        _id: 'recPKoVhZH4cGyWLX',
    },
    {
        slug: 'fgp-2-dis',
        lenses: ['reczeOLkIVWcWjZVB'],
        status: 'Inactive',
        Description: 'Displacements measurements  on Flagpole',
        _id: 'reclvgpFqScI6A4xk',
    },
    {
        slug: 'fgp-1-tmd',
        lenses: ['reczeOLkIVWcWjZVB'],
        status: 'Inactive',
        Description: 'Dedicated algorithm for TMDs efficiency computations. ',
        _id: 'rec6YkqWpXc1hdiWv',
    },
    {
        slug: 'mbh-1-pot',
        setups: ['recGbzftZNWKUt9wm', 'rec1A5t5HuKIYACFU', 'recohzdIcdQbEdGbS', 'recHHszEdfubcrfRO'],
        lenses: ['recA078N3JVaEOUN6'],
        status: 'Inactive',
        Description: 'One string potentiometer sensor per rail. ',
        _id: 'recsaH6D2ocUSwvQI',
    },
    {
        slug: 'mbh-12-tem',
        setups: ['recfiKh7VfghhQgx9', 'rec1A5t5HuKIYACFU', 'recohzdIcdQbEdGbS', 'recHHszEdfubcrfRO'],
        lenses: ['recA078N3JVaEOUN6'],
        status: 'Inactive',
        Description: 'One surface temperature sensor per rail. ',
        _id: 'recbSjhmrGHnwgfpU',
    },
    {
        slug: 'gsm-1-tem',
        setups: ['recBVp97aEfg8CuuI', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr'],
        lenses: ['recz7dS5tIe7SIego'],
        status: 'Inactive',
        Description: 'Operating temperature range for sensors (from -40°C, -55°C in a few places). ',
        _id: 'recHL98cCay1CEJmC',
    },
    {
        slug: 'wnd-1-acc',
        setups: ['rec1A5t5HuKIYACFU', 'recHHszEdfubcrfRO'],
        lenses: ['recMfn495FoqMBTtH'],
        status: 'Inactive',
        Description: '2-axis IAC-I accelerometer',
        _id: 'recxQ5BdtE6bYjO2N',
    },
    {
        slug: 'coo-1-cnd',
        setups: ['recxKPXIHR7OtcW49', 'recXNo4xuhS64bnmu', 'recHHszEdfubcrfRO', 'recTxw6eotonR8YK7', 'rec1A5t5HuKIYACFU', 'recHNZ4OVHIGxWufv'],
        lenses: ['recw92lE4aETqozHR'],
        status: 'Inactive',
        Description: 'Remote monitoring from everywhere. Up to 500Hz useful bandwidth ',
        _id: 'recr6Y8Yp7AfZWnYr',
    },
    {
        slug: 'coo-2-cnd',
        setups: ['recxKPXIHR7OtcW49', 'recXNo4xuhS64bnmu', 'recHHszEdfubcrfRO', 'recTxw6eotonR8YK7', 'rec1A5t5HuKIYACFU', 'recHNZ4OVHIGxWufv'],
        lenses: ['recw92lE4aETqozHR'],
        status: 'Inactive',
        Description: 'Remote monitoring from everywhere. Up to 500Hz useful bandwidth ',
        _id: 'recM3LQrkdidarFXy',
    },
    {
        slug: 'coo-3-cnd',
        setups: ['recxKPXIHR7OtcW49', 'recXNo4xuhS64bnmu', 'recHHszEdfubcrfRO', 'recTxw6eotonR8YK7', 'rec1A5t5HuKIYACFU', 'recHNZ4OVHIGxWufv'],
        lenses: ['recw92lE4aETqozHR'],
        status: 'Inactive',
        Description: 'Remote monitoring from everywhere. Up to 500Hz useful bandwidth ',
        _id: 'recuCoGADd1kv17cP',
    },
    {
        slug: 'coo-4-cnd',
        setups: ['recxKPXIHR7OtcW49', 'recXNo4xuhS64bnmu', 'recHHszEdfubcrfRO', 'recTxw6eotonR8YK7', 'rec1A5t5HuKIYACFU', 'recHNZ4OVHIGxWufv'],
        lenses: ['recw92lE4aETqozHR'],
        status: 'Inactive',
        Description: 'Remote monitoring from everywhere. Up to 500Hz useful bandwidth ',
        _id: 'recI9Xja68ji6VCzZ',
    },
    {
        slug: 'mbh-2-pot',
        setups: ['recGbzftZNWKUt9wm', 'rec1A5t5HuKIYACFU', 'recohzdIcdQbEdGbS', 'recHHszEdfubcrfRO'],
        lenses: ['recA078N3JVaEOUN6'],
        status: 'Inactive',
        Description: 'One string potentiometer sensor per rail. ',
        _id: 'recXuyEw5OlDdazIZ',
    },
    {
        slug: 'mbh-3-pot',
        setups: ['recGbzftZNWKUt9wm', 'rec1A5t5HuKIYACFU', 'recohzdIcdQbEdGbS', 'recHHszEdfubcrfRO'],
        lenses: ['recA078N3JVaEOUN6'],
        status: 'Inactive',
        Description: 'One string potentiometer sensor per rail. ',
        _id: 'recFUeInK46msgfEe',
    },
    {
        slug: 'mbh-14-tem',
        setups: ['recfiKh7VfghhQgx9', 'rec1A5t5HuKIYACFU', 'recohzdIcdQbEdGbS', 'recHHszEdfubcrfRO'],
        lenses: ['recA078N3JVaEOUN6'],
        status: 'Inactive',
        Description: 'One surface temperature sensor per rail. ',
        _id: 'recoTsCfw74ji6BRg',
    },
    {
        slug: 'mbh-15-tem',
        setups: ['recfiKh7VfghhQgx9', 'rec1A5t5HuKIYACFU', 'recohzdIcdQbEdGbS', 'recHHszEdfubcrfRO'],
        lenses: ['recA078N3JVaEOUN6'],
        status: 'Inactive',
        Description: 'One surface temperature sensor per rail. ',
        _id: 'reczAT4uvhwhqiQKc',
    },
    {
        slug: 'pgd-1-acc',
        setups: ['recWXNLZ4FJYaIHQ7', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr', 'recubEWVnHVEGJX3z'],
        lenses: ['recptVUJF3wjIitXN'],
        status: 'Inactive',
        Description: 'Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ',
        _id: 'recOAO9p85XFURlyl',
    },
    {
        slug: 'pgd-20-inc',
        setups: ['recWXNLZ4FJYaIHQ7', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr', 'recubEWVnHVEGJX3z'],
        lenses: ['recptVUJF3wjIitXN'],
        status: 'Inactive',
        Description: '3 x inclinometer (without temperature compensation)',
        _id: 'recdYRXMH0yeZoC8B',
    },
    {
        slug: 'pgd-2-acc',
        setups: ['recWXNLZ4FJYaIHQ7', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr', 'recubEWVnHVEGJX3z'],
        lenses: ['recptVUJF3wjIitXN'],
        status: 'Inactive',
        Description: 'Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ',
        _id: 'rec5cd7To0UidPqow',
    },
    {
        slug: 'pgd-3-acc',
        setups: ['recWXNLZ4FJYaIHQ7', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr', 'recubEWVnHVEGJX3z'],
        lenses: ['recptVUJF3wjIitXN'],
        status: 'Inactive',
        Description: 'Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ',
        _id: 'reccMHc7B8ZvdQ73q',
    },
    {
        slug: 'pgd-21-inc',
        setups: ['recWXNLZ4FJYaIHQ7', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr', 'recubEWVnHVEGJX3z'],
        lenses: ['recptVUJF3wjIitXN'],
        status: 'Inactive',
        Description: '3 x inclinometer (without temperature compensation)',
        _id: 'recCkmJaC9zbJpGPf',
    },
    {
        slug: 'pgd-22-inc',
        setups: ['recWXNLZ4FJYaIHQ7', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr', 'recubEWVnHVEGJX3z'],
        lenses: ['recptVUJF3wjIitXN'],
        status: 'Inactive',
        Description: '3 x inclinometer (without temperature compensation)',
        _id: 'recsOpZQu5BOOQ7tv',
    },
    {
        slug: 'pgd-4-acc',
        setups: ['recWXNLZ4FJYaIHQ7', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr', 'recubEWVnHVEGJX3z'],
        lenses: ['recptVUJF3wjIitXN'],
        status: 'Inactive',
        Description: 'Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ',
        _id: 'reca07kdlw6aXQ6Gv',
    },
    {
        slug: 'mbh-21-ac',
        setups: ['recubEWVnHVEGJX3z'],
        status: 'Inactive',
        Description: 'accelerometer FrontLeft',
        _id: 'recNuKOwJ7WfmcyXy',
    },
    {
        slug: 'mbh-22-ac',
        setups: ['recubEWVnHVEGJX3z', 'recxKPXIHR7OtcW49'],
        status: 'Inactive',
        Description: 'accelerometer FrontRight',
        _id: 'rec9XkV14TJZFC38e',
    },
    {
        slug: 'mbh-23-ac',
        setups: ['recubEWVnHVEGJX3z', 'recxKPXIHR7OtcW49'],
        status: 'Inactive',
        Description: 'accelerometer BottomRight',
        _id: 'recIWnsFY59I0t6eq',
    },
    {
        slug: 'n13-1-vib',
        status: 'Inactive',
        _id: 'reckGHCrSRcRSqirw',
    },
    {
        slug: 'n12-1-vib',
        status: 'Inactive',
        _id: 'reckdqcEyhscpIAhh',
    },
    {
        slug: 'n11-1-vib',
        status: 'Inactive',
        _id: 'recguonxy5dxckiWX',
    },
    {
        slug: 'n18-1-vib',
        status: 'Inactive',
        _id: 'recTP8tvVEJeQX85s',
    },
    {
        slug: 'cold-path',
        status: 'Inactive',
        _id: 'recWFrOrpPu0wIVgg',
    },
    {
        slug: 'nyh-1-acc-stag',
        setups: ['recxKPXIHR7OtcW49', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr'],
        devices: ['recz3ySgv9xvN0BMs'],
        lenses: ['recwPuRtpi8YGNbTj'],
        status: 'Active',
        Description: 'Peak Acceleration (g)',
        lens_blocks: ['recP0EJNYKSRMx8xu', 'recxGuuwBMW50x9Fu', 'recOsnkUnItkBLvny'],
        app: '{\n  "beat-name": "crazy0|crazy1|crazy2|crazy3",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['recsHRPxIqHFuQaz4', 'rec9yWxj4aItDnfya'],
        _id: 'recDoeSx8kCpHl9sP',
    },
    {
        slug: 'nyh-1-bst-stag',
        setups: ['recxKPXIHR7OtcW49', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr'],
        devices: ['recz3ySgv9xvN0BMs'],
        lenses: ['rec23cPcuWjARCyG3'],
        status: 'Active',
        Description: 'Peak Acceleration (g)',
        lens_blocks: ['recP0EJNYKSRMx8xu', 'recxGuuwBMW50x9Fu', 'recOsnkUnItkBLvny'],
        app: '{\n  "beat-name": "\'goofy0\'",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['recsHRPxIqHFuQaz4', 'rec9yWxj4aItDnfya'],
        _id: 'recmlRi4kNqzG0FJa',
    },
    {
        slug: 'nyh-1-bst',
        setups: ['recxKPXIHR7OtcW49', 'recHHszEdfubcrfRO', 'recq1bAZDdUNGzdqr'],
        devices: ['recz3ySgv9xvN0BMs'],
        lenses: ['recQQT5jcniotm2SS'],
        status: 'Active',
        Description: 'Peak Acceleration (g)',
        lens_blocks: ['recP0EJNYKSRMx8xu', 'recxGuuwBMW50x9Fu', 'recOsnkUnItkBLvny'],
        app: '{\n  "beat-name": "\'goofy1\'",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['recsHRPxIqHFuQaz4', 'rec9yWxj4aItDnfya'],
        _id: 'recQEp2a9n7EWbtKK',
    },
    {
        slug: 'lho-3-dst',
        setups: [
            'recvi6K7hjyHVXte5',
            'recVjDucqFnmaRfkt',
            'recSEAzZVY930Oiya',
            'recFPBVxJiksZZNok',
            'recCadj8O6EUp9coB',
            'recDhAvQ8ELGeSemH',
            'recj8FWZ78SQh6J2P',
            'recWGjntBIfHUKuuU',
            'recxKPXIHR7OtcW49',
            'recq1bAZDdUNGzdqr',
            'recRjuilDzlaQJAc5',
            'recHHszEdfubcrfRO',
            'recpJJH7DdQM45pWf',
        ],
        devices: ['recRKt9A9OemBW2jl'],
        lenses: ['recKT3Z6cOztKKQet'],
        status: 'Active',
        Description: 'Peak Air Pressure (Pa)',
        lens_blocks: ['recN1A94MeNEYUveo', 'recQZZkN3ygOB5yf4'],
        app: '{\n  "beat-name": "Air fast",\n  "partitionKey": "[\\"99\\"]"\n}',
        configs: ['rec1gTgGJM0BsP071', 'recI5ZBHgXmt90O9k'],
        _id: 'recDd8fgyT8423xbq',
    },
]