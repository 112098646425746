export const table = [{
        "slug": "lhoist-quarry-marchelesdames",
        "location_i18n": "Marche-les-Dames",
        "roles": [
            "rec1qsN0Xi917oT74",
            "recoo5j79K6YnGNAu",
            "recPBUdz726z0L5up",
            "recQr4jMHjWenG7Un",
            "recWarXxEtjfVOOid"
        ],
        "i18n": "Lhoist Quarry",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recogUTMzKIM1jpsZ"
        ],
        "lenses": [
            "rec4B9zT5dueMZZBs",
            "recChHriPInnrgGfd",
            "recKT3Z6cOztKKQet"
        ],
        "status": "active",
        "_id": "recU4d0NsafVSRELQ"
    },
    {
        "slug": "bierset-ground-radar",
        "location_i18n": "Bierset - Galère Construction Work",
        "roles": [
            "recMOLapjHAuuuj8E",
            "recPBUdz726z0L5up"
        ],
        "i18n": "Airport Ground Radar",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recogUTMzKIM1jpsZ"
        ],
        "lenses": [
            "recj1etG31fBFMKWN"
        ],
        "status": "closed",
        "_id": "rec9uiUAqY0iLEp6b"
    },
    {
        "slug": "thomas-piron-mons",
        "location_i18n": "Mons",
        "roles": [
            "rec4et38oeHtetAqm",
            "recPBUdz726z0L5up"
        ],
        "i18n": "Thomas-Piron Construction Work",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recogUTMzKIM1jpsZ"
        ],
        "lenses": [
            "recn1WwfZrCQvoDes"
        ],
        "status": "closed",
        "_id": "recdu0X9monxBgioQ"
    },
    {
        "slug": "ec-museum",
        "location_i18n": "Brussels",
        "roles": [
            "recWTzWWRfh7PiMLn",
            "recPBUdz726z0L5up"
        ],
        "i18n": "EC Museum",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recogUTMzKIM1jpsZ"
        ],
        "lenses": [
            "reclpckuaJEkxeRbI"
        ],
        "status": "closed",
        "_id": "recbSgLoxGp1D6wl6"
    },
    {
        "slug": "edith-cavel-hospital",
        "location_i18n": "Brussels",
        "roles": [
            "recka6aGkwEtr88Ef",
            "recPBUdz726z0L5up"
        ],
        "i18n": "Edith Cavel Hospital",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recogUTMzKIM1jpsZ"
        ],
        "lenses": [
            "recWFDWTcFuxQRj4a"
        ],
        "status": "canceled",
        "_id": "recM8HnNzCfeWJYey"
    },
    {
        "slug": "walibi-nl-roller-coaster",
        "location_i18n": "Biddinghuizen",
        "roles": [
            "recH8ClrAsmxlTNAT",
            "recthVkzxRKuhuURV"
        ],
        "i18n": "Walibi Holland Roller Coaster",
        "country_i18n": "Netherlands",
        "affiliate-manager": [
            "rec02ACNtFPx25pOe"
        ],
        "lenses": [
            "recooDFaLJt17BvPi"
        ],
        "status": "re-start-pending ",
        "_id": "receRH648GeIdtaZG"
    },
    {
        "slug": "flagpole",
        "location_i18n": "Sheboygan Winsconsin",
        "roles": [
            "recn1lpeIPWj6Wmtz",
            "recthVkzxRKuhuURV"
        ],
        "i18n": "Flagpole",
        "country_i18n": "USA",
        "affiliate-manager": [
            "rec02ACNtFPx25pOe"
        ],
        "lenses": [
            "reczeOLkIVWcWjZVB"
        ],
        "status": "proposed",
        "_id": "recpHSce5SHT2bE5Z"
    },
    {
        "slug": "melb-heaven-rails",
        "location_i18n": "Melbourne",
        "roles": [
            "reccruEnh4NPkTY4y",
            "recY9m1v4Y63e8S2b"
        ],
        "i18n": "Port of Melbourne – VICT – ASC Blocks",
        "country_i18n": "Australia",
        "affiliate-manager": [
            "recfQBlyumJnLtmpZ"
        ],
        "lenses": [
            "recA078N3JVaEOUN6"
        ],
        "status": "proposed",
        "_id": "recESV0WYKfUUesjk"
    },
    {
        "slug": "ny-heaven-rails",
        "location_i18n": "New York",
        "roles": [
            "recSlH4gqMwUVJtWw",
            "recXRlHaY9YzIQxRB",
            "recY9m1v4Y63e8S2b"
        ],
        "i18n": "Port of New York – Maher Hinge Guard crane #40",
        "country_i18n": "USA",
        "affiliate-manager": [
            "recfQBlyumJnLtmpZ",
            "recYXHnE55SLjZcTg"
        ],
        "lenses": [
            "rec3OHQXn3S4zP1Gl",
            "recQQT5jcniotm2SS"
        ],
        "status": "active",
        "_id": "recv4Gzbi9mK9iW3L"
    },
    {
        "slug": "ny-heaven-rails-staging",
        "location_i18n": "New York",
        "roles": [
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "[STAGING] Crane rails - New York Heaven",
        "country_i18n": "USA",
        "affiliate-manager": [
            "recfQBlyumJnLtmpZ",
            "recYXHnE55SLjZcTg"
        ],
        "lenses": [
            "recwPuRtpi8YGNbTj",
            "rec23cPcuWjARCyG3"
        ],
        "status": "active",
        "_id": "recm0sNGrHnExoJg6"
    },
    {
        "slug": "hv-heaven-rails",
        "location_i18n": "Le Havre",
        "roles": [
            "recSlH4gqMwUVJtWw",
            "recXRlHaY9YzIQxRB",
            "recY9m1v4Y63e8S2b"
        ],
        "i18n": "Le Havre – TPO – Hinge Guard grue #xx",
        "country_i18n": "France",
        "affiliate-manager": [
            "recfQBlyumJnLtmpZ"
        ],
        "lenses": [
            "recoIsLgNYcassMNx"
        ],
        "status": "proposed",
        "_id": "recVVhEuxlccS3erz"
    },
    {
        "slug": "gsm-pylon-structural-integrity",
        "location_i18n": "Canada",
        "roles": [
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "GSM pylons",
        "country_i18n": "Canada",
        "affiliate-manager": [
            "recQd20E4mOk2ZLfj"
        ],
        "lenses": [
            "recz7dS5tIe7SIego"
        ],
        "status": "proposed",
        "_id": "recpAhjZQFZOYATqM"
    },
    {
        "slug": "wind-turbine",
        "location_i18n": "South Africa",
        "roles": [
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "30 MW Wind turbines",
        "country_i18n": "South Africa",
        "affiliate-manager": [
            "recQd20E4mOk2ZLfj"
        ],
        "lenses": [
            "recMfn495FoqMBTtH"
        ],
        "status": "proposed",
        "_id": "recCIrv3sC97StyD5"
    },
    {
        "slug": "cooling-system",
        "roles": [
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "Cooling System Monitoring",
        "country_i18n": "South Korea",
        "affiliate-manager": [
            "recQd20E4mOk2ZLfj"
        ],
        "lenses": [
            "recw92lE4aETqozHR"
        ],
        "status": "proposed",
        "_id": "recmC6Myr7pAwgeRf"
    },
    {
        "slug": "mumbai-pagoda",
        "location_i18n": "Mumbai",
        "roles": [
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "Global Vipassana Pagoda",
        "country_i18n": "India",
        "affiliate-manager": [
            "recQd20E4mOk2ZLfj"
        ],
        "lenses": [
            "recptVUJF3wjIitXN"
        ],
        "status": "proposed",
        "_id": "recfWZlD20h0Oa87b"
    },
    {
        "slug": "pommeroeul-railways",
        "location_i18n": "Ville Pommeroeul",
        "roles": [
            "rectzg796uRxA5cwe",
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "Railways Vibration",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recQd20E4mOk2ZLfj",
            "recHlQ49LSyTN9FIB"
        ],
        "lenses": [
            "rec8oMkgLRpAooIMW"
        ],
        "status": "active",
        "_id": "recYRQLa8OahugnWk"
    },
    {
        "slug": "demo_mode",
        "location_i18n": "Fernelmont",
        "roles": [
            "recSdWBzCf0jP2Jft",
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "Demo Account",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recQd20E4mOk2ZLfj",
            "recy8eOftihYfh4ik"
        ],
        "lenses": [
            "recB8ZKzjdZr3V4wr",
            "recY9ILAwR4dApQDH"
        ],
        "status": "active",
        "_id": "rec9I1aHgfQFuhC3k"
    },
    {
        "slug": "staging",
        "location_i18n": "Fernelmont",
        "roles": [
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "Staging Account",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recQd20E4mOk2ZLfj",
            "recy8eOftihYfh4ik"
        ],
        "status": "active",
        "_id": "recAJrQvc1lVBC2bG"
    },
    {
        "slug": "cementys-1st-account",
        "location_i18n": "Underwater",
        "roles": [
            "rec8Mi25ePSpIMCLH"
        ],
        "i18n": "Cementys Account #1",
        "country_i18n": "France",
        "affiliate-manager": [
            "recm4Kig9gxBeKcVx"
        ],
        "lenses": [
            "recNwLhQe8at4lT0Y"
        ],
        "status": "active",
        "_id": "recXY1CQroQC0NgUU"
    },
    {
        "slug": "vmv-1st-account",
        "location_i18n": "Pohang",
        "roles": [
            "recGxH6MSIpXrcFEQ"
        ],
        "i18n": "POSCO",
        "country_i18n": "South Korea",
        "affiliate-manager": [
            "recDKrMIhw8TSACQl"
        ],
        "lenses": [
            "rec7zofqzJOnuGOZl"
        ],
        "status": "active",
        "_id": "rec6FChHb2nNdRZTV"
    },
    {
        "slug": "travelec",
        "location_i18n": "Brussels",
        "roles": [
            "recXRlHaY9YzIQxRB"
        ],
        "i18n": "Travelec Staging Experiments",
        "country_i18n": "Belgium",
        "affiliate-manager": [
            "recQd20E4mOk2ZLfj"
        ],
        "lenses": [
            "recKJxhecJd0Mzc82"
        ],
        "status": "active",
        "_id": "recH2V21O1HvxIqhm"
    },
    {
        "slug": "vmv-closed-account",
        "location_i18n": "Seoul",
        "roles": [
            "recGxH6MSIpXrcFEQ"
        ],
        "i18n": "Airport Surveillance Radar",
        "country_i18n": "South Korea",
        "affiliate-manager": [
            "recDKrMIhw8TSACQl"
        ],
        "lenses": [
            "recB9f45w5wxK9GAF"
        ],
        "status": "closed",
        "_id": "recIRyhLwGVtUr1Ml"
    }
]