import styled from 'styled-components'

export const defaultTokens = { bgColorLight: 'white', borderWidth: undefined, borderColor: undefined, borderRadius: undefined }

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  td {
    padding-right: 32px;
  }
  th td {
    text-align: left;
  }
`

export const Section = styled.div`
  font-family: 'Calibri', sans-serif;
  background-color: #ffffff;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);
  font-size: 12pt;
  line-height: 1.2;
  width: 100%;
  border: 1px solid white;
  border-radius: 4px;
  margin-top: 32px;
  max-height: ${(props) => {
      return props.maxHeight ? props.maxHeight + 'px' : undefined
    }}
    & + & {
    margin-bottom: 0px;
  }
`

export const Title = styled.div`
  font-size: 1.4em;
  font-weight: bold;
`

export const WidgetBox = styled.div`
  margin-top: 32px;
`
