import React from 'react'
import Chart from 'react-apexcharts'
import { buildOptions } from '@mmd/block-recovib/chart-defaults'
import { getDecimated } from '@mmd/fns/tlm-burst/decimate' // Base

const orderByAbsMax = (a, b) => {
  return b.abs - a.abs
}

const Burst = (props) => {
  const { channels } = props

  const series = (channels || [])
    .map((channel, i) => {
      const { name, data } = channel
      const { pts, max, ...rest } = getDecimated(data, 5)
      return { name: name, data: pts, max, channel: channel.name, channelIdx: i, ...rest }
    })
    .sort(orderByAbsMax)

  const { title, legend } = props

  const optionsMain = buildOptions({
    id: 'burst-ppv',
    title: title,
    yTitle: legend,
    yTooltip: function (value) {
      return value ? value.toFixed(5) : ''
    },
    xFormatter: function (ms, ...rest) {
      //   return seconds.toFixed(3).padStart(2, '0')
      return Math.round(ms / 100000) / 10
      // :WARN: for gantrex, used
      // return msAsSeconds(ms).toFixed(1) + '00'
    },
    yFormatter: function (value, ...rest) {
      return value ? value.toFixed(2) : ''
    },
  })

  optionsMain.yaxis.max = undefined // maxPoint
  optionsMain.yaxis.min = undefined // maxPoint
  optionsMain.xaxis.forceNiceScale = true
  optionsMain.xaxis.min = 0 // maxPoint

  // optionsMain.yaxis.max = 2 // maxPoint
  // optionsMain.yaxis.min = -2 // maxPoint

  const line = { color: '#F9971E', dash: 3 } // maxPoint >= maxStd ? { color: '#b22200', dash: 3 } : { color: '#3ca53a', dash: 0 }
  const xAnnotations = series.sort(orderByAbsMax).map((d, i) => {
    const color = optionsMain.colors[d.channelIdx]
    const { time: maxTime, value: maxValue } = d.max
    console.log('[D]', d.channelIdx, maxTime, maxValue)
    return {
      x: maxTime,
      strokeDashArray: line.dash,
      borderColor: color,
      label: {
        textAnchor: 'middle',
        position: i === 0 ? 'top' : 'bottom',
        orientation: 'horizontal',
        borderColor: color,
        style: {
          color: '#fff',
          background: color,
          fontSize: `${(100 - 16 * i) / 100}em`,
        },
        offsetY: i === 0 ? 0 : -5 * i,
        text: `${maxValue.toFixed(3)}`,
      },
    }
  })

  optionsMain.annotations.xaxis = xAnnotations

  return <Chart options={optionsMain} series={series} type="line" height="360" />
}

export default Burst
