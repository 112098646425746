import React from 'react'
import logo from './etc/top-footer.png'

import styled from 'styled-components'
// import Tiles from '@mmd/design-layout/tiles'
import { VSpacer } from '@mmd/design-layout/'

const Layout = styled.div`
  background-color: #0e2230;
  color: #ffffff;
  padding-bottom: 32px;
`

const Section = styled.div`
  text-align: left;
  box-sizing: border-box;
  text-align: center;
  margin-top: 8px;
  a {
    color: #ffffff;
    text-decoration: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0 0 0 0;
    list-style-type: none;
  }
  li {
    padding: 0 0 0 1em;
  }
`

/*
const SectionTitle = styled.div`
  color: #b0d1da;
  font-weight: 700;
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
`
*/
const Separator = styled.img`
  margin-top: -32px;
`

const RecovibBlah = <Section>Powered by Micromega Dynamics</Section>

const SiteFooter = props => {
  return (
    <Layout>
      <Separator src={logo} width="100%" alt="footer separator" />
      <VSpacer size={8} />
      {RecovibBlah}
    </Layout>
  )
}

SiteFooter.propTypes = {}

export default SiteFooter

/*
<Tiles>
  <Tiles.i />
  <Tiles.i>{RecovibBlah}</Tiles.i>
  <Tiles.i />
</Tiles>
*/
