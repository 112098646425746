import { initializeBackOffice } from '@mmd/domain-recovib/backoffice'
import Router from '@mmd/io/router'

export let router
export let backoffice = initializeBackOffice()

export const smurg = 'big'

export const getBackoffice = () => {
  return backoffice
}

export const getRouter = (...props) => {
  if (router === undefined) {
    router = new Router(...props)
  }
  return router
}
