import { urlsafe_decode } from './urlsafe-base64'
export const unpackDash = str => {
  return (str || '').split('#')
}

export const unpackByte = str => {
  return Uint8Array.from(urlsafe_decode(str), c => c.charCodeAt(0))
}

export const unpackInt8Array = str => {
  return Array.from(new Int8Array(unpackByte(str).buffer))
}

export const unpackUint8ClampedArray = str => {
  return Array.from(new Uint8ClampedArray(unpackByte(str).buffer))
}

export const unpackUInt16 = str => {
  return Array.from(new Uint16Array(unpackByte(str).buffer))
}

export const unpackInt16 = str => {
  return Array.from(new Int16Array(unpackByte(str).buffer))
}

export const unpackUInt32 = str => {
  return Array.from(new Uint32Array(unpackByte(str).buffer))
}

export const unpackInt32 = str => {
  return Array.from(new Int32Array(unpackByte(str).buffer))
}

export const unpackFloat32 = str => {
  return Array.from(new Float32Array(unpackByte(str).buffer))
}

export const unpackFloat64 = str => {
  return Array.from(new Float64Array(unpackByte(str).buffer))
}

export const unpack = str => {
  return new Float32Array(Uint8Array.from(urlsafe_decode(str), c => c.charCodeAt(0)).buffer)
}
