import { fromTsv } from '@mmd/fns/tsv'

function responseText(response) {
  if (!response.ok) throw new Error(response.status + ' ' + response.statusText)
  return response.text()
}

function fetchTsv(input, init) {
  return fetch(input, init).then(responseText)
}
/*


var myRequest = new Request('myImage.jpg');

fetch(myRequest, myInit)
  .then(function(response) {
    return response.blob();
  })
  .then(function(response) {
    var objectURL = URL.createObjectURL(response);
    myImage.src = objectURL;
  });
  */

export const fetchIfUndefined = (rows, fetcher, asyncReturn) => {
  if (rows === undefined) {
    fetcher(([f, r]) => {
      asyncReturn(r)
    })
  } else {
    asyncReturn(rows)
  }
}

export const fetchBurst = (slug, getBurstUrl, asyncReturn) => {
  const url = getBurstUrl(slug)
  fetchTsv(url)
    .then((tsv) => {
      const [fields, ...rows] = fromTsv(tsv.trim())
      if (typeof asyncReturn === 'function') {
        asyncReturn({ fields, rows })
      } else {
        console.warn('[BURST]', slug, { fields, rows })
      }
    })
    .catch((err) => {
      console.error('[ERROR]', err)
    })
}
