import React, { PureComponent } from 'react'

import { Notification, FlexBox } from './styled'
import Chart from 'react-apexcharts'
// mmd
import { VBox } from '@mmd/design-layout'
// local configs
import { getOptions } from './apex_options'

const CHART_HEIGHT_MAIN = 360

class TimeSeries extends PureComponent {
	constructor(props) {
		super(props)
		const { ymax = 7, maxStd = 5, xZoom, statTitle, statUnit, i18n } = props || {}
		const { main: optionsMain, brush: optionsBrush } = getOptions({
			uid: 'building',
			title: i18n || statTitle || 'Peak Acceleration (g)',
			colors: ['#28A138', '#F9971E', '#3B5998'],
			ymax,
			ymin: 0,
			maxStd,
			xZoom,
			tooltTipRounding: 5,
			yRounding: 1,
			safeLevelMarker: { color: '#0071ca', dash: 3, text: `${maxStd}${statUnit || ''}` }, // maxPoint >= maxStd ? { color: '#b22200', dash: 3 } : { color: '#3ca53a', dash: 0 }
		})
		this.state = { optionsMain, optionsBrush }
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		})
	}

	render() {
		const { series, xZoom } = this.props
		const { optionsMain, optionsBrush } = this.state

		if (!series.length) {
			return <Notification.Warn>We are unable to display the graph. No data are available yet.</Notification.Warn>
		}

		let apps = series.reduce((acc, d) => {
			const k = d.app
			if (!acc[k]) {
				acc[k] = []
			}
			acc[k].push(d)
			return acc
		}, {})

		let seriesAllBrush = [{ name: 'max', data: [] }]
		for (let i = 0, n = series[0].data.length; i < n; i++) {
			const max = Math.max(...series.map((d) => d.data[i][1]))
			seriesAllBrush[0].data.push([series[0].data[i][0], max])
		}

		const charts = Object.entries(apps).reduce((acc, [k, series]) => {
			series.forEach((serie, i) => {
				let brush = { name: 'max', data: [] }
				for (let i = 0, n = serie.data.length; i < n; i++) {
					const max = serie.data[i][1]
					brush.data.push([serie.data[i][0], max])
				}

				acc.push({ series: [serie], seriesBrush: brush })
			})
			return acc
		}, [])

		charts.sort((a, b) => {
			const order = ['Density', 'Alarm', 'Command']
			const oa = order.indexOf(a.series[0].name)
			const ob = order.indexOf(b.series[0].name)
			return oa - ob
		})

		console.log(charts)

		let _optionsAllBrush = JSON.parse(JSON.stringify(optionsBrush))
		_optionsAllBrush.chart.group = 'app'

		return (
			<VBox>
				<FlexBox>
					{charts.map((d, i) => {
						const { series } = d
						let _optionsMain = JSON.parse(JSON.stringify(optionsMain))
						const uid = series[0].name.split('-')[0]
						console.log('[uid]', uid)
						if (uid === 'smurf') {
						} else if (uid === 'Alarm') {
							_optionsMain.title.text = 'Particulate Concentration Alarm'
							_optionsMain.chart.id = `apexArea_${uid}`
							_optionsMain.chart.group = 'app'
							_optionsMain.yaxis = { ..._optionsMain.yaxis, min: 0, max: 100 }
							_optionsMain.annotations.yaxis[0].y = 50
							_optionsMain.annotations.yaxis[0].label.text = '50'
						} else if (uid === 'Command') {
							_optionsMain.title.text = 'Water Jet Command'
							_optionsMain.chart.id = `apexArea_${uid}`
							_optionsMain.chart.group = 'app'
							_optionsMain.yaxis = { ..._optionsMain.yaxis, min: 0, max: 100 }
							_optionsMain.annotations.yaxis[0].y = 50
							_optionsMain.annotations.yaxis[0].label.text = '50'
						} else if (uid === 'Density') {
							_optionsMain.title.text = 'Particulate Concentration (mg/m3)'
							_optionsMain.chart.id = `apexArea_${uid}`
							_optionsMain.chart.group = 'app'
							_optionsMain.yaxis = { ..._optionsMain.yaxis, min: 0, max: 10 }
							_optionsMain.annotations.yaxis[0].y = 1
							_optionsMain.annotations.yaxis[0].label.text = '1'
						}
						/*
						_optionsBrush.chart.id = `apexBrush_${uid}`
						_optionsBrush.chart.brush.target = `apexArea_${uid}`
						*/
						_optionsAllBrush.chart.brush.target = `apexArea_${uid}`

						if (xZoom) {
							_optionsMain.xaxis = { ..._optionsMain.xaxis, ...xZoom }
						}

						return (
							<VBox key={uid}>
								<Chart options={_optionsMain} series={series} type="line" height={CHART_HEIGHT_MAIN} />
								{/* <Chart options={_optionsBrush} series={seriesBrush} type="area" height={CHART_HEIGHT_BRUSH} /> */}
							</VBox>
						)
					})}
				</FlexBox>
				{/* <Chart options={_optionsAllBrush} series={seriesAllBrush} type="area" height={CHART_HEIGHT_BRUSH} />  */}
			</VBox>
		)
	}
}

export default TimeSeries

/*

*/
