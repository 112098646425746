import styled from 'styled-components'

export const Summary = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	flex-wrap: wrap;
	margin-top: 8px;
	width: 100%;
`
export const HBox = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	position: relative;
	margin-bottom: 2px;
	align-items: center;
	height: 24px;
	max-height: 24px;
`

export const Signals = styled.div``

export const Icon = styled.img`
	max-width: 24px;
	max-height: 24px;
	padding-right: 4px;
	opacity: ${(props) => (props.disabled ? 0.1 : 1)};
`

/*
const More = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  max-width: 32px;
`
*/

export const Sep = styled.div`
	display: inline-block;
	border-right: 1px dashed #afafaf;
	margin-right: 8px;
	padding-right: 4px;
	height: 100%;
`

export const Outputs = styled.div``

/*
const TimeStamp = styled.div`
  font-family: 'Avenir Next Condensed';
`
const StatusTechnique = styled.div``
*/

export const Actions = styled.div`
	text-align: right;
	flex: 1;
`
