import styled, { css } from 'styled-components'
import { darken } from '@mmd/design-tokens/colors/color-transform'

// transition: ${d => d.tokens.colors.transitions};

const defaultTokens = {
  primaryColor: '#0072bc',
  primaryColorDark: '#0072bc',
  primaryColorLight: '#ffffff',
  secondaryColor: undefined,
  bgColorLight: '#ffffff',
  borderWidth: '.1',
  fontSize: '1em',
  fontSizeSm: '.8rem',
  fontSizeLg: '1.2rem',
  lineHeight: '1li',
  controlSize: undefined,
  controlPaddingX: undefined,
  controlPaddingY: undefined,
  shadowColor: undefined,
  successColor: 'green',
  errorColor: 'red',
  linkColor: undefined,
  linkColorDark: undefined,
  controlSizeSm: undefined,
  controlPaddingXSm: undefined,
  controlPaddingYSm: undefined,
  controlSizeLg: undefined,
  controlPaddingXLg: undefined,
  controlPaddingYLg: undefined,
  sm: undefined,
  lg: undefined,
  unit4: '.8rem'
}
const Button = styled.button.attrs(props => {
  return { ...props, tokens: { ...defaultTokens, ...props.tokens } }
})`
  appearance: none;
  background: ${d => d.tokens.bgColorLight};
  border: ${d => d.tokens.borderWidth}rem solid ${d => d.tokens.primaryColor};
  border-radius: ${d => d.tokens.borderRadius}rem;
  color: ${d => d.tokens.primaryColor};
  cursor: pointer;
  font-size: ${d => d.tokens.fontSize};
  height: ${d => d.tokens.controlSize}rem;
  line-height: ${d => d.tokens.lineHeight};
  display: inline-block;
  outline: none;
  padding: ${d => d.tokens.controlPaddingY}rem ${d => d.tokens.controlPaddingX}rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &:focus {
    box-shadow: ${d => d.tokens.shadowColor};
  }
  &:focus,
  &:hover {
    text-decoration: none;
    background: ${d => d.tokens.secondaryColor};
    border-color: ${d => d.tokens.primaryColorDark};
  }
  &:active {
    background: ${d => d.tokens.primaryColorDark};
    border-color: ${tokens => darken(tokens.primaryColorDark, 0.05)};
    color: ${d => d.tokens.primaryColorLight};
    text-decoration: none;
  }
  &[disabled],&: disabled,
    ${tokens =>
      tokens.disabled &&
      css`
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
      `};
  ${d =>
    d.primary &&
    css`
      background: ${d => d.tokens.primaryColor};
      border-color: ${d => d.tokens.primaryColorDark};
      color: ${d => d.tokens.primaryColorLight};
      &:focus,
      &:hover {
        background: ${tokens => darken(tokens.primaryColorDark, 0.02)};
        border-color: ${tokens => darken(tokens.primaryColorDark, 0.05)};
        color: ${d => d.tokens.primaryColorLight};
      }
      &:active {
        background: ${tokens => darken(tokens.primaryColorDark, 0.04)};
        border-color: ${tokens => darken(tokens.primaryColorDark, 0.07)};
        color: ${d => d.tokens.primaryColorLight};
      }
      ${tokens =>
        tokens.loading &&
        css`
          &::after {
            border-bottom-color: ${d => d.tokens.primaryColorLight};
            border-left-color: ${d => d.tokens.primaryColorLight};
          }
        `};
    `};
  ${d =>
    d.success &&
    css`
      background: ${d => d.tokens.successColor};
      border-color: ${tokens => darken(tokens.successColor, 0.03)};
      color: ${d => d.tokens.primaryColorLight};
      &:focus {
      }
      &:focus,
      &:hover {
        background: ${tokens => darken(tokens.successColor, 0.02)};
        border-color: ${tokens => darken(tokens.successColor, 0.05)};
        color: ${d => d.tokens.primaryColorLight};
      }
      &:active {
        background: ${tokens => darken(tokens.successColor, 0.07)};
        border-color: ${tokens => darken(tokens.successColor, 0.1)};
        color: ${d => d.tokens.primaryColorLight};
      }
    `};
  ${d =>
    d.error &&
    css`
      background: ${d => d.tokens.errorColor};
      border-color: ${tokens => darken(tokens.errorColor, 0.03)};
      color: ${d => d.tokens.primaryColorLight};
      &:focus {
      }
      &:focus,
      &:hover {
        background: ${tokens => darken(tokens.errorColor, 0.02)};
        border-color: ${tokens => darken(tokens.errorColor, 0.05)};
        color: ${d => d.tokens.primaryColorLight};
      }
      &:active {
        background: ${tokens => darken(tokens.errorColor, 0.07)};
        border-color: ${tokens => darken(tokens.errorColor, 0.1)};
        color: ${d => d.tokens.primaryColorLight};
      }
    `};
  ${d =>
    d.link &&
    css`
      background: transparent;
      border-color: transparent;
      color: ${d => d.tokens.linkColor};
      &:focus,
      &:hover,
      &:active {
        background: transparent;
        border-color: transparent;
        color: ${d => d.tokens.linkColorDark};
      }
    `};
  ${d =>
    d.sm &&
    css`
      font-size: ${d => d.tokens.fontSizeSm};
      height: ${d => d.tokens.controlSizeSm}rem;
      padding: ${d => d.tokens.controlPaddingYSm}rem ${d => d.tokens.controlPaddingXSm}rem;
    `};
  ${d =>
    d.lg &&
    css`
      font-size: ${d => d.tokens.fontSizeLg};
      height: ${d => d.tokens.controlSizeLg}rem;
      padding: ${d => d.tokens.controlPaddingYLg}rem ${d => d.tokens.controlPaddingXLg}rem;
    `};
  ${d =>
    d.block &&
    css`
      display: block;
      width: 100%;
    `};
  ${d =>
    d.action &&
    css`
      padding-left: 0;
      padding-right: 0;
      ${d => d.tokens.sm && css``};
      ${d => d.tokens.lg && css``};
    `};
  ${d =>
    d.clear &&
    css`
      background: transparent;
      border: 0;
      color: currentColor;
      height: ${d => d.tokens.unit4}rem;
      line-height: ${d => d.tokens.unit4}rem;
      margin-left: ${d => d.tokens.unit1}rem;
      margin-right: -2px;
      opacity: 1;
      padding: 0;
      text-decoration: none;
      width: ${d => d.tokens.unit4}rem;
      &:hover {
        opacity: 0.95;
      }
      &:focus,
      &:active {
        background: transparent;
        border: 0;
        color: currentColor;
      }
      &::before {
      }
    `};
  ${d =>
    d.bottomradius &&
    css`
      border-bottom-left-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
    `};
  ${d =>
    d.topradius &&
    css`
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
    `};
`

export default Button
