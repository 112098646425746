export const burstAsSeries = (burst) => {
  const { rows, fields } = burst
  const cnames = fields.slice(1)
  const firstStamp = new Date(rows[0][0]).getTime()
  console.log('[firstSTamp]', firstStamp)
  const channelsx = rows.reduce((acc, [timestamp, ...cs], ri) => {
    const stamp = new Date(timestamp).getTime() - firstStamp
    if (ri < 5) {
      console.log('stamp', stamp)
    }
    cs.forEach((d, i) => {
      const k = cnames[i]
      if (!acc[k]) {
        acc[k] = []
      }
      acc[k][ri] = [parseFloat(d), stamp]
    })
    return acc
  }, {})

  return Object.entries(channelsx).map(([name, data]) => {
    return { name, data }
  })
}
