import styled from 'styled-components'
import { HBox, VBox } from '@mmd/design-layout'

export const ExplorerLayout = styled(VBox)`
	font-family: 'Roboto Condensed';
	width: 100%;
`

export const Layout = styled(HBox)`
  margin: 32px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-end;

  input {
    padding: 4px 8px;
  }
}
`

export const Tags = styled.div`
	font-family: Calibri;
`

export const Tag = styled.span`
	padding: 4px 8px;
	box-sizing: border-box;
	color: white;
	background-color: #0072bc;
	opacity: 0.9;
	border-radius: 4px;
	border-radius: 1px solid #0072bc;
	float: left;
	outline: 0;
	margin-left: 4px;
	font-size: 0.8rem;
`

/*

body {
    font-family: 'open sans', 'helvetica neue', helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #222;
    background: #ecf0f1;
  }

  #root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    min-width: 700px;
  }

  .tags {
    background: #fff;
    padding: 5px;
    overflow: hidden;
  }

  .tag {
    color: #fff;
    font-weight: bold;
    background: #3498db;
    float: left;
    padding: 5px 10px;
    border-radius: 10em;
    margin: 5px;

    button {
      background: transparent;
      border: 0;
      cursor: pointer;
    }
  }

  .tag-input {
    padding: 5px 10px;
    box-sizing: border-box;
    color: #7f8c8d;
    border: 0;
    float: left;
    margin: 10px 0;
    font-size: 16px;
    outline: 0;
  }

  code {
    font-size: 12px;
    background: #fcf8d0;
    display: inline-block;
    font-family: courier;
    padding: 4px 6px;
    border-radius: 4px;
  }

  small {
    color: #7f8c8d;
    font-size: 14px;
    margin-top: 10px;
    display: block;
    line-height: 16px;
  }

  */
