import { getAccountUrl } from '@mmd/domain-recovib/account'
import { getFirstStreamSlug } from '@mmd/domain-recovib/stream'

export const getBurstUrl = (activeLens) => {
  if (!activeLens) {
    return
  }
  const tenantUrl = getAccountUrl(activeLens)
  const streamSlug = getFirstStreamSlug(activeLens)
  return (slug) => {
    return `${tenantUrl}/_crons/daily-dump/__ignore/${streamSlug}/burst/${slug}.tsv`
  }
}
