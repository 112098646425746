export const triggerDownload = (fileName, contents, document) => {
  //Initialize file format you want csv or xls
  var uri = 'data:text/csv;charset=utf-8,' + encodeURI(contents)

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement('a')
  link.href = uri
  link.target = '_blank'

  //set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden'
  link.download = fileName

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
