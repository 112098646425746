import * as logger from '@mmd/io/logger'
import * as action from './ActionType'
import { asActionType } from '@mmd/flow/action-payload'
import { getBackoffice } from '../globals'
import { expandActiveLens } from '@mmd/domain-recovib/lens'

export const reduceLensActiveChange = (state, payload) => {
  const slug = payload.slug || payload
  const backoffice = getBackoffice()
  try {
    return { ...state, activeLens: expandActiveLens(backoffice, slug) }
  } catch (e) {
    return { ...state, activeLens: undefined }
  }
}

export const reduceBackToAccounts = (state, payload) => {
  return { ...state, activeLens: undefined }
}

export const dispatchBackToAccounts = (activeAccount) => {
  const signal = asActionType(action.BACK_TO_ACCOUNTS)
  return (dispatch) => dispatch(signal(activeAccount))
}

export const lensReducer = [
  {
    activeLens: undefined,
    activeRoute: undefined,
  },
  {
    [action.LENS_ACTIVE_CHANGE]: reduceLensActiveChange,
    [action.BACK_TO_ACCOUNTS]: reduceBackToAccounts,
  },
]
