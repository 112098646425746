import { tables } from '@mmd/domain-recovib/backoffice/tables'
export const TABLE = tables.UUID

export const getSecret = (getUuid, accountSlug) => {
  if (!accountSlug) {
    return
  }

  const data = getUuid(accountSlug)
  if (data) {
    const { uuid } = data
    return uuid
  } else {
    throw new Error(`Cannot get UUIDS for slug: ${accountSlug}`)
  }
}
