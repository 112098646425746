import React from 'react'
import AccountCard from '@mmd/block-recovib/account-card'
import * as s from './styled'

const AccountsExplorer = (props) => {
	const { accounts, expandAccount, ...passOn } = props
	const fragmentFactory = (d) => {
		const { lenses, expandLens } = expandAccount(d)
		return (
			<s.ListItem key={d.slug}>
				<AccountCard {...{ ...d, ...passOn, lenses, expandLens }} />
			</s.ListItem>
		)
	}
	return <s.List>{accounts.map(fragmentFactory)}</s.List>
}

AccountsExplorer.propTypes = {}
export default AccountsExplorer
