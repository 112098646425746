import styled from 'styled-components'

export const HSpacer = styled.span`
  display: inline-block;
  width: ${props => props.width || props.size || 0}px;
`

export const VSpacer = styled.div`
  height: ${props => props.height || props.size || 0}px;
`

/*
// https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js

export const spacing = {
  px: '1px',
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem',
  '16': '4rem',
  '20': '5rem',
  '24': '6rem',
  '32': '8rem',
  '40': '10rem',
  '48': '12rem',
  '56': '14rem',
  '64': '16rem'
}
*/

/*
// https://github.com/primer/css/blob/master/src/support/variables/layout.scss
// These are our margin and padding utility spacers. The default step size we
// use is 8px. This gives us a key of:
[
  0,
  4,
  8,
  16,
  24,
  32,
  40,
  48
]

//    0 => 0px
//    1 => 4px
//    2 => 8px
//    3 => 16px
//    4 => 24px
//    5 => 32px
//    6 => 40px
$spacer: 8px !default;

// Our spacing scale
$spacer-0: 0 !default;                  // 0
$spacer-1: round($spacer / 2) !default; // 4px
$spacer-2: $spacer !default;            // 8px
$spacer-3: $spacer * 2 !default;        // 16px
$spacer-4: $spacer * 3 !default;        // 24px
$spacer-5: $spacer * 4 !default;        // 32px
$spacer-6: $spacer * 5 !default;        // 40px

// Em spacer variables
$em-spacer-1: 0.0625em !default; // 1/16
$em-spacer-2: 0.125em !default;  // 1/8
$em-spacer-3: 0.25em !default;   // 1/4
$em-spacer-4: 0.375em !default;  // 3/8
$em-spacer-5: 0.5em !default;    // 1/2
$em-spacer-6: 0.75em !default;   // 3/4

$grid-gutter:     10px !default;

*/
