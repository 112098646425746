import { tables } from './tables'

import { table as table__organisation } from '@mmd/backoffice-data/organisation'
import { table as table__role } from '@mmd/backoffice-data/role'
import { table as table__user } from '@mmd/backoffice-data/user'
import { table as table__account } from '@mmd/backoffice-data/account'
import { table as table__lens } from '@mmd/backoffice-data/lens'
import { table as table__data_stream } from '@mmd/backoffice-data/data_stream'
import { table as table__setup } from '@mmd/backoffice-data/setup'
import { table as table__uuid } from '@mmd/backoffice-data/uuid'

const initialState = {
  table: {
    [tables.ORGANISATION]: [
      {
        slug: 'backoffice-problem',
        i18n: 'Backoffice Problem',
        roles: [],
        accounts: [],
      },
    ],
    [tables.USER]: [],
    [tables.ACCOUNT]: [],
    [tables.LENS]: [],
    [tables.DATA_STREAM]: [],
    [tables.STREAM_CONFIG]: [],
    [tables.SETUP]: [],
    [tables.UUID]: [],
  },
}

export const initializeBackOfficeFromObj = (obj) => {
  const state = initialState
  Object.entries(obj).forEach(([k, v]) => {
    state.table[k] = v
  })
  return state
}

export const initializeBackOffice = (obj) => {
  return initializeBackOfficeFromObj({
    [tables.ORGANISATION]: table__organisation,
    [tables.ROLE]: table__role,
    [tables.USER]: table__user,
    [tables.ACCOUNT]: table__account,
    [tables.LENS]: table__lens,
    [tables.DATA_STREAM]: table__data_stream,
    [tables.SETUP]: table__setup,
    [tables.UUID]: table__uuid,
  })
}
