import * as action from './ActionType'
import { getBackoffice } from '../globals'
import { asActionType } from '@mmd/flow/action-payload'
import { asAuthenticatedUser } from '@mmd/domain-recovib/site-user'
import { expandAdmin, validateUser } from '@mmd/domain-recovib/site-user'
// import { initialize as initializeLens } from '@mmd/domain-recovib/lens/Singleton'

// ############################################################
// ## Constants
// ############################################################

export const ROLE_ADMIN = 'ROLE_ADMIN'

// ############################################################
// ## Utils
// ############################################################

export const getUserInQuery = () => {
  const queryString = new URLSearchParams(window.location.search)
  return { role: queryString.get('role') === 'admin' ? ROLE_ADMIN : undefined, user: queryString.get('user') }
}

// ############################################################
// ## Payload dispatchers
// ############################################################

export const dispatchLetInIfAdmin = () => {
  const backoffice = getBackoffice()
  const signalLogin = asActionType(action.LOGIN_REQUEST)
  return (dispatch) => {
    let { role, user } = getUserInQuery() || {}
    let admin = expandAdmin(backoffice, user, role === ROLE_ADMIN)
    if (admin) {
      dispatch(signalLogin(admin))
    }
  }
}

export const dispatchAuthenticateWithQueryString = ({ user, pass }) => {
  const signalLogin = asActionType(action.LOGIN_REQUEST)
  return (dispatch) => dispatch(signalLogin({ user, pass }))
}

export const dispatchAuthenticateUser = ({ user, pass }) => {
  const backoffice = getBackoffice()
  const signalLogin = asActionType(action.LOGIN_REQUEST)
  const userAndPass = asAuthenticatedUser(backoffice, user, pass)
  return (dispatch) => dispatch(signalLogin(userAndPass))
}

// ############################################################
// ## State Reducers
// ############################################################

export const reduceLoginRequest = (state, payload) => {
  const { user, permissions, accounts, screenName, branding } = payload
  const credentials = validateUser({ user, permissions, accounts, screenName, branding })
  return { ...state, ...credentials }
}

export const reduceLogoutSuccess = (state = {}, action) => {
  return undefined
}

// ############################################################
// ## Props Injectors
// ############################################################

export const siteProps = [
  (state) => {
    const { login, permissions, role, screeName, branding } = state.app
    const { user, authError } = login
    return { user, authError, permissions, role, screeName, branding }
  },
  (dispatch) => {
    return {
      whenAuthenticateAttempt: ({ user, pass }) => dispatch(dispatchAuthenticateWithQueryString({ user, pass })),
    }
  },
]

// ############################################################
// ## Store Reducers
// ############################################################

export const loginProps = [
  (state) => {
    const { authError } = state.app.login
    return { authError }
  },
  (dispatch) => ({
    whenAuthenticateAttempt: ({ user, pass }) => dispatch(dispatchAuthenticateUser({ user, pass })),
  }),
]
