import * as action from './ActionType'
import { asActionType } from '@mmd/flow/action-payload'

export const dispatchBackendDown = (error) => {
  const signalDown = asActionType(action.SET_BACKEND_DOWN)
  return (dispatch) => dispatch(signalDown(error))
}

export const setBackendStatus = (state, payload) => {
  const backendDown = payload || false
  return backendDown
}
export const backendDownReducer = [
  false,
  {
    [action.SET_BACKEND_DOWN]: setBackendStatus,
  },
]
