import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import createReducer from '@mmd/flow/create-reducer'
import middlewares from '@mmd/flow/thunk-logger-middlewares'

export const configureReduxStore = (combinedReducers) => {
  return createStore(
    combineReducers(
      combinedReducers.reduce((acc, d) => {
        const [name, initial, reducer] = d
        acc[name] = createReducer(initial, reducer)
        return acc
      }, {})
    ),
    {},
    compose(applyMiddleware(...middlewares))
  )
}
