import React, { Component } from 'react'
import * as s from './styled'
import AccountLensSummary from '@mmd/block-recovib/account-lens-summary'

class AccountCardExplorer extends Component {
	render() {
		let fragment = null
		let { whenLens, expandAccount, ...account } = this.props
		let { slug, i18n, status, location_i18n, lenses, expandLens } = account
		if (slug) {
			const image = slug || ''
			fragment = (
				<s.Card>
					<s.Head>
						<s.AccountStatus status={status}>{(status || '').toUpperCase()}</s.AccountStatus>
						<s.Title>{i18n}</s.Title>
						<s.City>{location_i18n}</s.City>
					</s.Head>
					<s.HBox>
						<s.ProfileImage src={`https://assets.recovib.io/accounts/${image || 'unknown'}.png`} />
						<s.List>
							{lenses.map((d) => {
								const { slug, i18n, ...etc } = expandLens(d)
								return (
									<s.Item key={slug}>
										<s.LensTitle>{i18n}</s.LensTitle>
										<AccountLensSummary {...{ slug, status, ...etc, whenLens }} />
									</s.Item>
								)
							})}
							{/* Actions a prendre en cas de probleme */}
							{/* <AccountActions /> */}
						</s.List>
					</s.HBox>
				</s.Card>
			)
		}
		return fragment
	}
}

export default AccountCardExplorer
