const getTriggers = (setups) => {
  return setups
    .reduce((acc, d) => {
      const m = (d.match(/trigger:(air-pressure|vibration|dust|voltage|sns|temperature)/) || [null, null])[1]
      if (m && !acc.includes(m)) {
        acc.push(m)
      }
      return acc
    }, [])
    .sort()
}

const getIx = (setups) => {
  return setups.reduce((acc, d) => {
    const m = (d.match(/ix:.*$/) || [null, null])[1]
    if (m && !acc.includes(m)) {
      acc.push(m)
    }
    return acc
  }, [])
}

const getTypes = (setups) => {
  return setups.reduce((acc, d) => {
    const m = (d.match(/type:(burst-data)/) || [null, null])[1]
    if (m && !acc.includes(m)) {
      acc.push(m)
    }
    return acc
  }, [])
}

export const collectStreamsSetups = (setupIds, expandSetup) => {
  const setups = setupIds.reduce((acc, items) => {
    if (items) {
      items.forEach((d) => {
        d = expandSetup(d)
        acc.push(d.slug)
        ;(d.tags || []).forEach((tags) => {
          acc = acc.concat(tags)
        })
      })
    }
    return acc
  }, [])

  const triggers = getTriggers(setups)
  const ix = getIx(setups)
  const types = getTypes(setups)

  const hasBurstData = types.includes('burst-data')
  const hasEmail = ix.includes('email')
  const hasSms = ix.includes('sms')

  return { triggers, hasBurstData, hasEmail, hasSms }
}
