import React, { Component } from 'react'
import styled from 'styled-components'
import SplitNav from '@mmd/design-system/split-nav'
// import texture from './arches.png'
import BrandLogo from '@mmd/block-recovib/masthead-affiliate-logo'
import RecovibLogo from '@mmd/block-recovib/recovib-logo'

const Layout = styled.div`
  background-color: #d0e3e9; /*  #b0d1da; #d0e3e9; rgba(104,141,152,.6); rgba(176,209,218,.2) #eaf5f8 #f3f8f9  */
  box-shadow: 0px 2px 8px rgba(173, 173, 173, 0.6);
  position: relative;
  z-index: 999999;
  padding: 12px 32px 4px 32px;
  opacity: 1;
  background
`

const right = (
	<div>
		<RecovibLogo height={48} />
	</div>
)

class Masthead extends Component {
	render() {
		const { branding } = { ...this.props }
		const logo = <BrandLogo height={38} branding={branding || 'micromega'} />
		return (
			<Layout>
				<SplitNav main={logo} right={right} />
			</Layout>
		)
	}
}

// <SplitNav />

Masthead.propTypes = {}

export default Masthead
