//User's actions
export const AUTH_DEFAULT = 'AUTH_DEFAULT'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const INITIALIZE_BACKOFFICE = 'INITIALIZE_BACKOFFICE'

// CONFIG
export const SET_CONFIG = 'SET_CONFIG'

// Status
export const SET_BACKEND_DOWN = 'BACKEND_DOWN'

// Routes
export const BACK_TO_ACCOUNTS = 'BACK_TO_ACCOUNTS'

// Account
export const ACCOUNT_ACTIVE_CHANGE = 'ACCOUNT_ACTIVE_CHANGE'

// Data
//-- many
export const LENS_ACTIVE_CHANGE = 'LENS_ACTIVE_CHANGE'

// Stream
export const TIME_RANGE_CHANGE = 'TIME_RANGE_CHANGE'
export const STREAM_DATA_CHANGE = 'STREAM_DATA_CHANGE'

//Burst
//-- many
export const SET_TAGS = 'SET_TAGS'
export const BURST_DATA_CHANGE = 'BURST_DATA_CHANGE'
