import React from 'react'
import { Helmet } from 'react-helmet'
import SinglePageApp from '@mmd/block-app/single-page-app/'
import Masthead from '@mmd/block-recovib/recovibcloud-masthead/'
import Footer from '@mmd/block-recovib/recovibcloud-footer/'

export const i18n = { appTitle: 'Recovib Cloud' }

const RecovibSite = (props) => {
	const { children, branding } = props
	const fragments = {
		helmet: (
			<Helmet>
				<meta charSet="utf-8" />
				<title>{i18n.appTitle}</title>
				<link rel="canonical" href="http://recovib-cloud.com" />
				<link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
			</Helmet>
		),
		masthead: <Masthead branding={branding} padding={8} />,
		footer: <Footer />,
	}
	return <SinglePageApp {...fragments}>{children}</SinglePageApp>
}

export default RecovibSite
