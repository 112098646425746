import React, { PureComponent } from 'react'

import styled from 'styled-components'
import Chart from 'react-apexcharts'
// mmd
import { VBox } from '@mmd/design-layout'
// local configs
import { getOptions } from './apex_options'

// :TODO: move the Notification Box to design system
const Notification = {}
Notification.Warn = styled.div`
	background-color: #ef9a9a;
	border: 2px solid #d32f2f;
	padding: 16px;
`

const FlexBox = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	& > * {
		width: 45%;
		min-width: 45%;
		max-width: 45%;
		overflow: hidden;
	}
`

const CHART_HEIGHT_MAIN = 360

class TimeSeries extends PureComponent {
	constructor(props) {
		super(props)
		const { ymax = 7, maxStd = 5, xZoom, statTitle, statUnit, i18n } = props || {}
		const { main: optionsMain, brush: optionsBrush } = getOptions({
			uid: 'building',
			title: i18n || statTitle || 'Peak Acceleration (g)',
			colors: ['#28A138', '#F9971E', '#3B5998'],
			ymax,
			ymin: 0,
			maxStd,
			xZoom,
			tooltTipRounding: 5,
			yRounding: 1,
			safeLevelMarker: { color: '#0071ca', dash: 3, text: `${maxStd}${statUnit || ''}` }, // maxPoint >= maxStd ? { color: '#b22200', dash: 3 } : { color: '#3ca53a', dash: 0 }
		})
		this.state = { optionsMain, optionsBrush }
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		})
	}

	render() {
		const { series, xZoom } = this.props
		const { optionsMain, optionsBrush } = this.state

		if (!series.length) {
			return <Notification.Warn>We are unable to display the graph. No data are available yet.</Notification.Warn>
		}

		let apps = series
			.sort((a, b) => {
				const order = ['steam0', 'steam3', 'steam1', 'steam2']
				const oa = order.indexOf(a.app)
				const ob = order.indexOf(b.app)
				return oa - ob
			})
			.reduce((acc, d) => {
				const k = d.app
				if (!acc[k]) {
					acc[k] = []
				}
				acc[k].push(d)
				return acc
			}, {})

		let seriesAllBrush = [{ name: 'max', data: [] }]
		for (let i = 0, n = series[0].data.length; i < n; i++) {
			const max = Math.max(...series.map((d) => d.data[i][1]))
			seriesAllBrush[0].data.push([series[0].data[i][0], max])
		}

		const charts = Object.entries(apps).map(([k, v]) => {
			const series = v
			let seriesBrush = [{ name: 'max', data: [] }]
			for (let i = 0, n = series[0].data.length; i < n; i++) {
				const max = Math.max(...series.map((d) => d.data[i][1]))
				seriesBrush[0].data.push([series[0].data[i][0], max])
			}
			return { series, seriesBrush }
		})

		let _optionsAllBrush = JSON.parse(JSON.stringify(optionsBrush))
		_optionsAllBrush.chart.group = 'app'

		return (
			<VBox>
				<FlexBox>
					{charts.map((d, i) => {
						const { series } = d
						let _optionsMain = JSON.parse(JSON.stringify(optionsMain))
						const uid = series[0].name.split('-')[0]

						_optionsMain.title.text = [_optionsMain.title.text, uid].join(' - ')
						_optionsMain.chart.id = `apexArea_${uid}`
						_optionsMain.chart.group = 'app'
						/*
						_optionsBrush.chart.id = `apexBrush_${uid}`
						_optionsBrush.chart.brush.target = `apexArea_${uid}`
						*/
						_optionsAllBrush.chart.brush.target = `apexArea_${uid}`

						if (xZoom) {
							_optionsMain.xaxis = { ..._optionsMain.xaxis, ...xZoom }
						}

						return (
							<VBox key={uid}>
								<Chart options={_optionsMain} series={series} type="line" height={CHART_HEIGHT_MAIN} />
								{/* <Chart options={_optionsBrush} series={seriesBrush} type="area" height={CHART_HEIGHT_BRUSH} /> */}
							</VBox>
						)
					})}
				</FlexBox>
				{/* <Chart options={_optionsAllBrush} series={seriesAllBrush} type="area" height={CHART_HEIGHT_BRUSH} />  */}
			</VBox>
		)
	}
}

export default TimeSeries

/*

*/
