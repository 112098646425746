import React, { Fragment } from 'react'
// design primitives
import { HSpacer } from '@mmd/design-layout'
import * as s from './styled'
import TagManager from '@mmd/domain-recovib/burst/BurstTagManager'

class BurstTags extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			value: '',
			isEditing: false,
			tagManager: undefined,
			tags: null,
		}
		this.input = React.createRef()
		this.bound = {
			onEditClick: (e) => {
				this.setState({
					isEditing: true,
				})
			},
			onSaveClick: (e) => {
				const { tagManager, airId } = this.state
				const tags = this.input.current.value
				tagManager.updateTags(tags, airId)
				this.setState({
					tags,
					isEditing: false,
				})
			},
			onCreateClick: (e) => {
				const { tagManager, burstSlug } = this.state
				const tags = this.input.current.value
				tagManager.createTags(tags, burstSlug, (airId) => {
					this.setState({
						airId,
					})
				})
				this.setState({
					tags,
					isEditing: false,
				})
			},
		}
	}

	componentDidMount() {
		this.loadTags()
	}

	componentDidUpdate(prevProps, prevState) {
		this.loadTags()
	}

	loadTags() {
		if (this.state.tags === null) {
			const { burstSlug, tagManager } = this.state
			if (burstSlug) {
				tagManager.retrieveTags(burstSlug, (tags, airId) => {
					this.setState({ tags, airId })
				})
			} else {
				this.setState({ tags: [] })
			}
		}
	}

	static getDerivedStateFromProps(props, state) {
		// Any time the current user changes,
		// Reset any parts of state that are tied to that user.
		// In this simple example, that's just the email.
		const { tableName } = props
		const tagManager = state.tagManager || new TagManager(tableName)
		const { slug: burstSlug } = props.burst || {}
		if (burstSlug && burstSlug !== state.burstSlug) {
			return {
				burstSlug,
				tags: null,
				tagManager,
			}
		}
		return null
	}

	render() {
		const { burstSlug, isEditing, tags } = this.state
		const { onEditClick, onSaveClick, onCreateClick } = this.bound
		const noTags = !tags || !tags.length ? true : false
		if (!burstSlug) {
			return null
		}
		return (
			<s.Layout>
				{isEditing || noTags ? (
					<Fragment>
						<input defaultValue={tags} type="text" ref={this.input} />
						<HSpacer size={16} />
						{noTags ? <button onClick={onCreateClick}>add tags</button> : <button onClick={onSaveClick}>save</button>}
					</Fragment>
				) : (
					<Fragment>
						<s.Tags>
							{tags.split(/\s*,\s*/).map((d) => {
								return <s.Tag key={d}>{d}</s.Tag>
							})}
						</s.Tags>
						<HSpacer size={16} />
						<button onClick={onEditClick}>edit</button>
					</Fragment>
				)}
			</s.Layout>
		)
	}
}

export default BurstTags
