import moment from 'moment'

export const periodic = (timestamp, period) => {
  return (i) => {
    return moment(timestamp)
      .add(period * i, 'milliseconds')
      .toISOString()
  }
}

export const removeMs = (iso) => {
  return iso.replace(/\.[^Z]+Z/, 'Z')
}

export const fromYmdHms = (ymd, hms) => {
  return isoTime(hms ? moment(`${ymd}T${hms}Z`) : moment(ymd, 'YYYY-MM-DD'))
}

export const isoTime = (mom) => {
  return moment(mom).toISOString()
}

export const addMilliseconds = (timestamp, keepMS = true) => {
  const mom = moment(timestamp)

  return (ms) => {
    const iso = isoTime(moment(mom).add(ms, 'milliseconds'))
    return keepMS ? iso : removeMs(iso)
  }
}

export const durationInSeconds = (startIso, endIso) => {
  return moment(endIso).diff(startIso, 'seconds')
}

export const expandIsoString = (dt) => {
  // .tz("Australia/Melbourne", "Europe/Brussels")
  // var a = moment("2013-11-18 11:55").tz("Asia/Taipei");
  // To use moment-timezone, you will need moment@2.9.0+, moment-timezone.js, and the moment-timezone data.

  if (typeof dt !== 'string' || !dt.match(/Z$/)) {
    console.warn('[ERR] Not an ISO date', dt)
    return ''
  }
  const mom = moment(dt)

  const utcOffset = mom.utcOffset()
  const weekDay = mom.isoWeekday()
  const quarter = mom.quarter()
  const year = mom.year()
  const weekOfYear = mom.isoWeek()

  let [mtch, ymd, hour, minsec] = dt.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}):(\d{2}:\d{2})/) || []

  const duration = moment.duration(moment(dt).diff(moment(`${ymd}T00:00:00.000Z`))) // .diff gives the difference in milliseconds

  const round = { h: duration.hours(), m: duration.minutes(), s: duration.seconds(), ms: duration.milliseconds() }
  const intoDay = {
    h: parseFloat(duration.asHours().toFixed(3)),
    m: parseFloat(duration.asMinutes().toFixed(0)),
    s: parseFloat(duration.asSeconds().toFixed(0)),
    ms: parseFloat(duration.asMilliseconds().toFixed(0)),
  }

  const utc = moment.duration(utcOffset, 'minutes')
  const monday = moment(`${ymd}T00:00:00Z`).isoWeekday(2) // :NOTE: monday... :REVIEW:  .isoWeekday(2) gives the same outcome
  const [startOfWeek] = monday.toISOString().split('T')

  return {
    dt,
    dtsec: dt.replace(/\.\d+Z$/, 'Z'), // no rounding up
    periodicity: {
      yearly: year,
      quarterly: `${year}_q${quarter}`,
      weeknumber: `${year}_w${weekOfYear}`,
      weekly: startOfWeek,
      daily: ymd,
      hourly: `${ymd}_h${hour}`,
    },
    time: {
      wd: moment().isoWeekday(weekDay).format('dd'),
      dn: weekDay,
      hour,
      minsec,
      ms: round.ms,
    },
    intoDay,
    offset: {
      off: `${utc.hours().toString().padStart(2, '0')}:${utc.minutes().toString().padStart(2, '0')}`,
      minutesOff: utcOffset,
    },
  }
}
