import React, { PureComponent } from 'react'
import styled from 'styled-components'
// Base
import { VBox } from '@mmd/design-layout'
import DataTable from '@mmd/block/data-table'
import moment from 'moment'
import { fetchIfUndefined, filterEventRows } from '@mmd/io/dh-tables'

const VB2 = styled.span`
	display: inline-block;
	background-color: ${(props) => {
		return props.vb2 > 5 ? `red` : props.vb2 > 3 ? `orange` : props.vb2 > 1 ? `rgba(80, 175, 80, 0.15)` : undefined
		// return props.vb2 > 15 ? `red` : props.vb2 > 10 ? `orange` : props.vb2 > 5 ? `rgba(80, 175, 80, 0.15)` : undefined
	}};
	width: 12px;
	height: 12px;
	margin-left: 4px;
	border-radius: 6px;
	vertical-align: center;
	text-align: right;
	line-height: 0.8em;
`

let rows

export const runIfFunction = (fn, ...args) => {
	if (typeof fn === 'function') {
		fn(...args)
	}
}

class Events extends PureComponent {
	constructor(props) {
		super(props)
		this.state = { events: [] }
	}

	componentDidMount() {
		rows = undefined
		this.initializeBlock()
		this.loadDays()
	}

	componentDidUpdate(prevProps) {
		if (this.props.days !== prevProps.days || this.props.showAll !== prevProps.showAll) {
			this.loadDays()
		}
	}
	initializeBlock() {
		const { initializeBlock } = this.props
		runIfFunction(initializeBlock)
	}

	loadDays() {
		const { showAll, days, fetchEventListing, eventMin } = this.props
		const ymds = showAll ? undefined : days
		fetchIfUndefined(undefined, fetchEventListing, (r) => {
			rows = r
			const events = filterEventRows(r, ymds)
			const filteredEvents = events.filter((d) => d.max > eventMin)
			this.setState({ events: filteredEvents })
		})
	}

	render() {
		const { events } = this.state
		const { legend = 'Peak', unit = 'Peak' } = this.props

		const tableRenderer = {
			headFragments: (d) => {
				return [' ', legend, 'Time']
			},
			rowKey: (d) => {
				const iso = d.start
				const max = parseFloat((d.max || '').trim())
				return `${iso}__${max}`
			},
			rowFragments: (d) => {
				const { max, start, channels } = d
				const timestamp = moment(start).format('llll')
				const channelsF = channels.replace(/(\d+\.\d{3})\d+/g, '$1')

				return [<VB2 vb2={max}>&nbsp;&nbsp;</VB2>, channelsF, timestamp]
			},
			widths: ['40px', '210px', 'auto'],
		}

		return (
			<VBox>
				<h3>Events with flagging based on highest {unit} in mm/s</h3>
				<DataTable renderer={tableRenderer} items={events} />
			</VBox>
		)
	}
}

export default Events
