import React, { PureComponent, Fragment } from 'react'
import { HSpacer } from '@mmd/design-layout'
import Button from '@mmd/design-system/button'

const withViewAndDownloadButtons = (actions) => {
	// ...and returns another component...
	const onItemAction = (event) => {
		const { action: type, slug } = event.currentTarget.dataset
		const action = actions && actions[type]
		if (typeof action === 'function') {
			action(slug)
		}
	}
	class Actions extends PureComponent {
		render() {
			const { slug, isActive } = this.props
			return (
				<Fragment>
					<Button key="action-view" primary onClick={onItemAction} data-action="view" data-slug={slug}>
						View
					</Button>
					<HSpacer width={8} />
					{isActive && (
						<Button key="action-download" primary onClick={onItemAction} data-action="download" data-slug={slug}>
							Download
						</Button>
					)}
				</Fragment>
			)
		}
	}
	return Actions
}

export default withViewAndDownloadButtons
