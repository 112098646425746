import React, { Fragment } from 'react'

const Widget = ({ data, meta = {}, Factory }) => {
	const { loading, error, nodata, ...props } = data || {}
	let fragment = null
	if (error) {
		fragment = <Fragment>Error, we could not load the data. Try reloading the page and please let us know if this problem repeats.</Fragment>
	} else if (loading) {
		fragment = <Fragment>Loading...</Fragment>
	} else if (nodata) {
		fragment = <Fragment>No Data Found for that period</Fragment>
	} else if (!Factory) {
		fragment = <Fragment>No Widget Factory specified</Fragment>
	} else {
		fragment = <Factory {...props} {...meta} />
	}

	return fragment
}

export default Widget
