import { periodic } from '@mmd/fns/datetime-iso/isoDateString'
import { roundDec } from '@mmd/fns/math-round'
import { isBurst } from '@mmd/fns/tlm-burst/utils'

const ROUND_DEC = 3

const startTime = (d) => {
  return d.acq_dt
}

const getStat = (d, stat) => {
  return (d.data || d[stat] || '').split('|')
}

const asInt = (d) => parseInt(d, 10)

const appOverChannel = (doc) => {
  let appNames = doc.name.split('|') // "crazy|goofy|erupt|sworn",
  let channelsInApp = doc.n_channels.split('|').map(asInt) // "2|2|2|2"
  let nstats = (doc.n_stats.split('|') || doc.n_raw_data.split('|')).map(asInt) // "10|10|10|10" || "15000|15000"
  let pstats = doc.p_stats.split('|').map(asInt) // "30|30|30|30" || ""

  const nStatData = doc.data ? doc.data.split('|').length : 0

  return channelsInApp.reduce((acc, d, i) => {
    let app = appNames[i]
    let nchannels = channelsInApp[i]
    let ndata = nstats[i] || nStatData / nchannels
    acc.push(
      ...Array(nchannels)
        .fill(null)
        .map((d, idx) => ({ n: ndata, p: pstats[i], app, idx }))
    )
    return acc
  }, [])
}

const flatChannels = (doc) => {
  const isStat = !isBurst(doc)

  let ds = doc.virtual_channel_dsf.split('|').map(asInt) || '1000|1000'
  let ls = doc.virtual_channel_names.split('|') || 'FL-Y|FL-Z'
  let us = doc.virtual_channel_units.split('|') || 'g|g'

  return appOverChannel(doc).map((d, i) => {
    /*
    :CHANGE: 
    was: `const pms = isStat ? p_stats * virtual_channel_dsf : 1000 / virtual_channel_dsf`
    correct: `const pms = isStat ? p_stats : 1000 / virtual_channel_dsf`
    however, for stats, the dsf value should never be used.
    */
    const pms = isStat ? d.p * 1000 : 1000 / ds[i]
    return { ...d, pms, unit: us[i], lbl: ls[i] }
  })
}
const sortOnDT = (a, b) => {
  return a[0] - b[0]
}
export const apexSeries = (docs, noStats = false) => {
  const apps = (docs || []).reduce((acc, doc, i) => {
    if (!doc) {
      return acc
    }
    const channels = flatChannels(doc)
    const stats = noStats ? [] : getStat(doc, 'peak_data')
    const timestamp = startTime(doc)
    const id = doc.id
    // 4 apps  1|2|3|4
    while (channels.length) {
      const channel = channels.shift()
      const { n, pms, unit, lbl, app, idx } = channel
      const stamper = periodic(timestamp, pms)
      const asStamped = (d, i) => [new Date(stamper(i).replace(/\.\d+Z$/, '.000Z')).valueOf(), roundDec(d, ROUND_DEC)]

      let items
      if (noStats) {
        items = [[new Date(timestamp.replace(/\.\d+Z$/, '.000Z')).valueOf(), roundDec(doc.raw_data_summary, ROUND_DEC), timestamp, doc.id]]
      } else {
        items = stats.splice(0, n).map(asStamped).sort(sortOnDT)
        // .map((d, i) => [stamper(i).replace(/\.\d+Z$/, '.000Z'), roundDec(d, ROUND_DEC), timestamp, i])
      }

      const uid = `${app}_${lbl}`
      if (!acc[uid]) {
        acc[uid] = { name: lbl, uid, app, idx, unit, lbl, n, pms, id, data: items }
      } else {
        acc[uid].data.push(...items)
      }
    }
    return acc
  }, {})
  return Object.values(apps)
}
