import { buildOptions } from '@mmd/block-recovib/chart-defaults'
import { lineColors } from '@mmd/block-recovib/chart-defaults'

export const dinOptions = options => {
  const builtOptions = buildOptions(options)
  const { Fmax } = options
  return {
    ...builtOptions,
    legend: {
      markers: {
        width: [0, 12, 12, 12]
      }
    },
    fill: {
      type: 'solid'
    },
    colors: ['#000000', ...lineColors],
    markers: {
      size: [0, 5, 5, 5],
      colors: ['#000000', ...lineColors]
    },
    xaxis: {
      title: { text: 'fmg [Hz]' },
      type: 'numeric',
      min: 0,
      max: Fmax,
      tickAmount: 25,
      labels: {
        rotate: -90,
        rotateAlways: true,
        formatter: d => parseFloat(d).toFixed(1)
      }
    },
    yaxis: {
      title: { text: 'PPV' },
      type: 'numeric',
      min: 0,
      max: 30,
      labels: {
        formatter: d => d.toFixed(2)
      }
    },
    stroke: {
      width: [1, 0],
      curve: 'straight',
      dashArray: [0, 8]
    }
  }
}
