import { getItemExpander } from '@mmd/domain-recovib/backoffice'
import { lensTable } from '@mmd/domain-recovib/lens'
import { setupTable } from '@mmd/domain-recovib/setup'
import { streamTable } from '@mmd/domain-recovib/stream'
import { collectStreamsSetups } from '@mmd/domain-recovib/setup'
import { propSort } from '@mmd/domain-recovib/backoffice'

export const expandAccount = (backoffice) => {
  return (d) => {
    const expandLensId = getItemExpander(backoffice, lensTable)
    const expandStream = getItemExpander(backoffice, streamTable)
    const expandSetup = getItemExpander(backoffice, setupTable)

    const lenses = d.lenses.map(expandLensId).sort(propSort('i18n'))
    const expandLens = (d) => {
      const { slug, i18n, status, data_streams, ...etc } = d
      if (data_streams) {
        const dataStreams = data_streams.map(expandStream)
        const setupIds = dataStreams.map((d) => {
          return d && d.setups ? (d.setups || []).sort(propSort('slug')) : []
        })
        return { ...d, setups: collectStreamsSetups(setupIds, expandSetup) }
      } else {
        return d
      }
    }

    return { lenses, expandLens }
  }
}
