export const table = [{
        "slug": "recovib-io",
        "uuid": "40c408ca-2b2a-3e96-a123-7190c8637be6",
        "_id": "recaPhypNyZvBHTpi"
    },
    {
        "slug": "dump-recovib-io",
        "uuid": "f95e2242-6ff1-3ffc-b366-5c4f7091473d",
        "_id": "rec1Oyu37YfrDyEqK"
    },
    {
        "slug": "assets-recovib-io",
        "uuid": "58c7685d-2057-33bc-8400-db255d086cd0",
        "_id": "recE8ZKfKUt0fI925"
    },
    {
        "slug": "cetic-recovib-io",
        "uuid": "bb5e83ba-6f74-339d-99b6-01d81d749487",
        "_id": "recR8V2Iw8z4UnTnU"
    },
    {
        "slug": "mmd-recovib-io",
        "uuid": "f78cd1a6-3f66-30fa-a51b-c5ecb4816eda",
        "_id": "recYo9AkBBR2eWNWK"
    },
    {
        "slug": "modyva-recovib-io",
        "uuid": "73ed6f6e-4e88-38b5-8e93-58d00053d7ae",
        "_id": "recGeOifqVgaOHye0"
    },
    {
        "slug": "staging-recovib-io",
        "uuid": "ac2a312b-86f6-39b3-9115-8ab753d848da",
        "_id": "recjrtosco4JWL7eb"
    },
    {
        "slug": "team-recovib-io",
        "uuid": "13c746ae-bd06-36ef-8273-897f415fcf6f",
        "_id": "recetVqv6scQUibIH"
    },
    {
        "slug": "demo-recovib.io",
        "uuid": "9a2b8840-3384-50cf-ac74-f3458b4f3d72",
        "_id": "recqUeBZA11IHv2Xd"
    },
    {
        "slug": "cementys-recovib.io",
        "uuid": "1aa7e97b-7f53-508b-9601-ebe55a68b84c",
        "_id": "reccYPozDW80ON0yB"
    },
    {
        "slug": "lhoist-quarry-marchelesdames",
        "uuid": "c767e53d-a805-5a93-ba83-47dd287d83d5",
        "_id": "recvrQtdRJSE9UQSF"
    },
    {
        "slug": "bierset-ground-radar",
        "uuid": "8a6e3705-6719-5c60-a20e-1d7d2e4a10ff",
        "_id": "recQJdd5KDJ1sEYr4"
    },
    {
        "slug": "thomas-piron-mons",
        "uuid": "25fa7dfb-3a95-5a02-b9fd-f672312e092a",
        "_id": "rechGNFI7qZfPcJGo"
    },
    {
        "slug": "ec-museum",
        "uuid": "c697018e-2c96-5b37-8430-7069d8a050d0",
        "_id": "recM2R7xlZ0nPLzl6"
    },
    {
        "slug": "edith-cavel-hospital",
        "uuid": "c983ba8d-9f05-5adc-94a5-c9fc18ebe084",
        "_id": "rec5361R0BnMQnSco"
    },
    {
        "slug": "walibi-nl-roller-coaster",
        "uuid": "1971aff1-11ec-5d65-bbf4-495831d2c48b",
        "_id": "recJatjqmnwnEptQ2"
    },
    {
        "slug": "flagpole",
        "uuid": "a24a7f4e-f1ad-5553-a638-ad81e33fcb42",
        "_id": "recS7uucF1w3CB4Mz"
    },
    {
        "slug": "melb-heaven-rails",
        "uuid": "51bd407a-bf33-57ab-a890-fbc19d275a9e",
        "_id": "recAd1gYVI44k3TxX"
    },
    {
        "slug": "ny-heaven-rails",
        "uuid": "f8d7d5d5-6d12-5b30-aa7e-5c62cc900a52",
        "_id": "recAkHIe2Ds2PiAuz"
    },
    {
        "slug": "gsm-pylon-structural-integrity",
        "uuid": "7a887884-edca-528a-a22b-7c0c5e534008",
        "_id": "recp8khzLhIkeMwtL"
    },
    {
        "slug": "wind-turbine",
        "uuid": "58ea189d-4b38-5ce2-9a36-56ac58c75c26",
        "_id": "recz7IUjnrnR9WkOV"
    },
    {
        "slug": "cooling-system",
        "uuid": "1a0b21da-7702-5afb-9c65-12b53de9dc6e",
        "_id": "recv6qSfNEpJuaigQ"
    },
    {
        "slug": "mumbai-pagoda",
        "uuid": "1773ae33-3413-5bb8-b623-26bc93d921b6",
        "_id": "reci397uYj9Ig2YCF"
    },
    {
        "slug": "pommeroeul-railways",
        "uuid": "997d078e-14bd-5ff1-b796-083815212a5a",
        "_id": "recCYfLYtsEaQCOnH"
    },
    {
        "slug": "cementys-1st-account",
        "uuid": "0a6ffa99-64d4-522e-a6ce-c4e95f808547",
        "_id": "recCGXGQa4sFmV6X6"
    },
    {
        "slug": "vmv-1st-account",
        "uuid": "0a6ffa99-64d4-522e-a6ce-c4e95f808547",
        "_id": "rec3QHnbHZPCwT9LQ"
    },
    {
        "slug": "lho-1-vib",
        "uuid": "0d115ac9-1883-579a-a45a-0f3e572e7466",
        "_id": "recB4IHlVCcgwX34S"
    },
    {
        "slug": "lho-2-air",
        "uuid": "321e4f34-0d9b-5cab-9588-8f14265f6819",
        "_id": "rec50dBNaPvjsDi4s"
    },
    {
        "slug": "bie-1-vib",
        "uuid": "bb82c5cb-4601-5eb3-8204-ae4389283942",
        "_id": "recrmuDq2BKxeGmp6"
    },
    {
        "slug": "thp-1-vib",
        "uuid": "d9c31a3b-52a8-5558-ade0-3346ed261bfa",
        "_id": "recSG6bc7ZniSHiUp"
    },
    {
        "slug": "thp-2-vib",
        "uuid": "c7d4d168-2de2-5e58-8854-cbd371ae1dbe",
        "_id": "rec8k8vt0iBQEMMZl"
    },
    {
        "slug": "ecm-1-vib",
        "uuid": "e661c385-a520-5f84-97f6-8e10e63946dd",
        "_id": "recFYAYAQKkzvkK6s"
    },
    {
        "slug": "ecm-2-dst",
        "uuid": "670767a9-20a0-568a-af01-37e5eae13247",
        "_id": "recnUQEPBInNVB6Qv"
    },
    {
        "slug": "ecv-1-vib",
        "uuid": "0641f19e-3abd-51f5-9cf1-85b5fdf417f1",
        "_id": "recFRJwchDa9E5qfO"
    },
    {
        "slug": "wnl-1-tmd",
        "uuid": "f6edd9b2-68e0-5156-9012-b0e69f46c65b",
        "_id": "recvO5GlmmjPWpfEB"
    },
    {
        "slug": "wnl-2-tmd",
        "uuid": "e82acb8c-ca65-5809-892b-b4835da4486c",
        "_id": "recr4p8vGM93b30XU"
    },
    {
        "slug": "wnl-3-tmd",
        "uuid": "3ce8eece-a0e3-5a76-8bce-cc29e111b600",
        "_id": "recRNneMhcwxXqR4Q"
    },
    {
        "slug": "fgp-1-acc",
        "uuid": "4235f312-62c5-5686-8d90-bc5d5390da62",
        "_id": "recQOVRG4os57Rx2p"
    },
    {
        "slug": "fgp-2-dis",
        "uuid": "de6517ec-5ff4-5247-a9e4-88c7e6a60f65",
        "_id": "recknAUBy1b72CRcF"
    },
    {
        "slug": "fgp-1-tmd",
        "uuid": "2c5af2ae-6008-5fb3-9ff1-c1c9dd27dfaf",
        "_id": "recsjIV8mFnozdTRM"
    },
    {
        "slug": "mbh-1-pot",
        "uuid": "49a02e1d-03f7-5505-843c-05c6eb6a2117",
        "_id": "rec1qsyP9ueN1O6W3"
    },
    {
        "slug": "mbh-2-pot",
        "uuid": "992fbf74-42bc-58e7-9724-9d2236612448",
        "_id": "rec7V3hSQwrBcmbCu"
    },
    {
        "slug": "mbh-3-pot",
        "uuid": "4a005fa6-f852-55c3-9d9b-f2a0ecddf326",
        "_id": "recbogZARtstCyE5c"
    },
    {
        "slug": "mbh-12-tem",
        "uuid": "f63e27f9-0e66-5060-9c0c-eb1c801c4d76",
        "_id": "recvaRtfYwMMq6GNS"
    },
    {
        "slug": "mbh-14-tem",
        "uuid": "8303b8bc-cf90-56d2-9915-fc9bd7a623a4",
        "_id": "recIht5Iwpt3TLnoj"
    },
    {
        "slug": "mbh-15-tem",
        "uuid": "a5f8e052-3d28-58e1-90f4-37e8c89d3f1f",
        "_id": "recAPhqC4xo0m6hCZ"
    },
    {
        "slug": "mbh-21-ac",
        "uuid": "b8dab8eb-b407-516c-a879-9ed73791c0c6",
        "_id": "reco8V2J1JrFqtQAY"
    },
    {
        "slug": "mbh-22-ac",
        "uuid": "5d429902-7da3-52c9-a95f-68938edc7076",
        "_id": "recsnAf1KyoGyv1dW"
    },
    {
        "slug": "mbh-23-ac",
        "uuid": "1eda0801-4e9e-5ef5-98e9-e54da1e9b70e",
        "_id": "recYZQgYyUoSqCoP9"
    },
    {
        "slug": "mbh-24-ac",
        "uuid": "fae369d0-cda3-53b6-b9c7-b8a471d53845",
        "_id": "rec5HJrTJQo8gGBJL"
    },
    {
        "slug": "nyh-1-vib",
        "uuid": "4c25b962-8798-511c-b0f8-1e8df5de4901",
        "_id": "recNnA8glYM51Yp8n"
    },
    {
        "slug": "gsm-1-tem",
        "uuid": "7b86d5e0-affe-5d6a-bb76-2b1f00160162",
        "_id": "recRW0xHaikb8PGTg"
    },
    {
        "slug": "wnd-1-acc",
        "uuid": "85b4c55a-5dbd-5e2a-9221-3f30f77b65e0",
        "_id": "rec0hycdXABye4w7W"
    },
    {
        "slug": "coo-1-cnd",
        "uuid": "864cff68-a07e-53ba-8c49-9f410683cadc",
        "_id": "recsiOjLD9FBCJSok"
    },
    {
        "slug": "coo-2-cnd",
        "uuid": "6c074fa3-0911-5405-ab8b-b359d3789ad3",
        "_id": "recnPCyWqDNTnHmKT"
    },
    {
        "slug": "coo-3-cnd",
        "uuid": "a5bd6e31-9a9d-56c7-a613-cecee66b658a",
        "_id": "recpF3LawuQwwMklz"
    },
    {
        "slug": "coo-4-cnd",
        "uuid": "1a87f312-a74b-5d8c-97ca-e563b9edbe89",
        "_id": "recnVEdIIh6MN3yrQ"
    },
    {
        "slug": "pgd-1-acc",
        "uuid": "65c1e74c-5082-5860-abcc-bd89284c43a1",
        "_id": "recfLODzRJrWCQzAv"
    },
    {
        "slug": "pgd-2-acc",
        "uuid": "5a049adc-0f84-5c2e-9c98-41e57df9c4f6",
        "_id": "recDKKszdymVuAbx1"
    },
    {
        "slug": "pgd-3-acc",
        "uuid": "44daac3f-43a1-5007-be41-7dfb1c15a42b",
        "_id": "recHX5gaH7lvyHBv6"
    },
    {
        "slug": "pgd-4-acc",
        "uuid": "270cb93a-7b8b-5b7f-bbd3-0f62898cc2c8",
        "_id": "rectH2qBw4wS22ojc"
    },
    {
        "slug": "pgd-20-inc",
        "uuid": "36589121-b930-5879-91df-0807a8b0717c",
        "_id": "recIfyOIDZKJV4rkX"
    },
    {
        "slug": "pgd-21-inc",
        "uuid": "12d5a846-8d10-5490-9109-c3e807702146",
        "_id": "recPKpNDgUp4624Ls"
    },
    {
        "slug": "pgd-22-inc",
        "uuid": "ee434601-1336-5183-a8f2-fc9fbfa48bef",
        "_id": "recSM7VSi1XmtF9nP"
    },
    {
        "slug": "pom-1-vib",
        "uuid": "6df06890-3847-544b-906b-d2eb6f62df0d",
        "_id": "rectXOBDNqvFJRQTi"
    },
    {
        "slug": "demo_mode",
        "uuid": "demo",
        "_id": "recbtK3Q784kbnhDU"
    },
    {
        "slug": "cem-1-vib",
        "uuid": "d71107fb-523c-5a7a-8082-ba6cc37cfd16",
        "_id": "recPdq1OqqVrrSJHt"
    },
    {
        "slug": "vmv-1-vib",
        "uuid": "d71107fb-523c-5a7a-8082-ba6cc37cfd16",
        "_id": "recYEnAemxdQ53925"
    },
    {
        "slug": "tvc-staging",
        "uuid": "travelec",
        "_id": "recQMnPq3zdvaNG41"
    },
    {
        "slug": "tvc-1-vib",
        "uuid": "travelec",
        "_id": "recDSoZ5FM3xAjvDG"
    },
    {
        "slug": "tvc-2-vib",
        "uuid": "travelec",
        "_id": "recM0LJ5QCjapV2ZC"
    },
    {
        "slug": "tvc-3-vib",
        "uuid": "travelec",
        "_id": "rechdhrh1Cgv0IRlI"
    },
    {
        "slug": "tvc-4-vib",
        "uuid": "travelec",
        "_id": "recNrSXObWlbEHHDq"
    },
    {
        "slug": "travelec",
        "uuid": "travelec",
        "Field 3": "    Xopix?80\n    Wuzol%71\n    Yulum#65\n    Vobox*60\n    Yulal$14\n    Jihek$38\n    Julus&18\n    Dusax*41\n    Vovub*47\n    Ridit*57",
        "_id": "recuJwOBlQ6HOK4cN"
    },
    {
        "_id": "recIRHQIZU2PoettV"
    }
]