class Enumeration {
  constructor(obj) {
    for (const key in obj) {
      this[key] = obj[key]
    }
    return Object.freeze(this)
  }
  has = key => {
    return this.hasOwnProperty(key)
  }
}

export const enumFromArray = arr => {
  const obj = arr.reduce((acc, k) => {
    acc[k] = k
    return acc
  }, {})
  return new Enumeration(obj)
}

export default Enumeration
