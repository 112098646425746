import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { View, Inline } from '@mmd/design-layout/'
import moment from 'moment'
import DayPicker from '@mmd/block/date-picker-with-week-selection'

import Button from '@mmd/design-system/button'

const l10n = { weekType: 'isoWeek' }

const pastWeek = (end, n) => {
	const { weekType } = l10n
	return [moment(end).subtract(n, 'week').startOf(weekType), moment.min([moment(end).subtract(n, 'week').endOf(weekType), moment(end)])]
}

const formatDateRange = (timeRange) => {
	if (!timeRange) {
		return ''
	}
	return `${moment(timeRange.start).format('DD MMM YYYY')} - ${moment(timeRange.end).format('DD MMM YYYY')}`
}

const buildRanges = () => {
	const now = new Date()
	const start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
	const end = moment(start).add(1, 'days').subtract(1, 'seconds')

	const ranges = [
		{ i18n: 'Today', range: [moment(start), moment(end)] },
		{
			i18n: 'Yesterday',
			range: [moment(start).subtract(1, 'days'), moment(end).subtract(1, 'days')],
		},
		{
			i18n: 'Last 3 Days',
			range: [moment(start).subtract(3, 'days'), moment(end)],
		} /*,
    {
      i18n: 'This week',
      range: [moment(end).startOf(weekType), moment.min([moment(end).endOf(weekType), moment(end)])]
    },
    { i18n: 'Last week', range: pastWeek(end, 1) } */,
	].map(({ i18n, range }) => {
		const [start, end] = range
		return { i18n, range: [start.toISOString(), end.toISOString()] }
	})
	return { ranges, startOfToday: start, endOfToday: end }
}

export const propTypes = {}

class WeeksWithMonth extends Component {
	constructor(props) {
		super(props)
		this.bound = {
			onRangeSelected: this.onRangeSelected.bind(this),
		}
	}
	onRangeSelected(event) {
		const { range } = event.currentTarget.dataset
		this.signalChange(...range.split(','))
	}

	signalChange(startDate, endDate) {
		const { whenChange } = this.props
		if (typeof whenChange === 'function') {
			whenChange({
				start: moment(startDate).toISOString(),
				end: moment(endDate || startDate).toISOString(),
			})
		}
	}

	render() {
		const { end, firstDate } = this.props
		const { onRangeSelected } = this.bound

		const weeks = [...Array(10)]
			.map((d, i) => {
				return i + 2
			})
			.map((d) => {
				return { i18n: `Week -${d}`, range: pastWeek(end, d) }
			})
			.filter((d) => {
				const [start] = d.range
				const days = moment(start).diff(firstDate, 'days')
				return days >= -7
			})

		return (
			<View>
				{weeks.map((d) => {
					const { i18n, range } = d
					const [start] = range
					const date = moment(start).date()
					const month = moment(start).format('MMM')

					return (
						<Button key={i18n} onClick={onRangeSelected} data-range={range}>
							{date} {month}
						</Button>
					)
				})}
			</View>
		)
	}
}
WeeksWithMonth.propTypes = propTypes

class WeekRangePicker extends Component {
	constructor(props) {
		super(props)
		let { timeRange } = props
		const { ranges, startOfToday, endOfToday } = buildRanges()

		// let { firstDate} = props
		// const lastInRange = (ranges.slice(-1).pop() || {}).range[1]
		// const weeksDiff = moment(lastInRange).diff(moment(firstDate), 'weeks')

		let active = undefined

		this.state = {
			ranges,
			startOfToday,
			endOfToday,
			active,
		}

		if (!timeRange || !timeRange.start || moment(timeRange.end) < endOfToday) {
			this.state.active = 'Today'
			this.signalChange(startOfToday.toISOString(), endOfToday.toISOString())
		} else if (moment(timeRange.start) < startOfToday) {
			this.signalChange(startOfToday.toISOString(), timeRange.end)
		}

		this.bound = {
			onRangeSelected: this.onRangeSelected.bind(this),
			whenOlderAction: ({ type, payload }) => {
				const { start: startDate, end: endDate } = payload
				const { start, end } = {
					start: moment(startDate).startOf('day').toISOString(),
					end: endDate ? moment(endDate).startOf('day').toISOString() : moment(startDate).startOf('day').add(1, 'day').subtract(1, 'millisecond').toISOString(),
				}
				// console.log("setState", { start, end });
				this.setState({ active: 'NONE', timeRange: { start, end } })
				this.signalChange(...[start, end])
			},
		}
	}

	onRangeSelected(event) {
		const { range, i18n } = event.currentTarget.dataset || {}
		const [startDate, endDate] = (range || '').split(',')
		const { start, end } = {
			start: moment(startDate).toISOString(),
			end: moment(endDate).toISOString(),
		}
		this.setState({ active: i18n, timeRange: { start, end } })
		this.signalChange(...[start, end])
	}
	signalChange(startDate, endDate) {
		const { whenChange } = this.props
		if (typeof whenChange === 'function') {
			whenChange({ start: startDate, end: endDate })
		}
	}

	render() {
		const { timeRange, showWeeks } = this.props
		const { ranges, active } = this.state
		const { onRangeSelected, whenOlderAction } = this.bound

		if (!timeRange) {
			return <View>No</View>
		}
		return (
			<Fragment>
				<Inline>
					{ranges.map((d) => {
						const { i18n, range } = d
						const isActive = (active || '').toString() === i18n ? true : undefined
						return (
							<Button key={i18n} onClick={onRangeSelected} data-range={range} primary={isActive} data-i18n={i18n}>
								{i18n}
							</Button>
						)
					})}
				</Inline>
				<DayPicker whenAction={whenOlderAction} showWeeks={showWeeks} />
				<View>{formatDateRange(timeRange)}</View>
			</Fragment>
		)
	}
}
export default WeekRangePicker
