import styled from 'styled-components'

export const HBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${props => (props.v === 'bottom' ? 'flex-end' : undefined)};
  flex-wrap: ${props => (props.wrap ? 'wrap' : undefined)};
  width: 100%;

  & > * {
    width: ${props => (props.fullwidth ? '100%' : undefined)};
  }
`
export const VBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
`

export const Inline = styled.div`
  display: inline-block;
  flex: 0 0 auto;
`

export const Padding = styled.div`
  padding: ${props => props.all || 0}px;
  flex: 1;
`
