/*
const defaults = {
  title: 'Peak Vibration (Pa)',
  colors: ['#008FFB'],
  axisValue: function(value) {
    return (value || 0).toFixed(1)
  }
}
*/

export const getOptionsMain = (props) => {
  const {
    uid = '',
    title,
    colors,
    ymax = 1,
    ymin = 0,
    tooltTipRounding = 5,
    yRounding = 1,
    maxStd = 5,
    safeLevelMarker = { color: '#0071ca', dash: 3, text: `${maxStd} mm/s` }, // maxPoint >= maxStd ? { color: '#b22200', dash: 3 } : { color: '#3ca53a', dash: 0 }
  } = props

  return {
    chart: {
      id: `apexArea_${uid}`,
      toolbar: {
        autoSelected: 'zoom',
        show: true,
      },
    },
    brush: {
      enabled: false,
    },

    title: {
      text: title,
      align: 'middle',
      offsetX: 0,
    },
    animations: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    colors,
    stroke: {
      width: 1,
      lineCap: 'round',
      // curve: 'stepline'
    },
    tooltip: {
      enabled: true,
      interesect: false,
      fillSeriesColor: false,
      style: { fontSize: '0.6rem' },
      x: {
        show: true,
        format: 'dd MMM HH:mm:ss',
        formatter: undefined,
      },
      y: {
        formatter: function (value) {
          return (value || 0).toFixed(tooltTipRounding)
        },
        title: {
          formatter: (d) => d,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    xaxis: {
      type: 'datetime',
    },

    yaxis: {
      min: ymin,
      max: ymax,
      labels: {
        show: true,
        formatter: function (value) {
          return (value || 0).toFixed(yRounding)
        },
      },
    },

    annotations: {
      yaxis: [
        {
          y: maxStd,
          strokeDashArray: safeLevelMarker.dash,
          borderColor: safeLevelMarker.color,
          label: {
            borderColor: safeLevelMarker.color,
            style: {
              color: '#fff',
              background: safeLevelMarker.color,
            },
            text: safeLevelMarker.text,
          },
        },
      ],
    },
  }
}

export const getOptionsBrush = (props) => {
  const { uid = '', ymax = 1, ymin = 0, yBrushTicks = 2, yRounding = 1 } = props

  return {
    chart: {
      id: `apexBrush_${uid}`,
      brush: {
        target: `apexArea_${uid}`,
        enabled: true,
      },
      animations: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      selection: {
        enabled: true,
      },
    },
    colors: ['#008FFB'],
    stroke: {
      width: 1,
    },
    fill: {
      opacity: 0,
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: yBrushTicks,
      min: ymin,
      max: ymax,
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(yRounding)
        },
      },
    },
  }
}
