export const filterEventRows = (rows, ymds) => {
  let selected
  if (ymds === undefined) {
    selected = rows
  } else {
    selected = rows.filter((d) => {
      const ymd = (d.ymd.match(/(\d{4}-\d{2}-\d{2})/) || [])[1]
      return ymds.includes(ymd)
    })
  }
  return selected
}

export const filterBurstRows = (rows, ymds) => {
  let selected
  if (ymds === undefined) {
    selected = rows
  } else {
    selected = rows.filter((d) => {
      const m = d.dt.match(/^(\d{4}-\d{2}-\d{2})/)
      const [ymd] = (m || []).slice(1)
      return ymds.includes(ymd)
    })
  }
  return selected
}
