export const getStreamComponent = (streamId) => {
  let Comp

  if (['lho-2-air', 'demo-2-air'].includes(streamId)) {
    Comp = require('@mmd/block-recovib/timeseries-air').default
  } else if (['oft-1-tmp'].includes(streamId)) {
    Comp = require('@mmd/block-recovib/timeseries-tmp').default
  } else if (['lho-3-dst', 'demo-3-dst'].includes(streamId)) {
    Comp = require('@mmd/block-recovib/timeseries-dst').default
  } else if (['lho-1-vib', 'demo-1-vib', 'pom-1-vib', 'cem-1-vib', 'vmv-1-vib', 'tvc-1-vib', 'tvc-2-vib', 'tvc-3-vib', 'tvc-4-vib'].includes(streamId)) {
    Comp = require('@mmd/block-recovib/timeseries-vib').default
  } else if (['nyh-1-acc', 'nyh-1-acc-stag'].includes(streamId)) {
    Comp = require('@mmd/block-recovib/timeseries-vib-apps').default
  } else if (['nyh-1-bst', 'nyh-1-bst-stag'].includes(streamId)) {
    Comp = require('@mmd/block-recovib/timeseries-vib').default
  }
  return Comp
}
