export const table = [{
        "i18n": "Building vibration - 81 rue Emile Vandevelde - Namêche",
        "data_streams": [
            "rec3qPv4HciTjEOHr"
        ],
        "phase": "production",
        "lens_uid": "1-blasting-vib",
        "account": [
            "recU4d0NsafVSRELQ"
        ],
        "resources": [
            "recc7q8op4DXkkfXH",
            "recbe4d3xoh57l2Do",
            "reckHKN3nluChVqSX",
            "rec4daxpMZhTMXGOb"
        ],
        "lens_connectors": [
            "rec20DiBkrRT2fVWc",
            "recFh592hkReNJyT1",
            "recPTfw2A21x9qOEa",
            "recydTKen4q0KJQ1y",
            "recgk2bXYBVPpfpsm",
            "recPUseYNPQL9BTl5",
            "recfOZFjKdACFqWY6",
            "reczOW6qLNtcX50ck",
            "recTxunbSD9INcIfd"
        ],
        "slug": "lhoist-quarry-marchelesdames__1-blasting-vib",
        "status": "active",
        "_id": "recChHriPInnrgGfd"
    },
    {
        "i18n": "Air pressure - Chateau de Melroy - Vezin",
        "data_streams": [
            "recERx3ECNc8ToSKm"
        ],
        "phase": "production",
        "lens_uid": "2-blasting-air",
        "account": [
            "recU4d0NsafVSRELQ"
        ],
        "resources": [
            "rec26MZ5UzInijSzt",
            "recbe4d3xoh57l2Do",
            "reckHKN3nluChVqSX",
            "recQenoSAqBI5E4Y4"
        ],
        "lens_connectors": [
            "recQZZkN3ygOB5yf4",
            "recN1A94MeNEYUveo"
        ],
        "slug": "lhoist-quarry-marchelesdames__2-blasting-air",
        "status": "active",
        "_id": "rec4B9zT5dueMZZBs"
    },
    {
        "i18n": "Dust - Marche-les-Dames",
        "data_streams": [
            "recDd8fgyT8423xbq"
        ],
        "phase": "production",
        "lens_uid": "3-blasting-dust",
        "account": [
            "recU4d0NsafVSRELQ"
        ],
        "resources": [
            "rec26MZ5UzInijSzt",
            "recbe4d3xoh57l2Do",
            "reckHKN3nluChVqSX",
            "recQenoSAqBI5E4Y4"
        ],
        "lens_connectors": [
            "recQZZkN3ygOB5yf4",
            "recN1A94MeNEYUveo"
        ],
        "slug": "lhoist-quarry-marchelesdames__3-blasting-dust",
        "status": "active",
        "_id": "recKT3Z6cOztKKQet"
    },
    {
        "i18n": "Air pressure - Demo",
        "data_streams": [
            "rec9AgBsXNRy5DdtD"
        ],
        "phase": "production",
        "lens_uid": "2-demo-air",
        "account": [
            "rec9I1aHgfQFuhC3k"
        ],
        "resources": [
            "rec26MZ5UzInijSzt",
            "recVzxr7SjJnA5Jv1",
            "recQenoSAqBI5E4Y4"
        ],
        "lens_connectors": [
            "recVduxiQMdsMZahz",
            "rec2xxeJS5sYLN4Gm"
        ],
        "slug": "demo_mode__2-demo-air",
        "status": "active",
        "_id": "recY9ILAwR4dApQDH"
    },
    {
        "i18n": "Building vibration - Demo",
        "data_streams": [
            "recFJoSnlbArdFIVp"
        ],
        "phase": "production",
        "lens_uid": "1-demo-vib",
        "account": [
            "rec9I1aHgfQFuhC3k"
        ],
        "resources": [
            "rec26MZ5UzInijSzt",
            "recVzxr7SjJnA5Jv1",
            "rec4daxpMZhTMXGOb"
        ],
        "lens_connectors": [
            "recZmAygCMHpRw9sr",
            "recId1qBgt1RgUuIm",
            "recKgJ7RTcFBOschy",
            "recOllgucunDvvBVK",
            "recnBqJ7zYcLg3tiR",
            "recdPSRxWfQR1mlJW",
            "rec2qh8NpqEEAgljQ"
        ],
        "slug": "demo_mode__1-demo-vib",
        "status": "active",
        "_id": "recB8ZKzjdZr3V4wr"
    },
    {
        "i18n": "Sensitive Equipment - Vibration Monitoring",
        "data_streams": [
            "reco3oDXuWSWU6qPO"
        ],
        "phase": "disconnected",
        "lens_uid": "1-vib",
        "account": [
            "rec9uiUAqY0iLEp6b"
        ],
        "resources": [
            "reckHKN3nluChVqSX"
        ],
        "lens_connectors": [
            "recJ9FL90xJP1pT3F",
            "recKvyc2A5Ac73R9V"
        ],
        "slug": "bierset-ground-radar__1-vib",
        "status": "closed",
        "_id": "recj1etG31fBFMKWN"
    },
    {
        "i18n": "Building vibration ",
        "data_streams": [
            "recLV3zmV5oGx9ZYT",
            "rec19pIldKM58hQlD"
        ],
        "phase": "disconnected",
        "lens_uid": "1-vib-resident",
        "account": [
            "recdu0X9monxBgioQ"
        ],
        "resources": [
            "recc7q8op4DXkkfXH",
            "reckHKN3nluChVqSX"
        ],
        "lens_connectors": [
            "rech6Il1KBTEejipC",
            "recj5VfS4l4vbVblv"
        ],
        "slug": "thomas-piron-mons__1-vib-resident",
        "status": "closed",
        "_id": "recn1WwfZrCQvoDes"
    },
    {
        "i18n": "Building vibration & dust monitoring",
        "data_streams": [
            "rech9XerLoitdmkRP",
            "recOLVd6kEzXhNhBu"
        ],
        "phase": "disconnected",
        "lens_uid": "1-dust+vib",
        "account": [
            "recbSgLoxGp1D6wl6"
        ],
        "resources": [
            "reckHKN3nluChVqSX"
        ],
        "lens_connectors": [
            "recvZFHksIcEKKGw1",
            "recV64yB1eElugjzd"
        ],
        "slug": "ec-museum__1-dust+vib",
        "status": "closed",
        "_id": "reclpckuaJEkxeRbI"
    },
    {
        "i18n": "Building vibration",
        "data_streams": [
            "rec2l1ben6EFobl73"
        ],
        "phase": "disconnected",
        "lens_uid": "1-vib",
        "account": [
            "recM8HnNzCfeWJYey"
        ],
        "resources": [
            "reckHKN3nluChVqSX"
        ],
        "lens_connectors": [
            "recNAvIkXMSte43Eq",
            "recoqAl3L6mCAbjpj"
        ],
        "slug": "edith-cavel-hospital__1-vib",
        "status": "closed",
        "_id": "recWFDWTcFuxQRj4a"
    },
    {
        "i18n": "Tuned mass damper efficiency",
        "data_streams": [
            "recq9A4n1RMMEMpyk",
            "recPi21QB1DbdAsu5",
            "recvl7jdalep3Cw7u"
        ],
        "phase": "proposed",
        "lens_uid": "1-tmd",
        "account": [
            "receRH648GeIdtaZG"
        ],
        "slug": "walibi-nl-roller-coaster__1-tmd",
        "status": "closed",
        "_id": "recooDFaLJt17BvPi"
    },
    {
        "i18n": "Tuned mass damper efficiency",
        "data_streams": [
            "recPKoVhZH4cGyWLX",
            "reclvgpFqScI6A4xk",
            "rec6YkqWpXc1hdiWv"
        ],
        "phase": "proposed",
        "lens_uid": "1-tmd",
        "account": [
            "recpHSce5SHT2bE5Z"
        ],
        "slug": "flagpole__1-tmd",
        "status": "closed",
        "_id": "reczeOLkIVWcWjZVB"
    },
    {
        "i18n": "Rails conditions",
        "data_streams": [
            "recsaH6D2ocUSwvQI",
            "recbSjhmrGHnwgfpU",
            "recXuyEw5OlDdazIZ",
            "recFUeInK46msgfEe",
            "recoTsCfw74ji6BRg",
            "reczAT4uvhwhqiQKc"
        ],
        "phase": "proposed",
        "lens_uid": "1-mon",
        "account": [
            "recESV0WYKfUUesjk"
        ],
        "slug": "melb-heaven-rails__1-mon",
        "status": "closed",
        "_id": "recA078N3JVaEOUN6"
    },
    {
        "i18n": "[RMS] Crane - Maher Terminals/New York/Rails vibrations",
        "data_streams": [
            "recupOFUOqXIO08o2"
        ],
        "phase": "production",
        "lens_uid": "1-acc",
        "account": [
            "recv4Gzbi9mK9iW3L"
        ],
        "resources": [
            "recEndIK0boDUdyvs",
            "recVzxr7SjJnA5Jv1",
            "rec4daxpMZhTMXGOb"
        ],
        "lens_connectors": [
            "recB62L0hhkNNJeY3",
            "recMsdFdJVqp6RJ7N",
            "rec2S9vOjJwGKPn0l"
        ],
        "slug": "ny-heaven-rails__1-acc",
        "status": "active",
        "_id": "rec3OHQXn3S4zP1Gl"
    },
    {
        "i18n": "[Bursts] Crane - Maher Terminals/New York/Rails vibrations",
        "data_streams": [
            "recQEp2a9n7EWbtKK"
        ],
        "phase": "production",
        "lens_uid": "1-burst",
        "account": [
            "recv4Gzbi9mK9iW3L"
        ],
        "resources": [
            "recEndIK0boDUdyvs",
            "recVzxr7SjJnA5Jv1",
            "rec4daxpMZhTMXGOb"
        ],
        "lens_connectors": [
            "recxGuuwBMW50x9Fu",
            "recP0EJNYKSRMx8xu",
            "recOsnkUnItkBLvny"
        ],
        "slug": "ny-heaven-rails__1-burst",
        "status": "active",
        "_id": "recQQT5jcniotm2SS"
    },
    {
        "i18n": "[RMS-Staging] Crane - Maher Terminals/New York/Rails vibrations",
        "data_streams": [
            "recDoeSx8kCpHl9sP"
        ],
        "phase": "staging",
        "lens_uid": "1-acc-stag",
        "account": [
            "recm0sNGrHnExoJg6"
        ],
        "resources": [
            "recEndIK0boDUdyvs",
            "recVzxr7SjJnA5Jv1",
            "rec4daxpMZhTMXGOb"
        ],
        "lens_connectors": [
            "recxGuuwBMW50x9Fu",
            "recP0EJNYKSRMx8xu",
            "recOsnkUnItkBLvny"
        ],
        "slug": "ny-heaven-rails-staging__1-acc-stag",
        "status": "active",
        "_id": "recwPuRtpi8YGNbTj"
    },
    {
        "i18n": "[Bursts-Staging] Crane - Maher Terminals/New York/Rails vibrations",
        "data_streams": [
            "recmlRi4kNqzG0FJa"
        ],
        "phase": "staging",
        "lens_uid": "1-burst-stag",
        "account": [
            "recm0sNGrHnExoJg6"
        ],
        "resources": [
            "recEndIK0boDUdyvs",
            "recVzxr7SjJnA5Jv1",
            "rec4daxpMZhTMXGOb"
        ],
        "lens_connectors": [
            "recxGuuwBMW50x9Fu",
            "recP0EJNYKSRMx8xu",
            "recOsnkUnItkBLvny"
        ],
        "slug": "ny-heaven-rails-staging__1-burst-stag",
        "status": "active",
        "_id": "rec23cPcuWjARCyG3"
    },
    {
        "i18n": "Crane - Le Havre",
        "phase": "proposed",
        "lens_uid": "1-mon",
        "account": [
            "recVVhEuxlccS3erz"
        ],
        "slug": "hv-heaven-rails__1-mon",
        "status": "closed",
        "_id": "recoIsLgNYcassMNx"
    },
    {
        "i18n": "Pylons structural monitoring",
        "data_streams": [
            "recHL98cCay1CEJmC"
        ],
        "phase": "proposed",
        "lens_uid": "1-str",
        "account": [
            "recpAhjZQFZOYATqM"
        ],
        "slug": "gsm-pylon-structural-integrity__1-str",
        "status": "closed",
        "_id": "recz7dS5tIe7SIego"
    },
    {
        "i18n": "Wind turbines integrity monitoring",
        "data_streams": [
            "recxQ5BdtE6bYjO2N"
        ],
        "phase": "proposed",
        "lens_uid": "1-str",
        "account": [
            "recCIrv3sC97StyD5"
        ],
        "slug": "wind-turbine__1-str",
        "status": "closed",
        "_id": "recMfn495FoqMBTtH"
    },
    {
        "i18n": "Cooling system condition monitoring",
        "data_streams": [
            "recr6Y8Yp7AfZWnYr",
            "recM3LQrkdidarFXy",
            "recuCoGADd1kv17cP",
            "recI9Xja68ji6VCzZ"
        ],
        "phase": "proposed",
        "lens_uid": "1-mon",
        "account": [
            "recmC6Myr7pAwgeRf"
        ],
        "slug": "cooling-system__1-mon",
        "status": "closed",
        "_id": "recw92lE4aETqozHR"
    },
    {
        "i18n": "Structural Monitoring",
        "data_streams": [
            "recOAO9p85XFURlyl",
            "rec5cd7To0UidPqow",
            "reccMHc7B8ZvdQ73q",
            "recdYRXMH0yeZoC8B",
            "recCkmJaC9zbJpGPf",
            "recsOpZQu5BOOQ7tv",
            "reca07kdlw6aXQ6Gv"
        ],
        "phase": "proposed",
        "lens_uid": "1-str",
        "account": [
            "recfWZlD20h0Oa87b"
        ],
        "slug": "mumbai-pagoda__1-str",
        "status": "closed",
        "_id": "recptVUJF3wjIitXN"
    },
    {
        "i18n": "Building Vibration",
        "data_streams": [
            "recvu5jV57eospwDA"
        ],
        "phase": "production",
        "lens_uid": "1-vib",
        "account": [
            "recYRQLa8OahugnWk"
        ],
        "resources": [
            "rec26MZ5UzInijSzt",
            "recEndIK0boDUdyvs",
            "recVzxr7SjJnA5Jv1",
            "rec4daxpMZhTMXGOb"
        ],
        "lens_connectors": [
            "recXhXQORrO6GFzzp",
            "rec8LVEO6Oc2ONnEg",
            "recvZBNJUDtnDcFbe",
            "reci9oayDp5k4yfyw",
            "rec4XAMHeuA3zSy9b",
            "recxAxbPqHTfxVG3d",
            "rece809uqKvd4Z5Qp",
            "recn7pkfesyi29X7s"
        ],
        "slug": "pommeroeul-railways__1-vib",
        "status": "active",
        "_id": "rec8oMkgLRpAooIMW"
    },
    {
        "i18n": "Pipe Vibration",
        "data_streams": [
            "reccR5SJ0fwvkpWS1"
        ],
        "phase": "production",
        "lens_uid": "1-vib",
        "account": [
            "recXY1CQroQC0NgUU"
        ],
        "resources": [
            "rec26MZ5UzInijSzt",
            "rec4daxpMZhTMXGOb"
        ],
        "lens_connectors": [
            "recwBnULAcIsye8ov",
            "rec18gxqVrDyPhyO4",
            "recir80lq8pKo1rl5"
        ],
        "slug": "cementys-1st-account__1-vib",
        "status": "active",
        "_id": "recNwLhQe8at4lT0Y"
    },
    {
        "i18n": "Pipe Vibration",
        "data_streams": [
            "recWlZLp0RGsQigc5"
        ],
        "phase": "production",
        "lens_uid": "1-vib",
        "account": [
            "rec6FChHb2nNdRZTV"
        ],
        "lens_connectors": [
            "rec6syxQh89N7JDr8",
            "recySjWIfGalVmmqp",
            "recjGgxDvtG3krOtd"
        ],
        "slug": "vmv-1st-account__1-vib",
        "status": "active",
        "_id": "rec7zofqzJOnuGOZl"
    },
    {
        "i18n": "Sensitive Equipment - Vibration Monitoring",
        "phase": "disconnected",
        "lens_uid": "1-vib",
        "account": [
            "recIRyhLwGVtUr1Ml"
        ],
        "slug": "vmv-closed-account__1-vib",
        "status": "closed",
        "_id": "recB9f45w5wxK9GAF"
    },
    {
        "i18n": "Source 1",
        "data_streams": [
            "recbYb2tktySgph7B",
            "recQa7NRAUqc15lLU",
            "recSbjrQnKCaBmSvv",
            "rec5I1b6y0lTOJz77"
        ],
        "phase": "staging",
        "lens_uid": "1-vib",
        "account": [
            "recH2V21O1HvxIqhm"
        ],
        "lens_connectors": [
            "recbIU6feKHkGCVGt",
            "recwHnLeyBfsR7MZk",
            "recGMp6hxmBOopwQn",
            "recEyXZpKWR6z2LNG"
        ],
        "slug": "travelec__1-vib",
        "status": "active",
        "_id": "recKJxhecJd0Mzc82"
    }
]