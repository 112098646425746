export const toTsv = (rows, fields, sep = '\t') => {
  const separate = (d) => {
    return Array.isArray(d) ? d.join(sep) : d
  }
  if (Array.isArray(rows)) {
    return `${separate(fields)}\n${rows.map(separate).join('\n')}`
  } else {
    console.log('[toTsv] rows: ', rows)
  }
}

export const fromTsv = (tsv, sep = '\t') => {
  return tsv
    .replace(/^[\s\n]*/, '')
    .split('\n')
    .map((d) => {
      return d.split(sep)
    })
}

export const fromTsvAsObj = (tsv, sep = '\t') => {
  const [fields, ...rows] = tsv
    .replace(/^[\s\n]*/, '')
    .split('\n')
    .map((d) => {
      return d.split(sep)
    })
  return { fields, rows }
}
