import { unpack } from '@mmd/fns/base64/unpack'
import { tlmTime, tlmPeriod } from '@mmd/fns/tlm/tlm'
import { periodic, isoTime } from '@mmd/fns/datetime-iso/isoDateString'
import { apexSeries } from '@mmd/fns/tlm/apex-series'
import { roundDec } from '@mmd/fns/math-round'

export const timeStamped = (timestamp, period = 30000) => {
  const addPeriods = periodic(timestamp, period)
  return (v, i) => {
    return { v, time: addPeriods(i), timestamp, i }
  }
}

export const timeStampedWeekly = (timestamp) => {
  const time = isoTime
  return (v, i) => ({ v, time, timestamp, i })
}

export const weekly = (docs, asyncReturn) => {
  const data = docs.reduce((acc, d, i) => {
    const stamped = timeStampedWeekly(tlmTime(d))
    if (d.peak) {
      acc.push(stamped(d.peak, i))
    }
    return acc
  }, [])

  asyncReturn({ data, docs })
}

const channelConcatenator = (getPeakChannels, stamped = timeStamped) => {
  return (docs) => {
    return (docs || []).reduce((acc, d, i) => {
      const timestamp = tlmTime(d)
      const period = tlmPeriod(d)
      const asData = timeStamped(timestamp, period * 1000)
      getPeakChannels(d).forEach((x, i) => {
        const dataPoints = x.map(asData)
        acc[i] = acc[i] ? acc[i].concat(dataPoints) : dataPoints
      })
      return acc
    }, [])
  }
}

export const singleChannel = (docs, asyncReturn) => {
  // console.log('[singleChannel]', docs)
  const getPeakChannels = (d) => {
    return [(d.data || d.peak_data).split('|')]
  }
  const [data] = channelConcatenator(getPeakChannels)(docs)

  let maxPoint = 0
  const datapoints = (data || []).map((d) => {
    const { v, time } = d
    const f = parseFloat(v)
    if (maxPoint < f) {
      maxPoint = f
    }
    return [time, f]
  })
  /*
    .sort((a, b) => {
      // console.log(new Date(b[0]).getTime(), new Date(a[0]).getTime())
      return new Date(a[0]).getTime() - new Date(b[0]).getTime()
    })
    */

  asyncReturn({ data: { maxPoint, datapoints }, docs })
}

export const multiApp = (docs, asyncReturn) => {
  const series = apexSeries(docs)
  asyncReturn({ data: { series }, docs })
}

export const burstTable = (docs, asyncReturn) => {
  const series = apexSeries(docs, true)
  asyncReturn({ data: { series }, docs })
}

export const multiChannel_DEPRECATED = (docs, asyncReturn) => {
  // console.log('[multiChannel_DEPRECATED]', docs)
  const getPeakChannels = (d) => {
    const dataPoints = d.peak_data.split('|')
    const n = parseInt(d.n_stats, 10)
    const channels = []
    while (dataPoints.length) {
      channels.push(dataPoints.splice(0, n))
    }
    return channels
  }
  const series = channelConcatenator(getPeakChannels)(docs)
  asyncReturn({ data: { series }, docs })
}

export const vibRetro = (docs, asyncReturn) => {
  console.log('--vibRetro--')
  const stamped = (d) => timeStamped(d.channels[0])
  const getPeakChannels = (d) => (d.channels || d.device.channels).map((d) => Array.from(unpack(d.peak_data))) // :INFO: dump requires a different path
  const channels = channelConcatenator(getPeakChannels, stamped)(docs)
  const [cx, cy, cz] = channels
  const periodic = (timestamp, period) => {
    const addPeriods = periodic(timestamp, period)
    return (i) => {
      return addPeriods(i)
    }
  }

  const asData = (d) => {
    const { v, time /*, timestamp*/, i } = d
    // console.log(d)
    // const t = periodic(timestamp,30000)(i)
    // console.log(t)
    const stamper = periodic(time, 30000)
    const t = new Date(stamper(i).replace(/\.\d+Z$/, '.000Z')).valueOf()
    // console.log(t)
    return [t, roundDec(v, 5)]
  }
  const series = channels[0]
    .reduce(
      (acc, d, i) => {
        acc[0].data.push(asData(cx[i]))
        acc[1].data.push(asData(cy[i]))
        acc[2].data.push(asData(cz[i]))
        return acc
      },
      [
        { app: '10A16', lbl: 'X', name: 'X', data: [], uid: '10A16_X', idx: 0, unit: 'mm/s', n: 10, pms: 30000 },
        { app: '10A16', lbl: 'Y', name: 'Y', data: [], uid: '10A16_Y', idx: 1, unit: 'mm/s', n: 10, pms: 30000 },
        { app: '10A16', lbl: 'Z', name: 'Z', data: [], uid: '10A16_Z', idx: 2, unit: 'mm/s', n: 10, pms: 30000 },
      ]
    )
    .map((d) => {
      d.data = d.data.sort((a, b) => {
        // console.log(new Date(b[0]).getTime(), new Date(a[0]).getTime())
        return a[0] - b[0]
      })
      return d
    })
  asyncReturn({ data: { series }, docs })
}

export const coldpath = (docs, asyncReturn) => {
  const data = [] /*docs.reduce((acc, d, i) => {
    const period = parseInt(d.p_stats, 10)
    const timestamp = d.acq_dt

    const asData = (v, i) => {
      const time = moment(timestamp)
        .subtract(period * i, 'seconds')
        .toISOString()
      return { v, time, timestamp, i }
    }

    acc = acc.concat(d.peak_data.split('|').map(asData))
    return acc
  }, []) */

  asyncReturn({ data, docs })
}
