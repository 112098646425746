import styled from 'styled-components'

export const Card = styled.div`
	font-family: 'Calibri', sans-serif;
	position: relative;
	padding: 16px;
	padding-top: 16px;
	box-sizing: border-box;
	box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);
	/*  border: 1px solid #eaeaea; */
	font-size: 12pt;
	line-height: 1.2;
	width: 100%;
	height: 100%;
	background-color: white;
`

export const Head = styled.div`
	display: block;
	min-height: 72px;
`
export const Title = styled.div`
	font-family: 'GothamBold', sans-serif;
	display: block;
	font-size: 1.2em;
	margin-bottom: 4px;
	line-height: 0.95;
`

export const LensTitle = styled.div`
	font-family: 'Calibri', sans-serif;

	display: block;
	line-height: 1;
	font-size: 1.1em;
	font-weight: 500;
`

export const City = styled.div`
	font-family: 'Calibri', sans-serif;
	display: block;
	font-size: 0.9em;
	color: #4d6a79;
	margin-bottom: 4px;
	font-weight: 500;
`

export const AccountStatus = styled.div`
font-family: 'GothamBold', sans-serif;
display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${(props) => {
		const clrs = { active: 'green', closed: 'gray', canceled: 'red' }
		return clrs[props.status] || 'gray'
	}};;
  color: white
  padding: 4px 8px 2px 8px;
  font-size: 0.5em;
`

export const HBox = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	& > * {
	}
`
export const ProfileImage = styled.img`
	display: block;
	max-height: 128px;
	margin-bottom: 16px;
`
export const List = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
`

export const Item = styled.div`
	box-sizing: border-box;
	width: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	margin-bottom: 4px;
	margin-top: 8px;
`
