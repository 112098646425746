export const table = [{
        "slug": "micromega",
        "i18n": "Micromega",
        "roles": [
            "recXRlHaY9YzIQxRB"
        ],
        "accounts": [
            "recpAhjZQFZOYATqM",
            "recCIrv3sC97StyD5",
            "recmC6Myr7pAwgeRf",
            "recfWZlD20h0Oa87b",
            "recYRQLa8OahugnWk",
            "rec9I1aHgfQFuhC3k",
            "recH2V21O1HvxIqhm",
            "recAJrQvc1lVBC2bG"
        ],
        "_id": "recQd20E4mOk2ZLfj"
    },
    {
        "slug": "modyva",
        "i18n": "Modyva",
        "roles": [
            "recPBUdz726z0L5up"
        ],
        "accounts": [
            "recU4d0NsafVSRELQ",
            "rec9uiUAqY0iLEp6b",
            "recdu0X9monxBgioQ",
            "recbSgLoxGp1D6wl6",
            "recM8HnNzCfeWJYey"
        ],
        "_id": "recogUTMzKIM1jpsZ"
    },
    {
        "slug": "lhoist",
        "i18n": "Lhoist",
        "roles": [
            "rec1qsN0Xi917oT74",
            "recoo5j79K6YnGNAu",
            "recQr4jMHjWenG7Un",
            "recWarXxEtjfVOOid"
        ],
        "_id": "recIr4ScgUGTHkn9T"
    },
    {
        "slug": "galere",
        "i18n": "Galere",
        "roles": [
            "recMOLapjHAuuuj8E"
        ],
        "_id": "rec4V3mUhhEAluXDF"
    },
    {
        "slug": "thomas-piron",
        "i18n": "Thomas Piron",
        "roles": [
            "rec4et38oeHtetAqm"
        ],
        "_id": "recFJWeYJ3NYVzrKf"
    },
    {
        "slug": "euro-com-museum",
        "i18n": "European Commission Museum",
        "roles": [
            "recWTzWWRfh7PiMLn"
        ],
        "_id": "rec1uPMZbQ1CcmTGs"
    },
    {
        "slug": "edith-cavell-hospital",
        "i18n": "Edit Cavell Hospital",
        "roles": [
            "recka6aGkwEtr88Ef"
        ],
        "_id": "recog9FABvr6h923z"
    },
    {
        "slug": "walibi-holland",
        "i18n": "Wallib Holland",
        "roles": [
            "recH8ClrAsmxlTNAT"
        ],
        "_id": "rechm9puuk9KRRaLF"
    },
    {
        "slug": "flow-engineering",
        "i18n": "Flow Engineering",
        "roles": [
            "recthVkzxRKuhuURV"
        ],
        "accounts": [
            "receRH648GeIdtaZG",
            "recpHSce5SHT2bE5Z"
        ],
        "_id": "rec02ACNtFPx25pOe"
    },
    {
        "slug": "flagpole",
        "i18n": "Flagpole",
        "roles": [
            "recn1lpeIPWj6Wmtz"
        ],
        "_id": "recpdOccwAFkEtuzl"
    },
    {
        "slug": "gantrex",
        "i18n": "Gantrex",
        "roles": [
            "recY9m1v4Y63e8S2b",
            "recSlH4gqMwUVJtWw"
        ],
        "accounts": [
            "recESV0WYKfUUesjk",
            "recv4Gzbi9mK9iW3L",
            "recVVhEuxlccS3erz",
            "recm0sNGrHnExoJg6"
        ],
        "_id": "recfQBlyumJnLtmpZ"
    },
    {
        "slug": "melbourne-heaven",
        "i18n": "Melbourne Heaven",
        "roles": [
            "reccruEnh4NPkTY4y"
        ],
        "_id": "recwpcSsxFvMc61hv"
    },
    {
        "slug": "new-york-heaven",
        "i18n": "New York Heaven",
        "accounts": [
            "recv4Gzbi9mK9iW3L",
            "recm0sNGrHnExoJg6"
        ],
        "_id": "recYXHnE55SLjZcTg"
    },
    {
        "slug": "pommeroeul",
        "i18n": "Pommeroeul",
        "roles": [
            "rectzg796uRxA5cwe"
        ],
        "accounts": [
            "recYRQLa8OahugnWk"
        ],
        "_id": "recHlQ49LSyTN9FIB"
    },
    {
        "slug": "demo",
        "i18n": "Demo",
        "roles": [
            "recSdWBzCf0jP2Jft"
        ],
        "accounts": [
            "rec9I1aHgfQFuhC3k",
            "recAJrQvc1lVBC2bG"
        ],
        "_id": "recy8eOftihYfh4ik"
    },
    {
        "slug": "cementys",
        "i18n": "Cementys",
        "roles": [
            "rec8Mi25ePSpIMCLH"
        ],
        "accounts": [
            "recXY1CQroQC0NgUU"
        ],
        "_id": "recm4Kig9gxBeKcVx"
    },
    {
        "slug": "vmvtech",
        "i18n": "VMVTech corporation",
        "roles": [
            "recGxH6MSIpXrcFEQ"
        ],
        "accounts": [
            "rec6FChHb2nNdRZTV",
            "recIRyhLwGVtUr1Ml"
        ],
        "_id": "recDKrMIhw8TSACQl"
    }
]