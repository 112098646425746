import { addStats, getFrequencyDomain, maxAbs, firstHalfWithEnergyAdjustment } from './utils'
import { hannWindowing } from '@mmd/fns/signal-windowing'

export const symmetricAroundIndex = (arr, index) => {
  const tillEnd = Math.min(index, arr.length - index - 1)
  // throw error if window length is <=1
  if (tillEnd <= 1) throw 'unable to compute half window length'
  // else continue to compute the point position
  // create a subarray from window
  return arr.slice(index - tillEnd, index + tillEnd)
}

export const hannWindowingSymmetric = (xs) => {
  const { index: indexPPV } = maxAbs(xs)
  // :REVIEW: different preprocessing
  const symm = symmetricAroundIndex(xs, indexPPV)
  return hannWindowing(symm)
}

export const fftFrequencyAndMagnitude = (xs, samplingFrequency = 1000, fftwindow_type = 'none') => {
  const { windowed, fft, adjusted, freqAtIndex } = getFrequencyDomain(xs, {
    applyWindow: hannWindowingSymmetric,
    adjustEnergy: firstHalfWithEnergyAdjustment,
    samplingFrequency,
  })
  const { freqMag, withStats } = addStats({ windowed, fft, adjusted, freqAtIndex })
  return { freqMag, windowed, fft, adjusted, withStats }
}
