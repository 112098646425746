import Airtable from "airtable";

export const base = Object.freeze({
  USER_EDITS_BASE: "appK7ek9FGj1IL9ms",
  TEST_ENV_BASE: "appYfk3QLtKCWgqc9"
});

export const table = Object.freeze({
  CRUD_TEST: "CrudTest"
});

const mmdApiKey = process.env.AIRTABLE_API_KEY;

export const getTable = (name, base) => {
  if (name === undefined) {
    console.info(
      `[INFO] No table 'name' specified. Using 'CrudTest' as default`
    );
    name = table.CRUD_TEST;
  }
  if (base === undefined) {
    base = getBase();
  }
  return base(name);
};

export const getBase = (config = {}) => {
  let { apiKey, baseId } = config;
  if (apiKey === undefined) {
    apiKey = mmdApiKey;
  }
  if (baseId === undefined) {
    console.info(
      `[INFO] No 'baseId' specified. Using 'TEST_ENV_BASE' as default`
    );
    baseId = base.TEST_ENV_BASE;
  }

  const airBase = new Airtable({ apiKey }).base(baseId);
  return airBase;
};
