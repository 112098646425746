import * as logger from '@mmd/io/logger'
import * as action from './ActionType'
import { asActionType } from '@mmd/flow/action-payload'
import { dispatchBackToAccounts } from './lens'
// globals
import Cache from '@mmd/fns/cache'
import { getBackoffice } from '../globals'
// io
import { fetchFormattedData } from '@mmd/io/az-cosmos/fetch-cosmos'
import { fetchBurstListing, fetchEventListing } from '@mmd/io/dh-tables/fetchTables'
// domain-recovib
import { expandMonitorLens } from '@mmd/domain-recovib/lens'
import { getStreamConfig } from '@mmd/domain-recovib/stream/configs'
import { getItemFromSlugGetter } from '@mmd/domain-recovib/backoffice'
import { getStreamUrl } from '@mmd/domain-recovib/stream'
import { getBurstUrl } from '@mmd/domain-recovib/burst'
import { uuidTable } from '@mmd/domain-recovib/backoffice'

// ############################################################
// ## Globals
// ############################################################

let streamCache = new Cache(({ streamId, lensId }) => {
  return `${streamId}__${lensId}`
})
export const takeStream = ({ streamId, lensId }) => streamCache.take({ streamId, lensId })
const upsertStream = ({ streamId, lensId, timeRange, ...data }) => {
  streamCache.upsert({ streamId, lensId }, { timeRange, ...data })
}

// ############################################################
// ## Payload dispatchers
// ############################################################

export const dispatchAccountActive = (activeAccount) => {
  const signalTimeRange = asActionType(action.ACCOUNT_ACTIVE_CHANGE)
  return (dispatch) => dispatch(signalTimeRange(activeAccount))
}

export const dispatchTimeRangeChange = (timeRange) => {
  const signalTimeRange = asActionType(action.TIME_RANGE_CHANGE)
  return (dispatch) => dispatch(signalTimeRange(timeRange))
}

export const dispatchFetchStreams = (streamIds) => {
  const signalStamp = asActionType(action.STREAM_DATA_CHANGE)
  return (dispatch, getState) => {
    try {
      const state = getState()
      const { activeLens } = state.lens
      const { timeRange } = state.monitorStat
      const lensId = activeLens.slug

      streamIds.forEach((streamId) => {
        const whenStream = (data) => upsertStream({ streamId, lensId, timeRange, ...data })
        const passOn = { streamId, lensId, timeRange }
        const whenSuccess = (docs) => {
          formatter(docs, ({ data }) => {
            whenStream({ ...passOn, ...data })
            dispatch(signalStamp(Date.now()))
          })
        }
        const whenError = (err) => {
          logger.log(err)
          whenStream({ ...passOn, error: err })
          dispatch(signalStamp(Date.now()))
        }
        whenStream({ ...passOn, loading: true })
        dispatch(signalStamp(Date.now()))
        const { query, formatter, cosmosStore } = getStreamConfig({ streamId, timeRange })
        fetchFormattedData({ query, cosmosStore }, { whenSuccess: whenSuccess, whenError })
      })
    } catch (err) {
      logger.log(err)
    }
  }
}

// ############################################################
// ## Props Injectors
// ############################################################

export const monitorStatProps = [
  (state) => {
    const { activeLens } = state.lens
    const { timeRange, streamLastUpdate } = state.monitorStat
    const backoffice = getBackoffice()

    // uuid
    const getUuid = getItemFromSlugGetter(backoffice, uuidTable)
    // lens with streams
    const getStream = ({ streamId, lensId }) => streamCache.take({ streamId, lensId })
    const lens = expandMonitorLens(activeLens, getStream)
    const { i18n: title, streams, streamsData, config, burstTableName, startDate, hasSafeLevels } = lens || {}

    console.log('[lens]', lens)

    return {
      title,
      streams,
      streamsData,
      config,
      burstTableName,
      hasSafeLevels,
      startDate,
      timeRange,
      streamLastUpdate,
      streamUrl: getStreamUrl(getUuid, activeLens),
      getBurstUrl: getBurstUrl(activeLens),
      fetchEventListing: fetchEventListing(activeLens),
      fetchBurstListing: fetchBurstListing(activeLens),
    }
  },
  (dispatch, ownProps) => ({
    whenExit: () => dispatch(dispatchBackToAccounts()),
    whenTimeRangeChange: (timeRange) => dispatch(dispatchTimeRangeChange(timeRange)),
    whenAccountActive: (activeAccount) => dispatch(dispatchAccountActive(activeAccount)),
    whenFetchStreams: (streams) => {
      const streamIds = streams.map((d) => d.slug)
      dispatch(dispatchFetchStreams(streamIds))
    },
  }),
]

// ############################################################
// ## Store Reducers
// ############################################################

export const reduceTimeRangeChange = (state, payload) => {
  return { ...state, timeRange: payload }
}

export const reduceStreamDataChange = (state, payload) => {
  return { ...state, streamLastUpdate: payload }
}
export const reduceBurstDataChange = (state, payload) => {
  return { ...state, burstLastUpdate: payload }
}

export const monitorStatReducer = [
  {
    streamLastUpdate: undefined,
    timeRange: undefined,
  },
  {
    [action.TIME_RANGE_CHANGE]: reduceTimeRangeChange,
    [action.STREAM_DATA_CHANGE]: reduceStreamDataChange,
    [action.BURST_DATA_CHANGE]: reduceBurstDataChange,
  },
]

/*
import { loadDays, configsAsProps } from '@mmm/domain-recovib/event'

export const eventTable = [
  (store) => {
    const lens = store.lens
    const configs = lens && lens.hasOwnProperty(blockUid) ? lens[blockUid] : undefined
    return configsAsProps(configs)
  },
  (dispatch, ownProps) => {
    const { store } = ownProps
    return {
      loadDays: (events) =>
        loadDays((events, (filteredEvents) => {
          // this.setState({ events: filteredEvents })
          // dispatch({ type: 'REGISTER_BLOCK', payload: blockUid })
        }),
      registerBlock: () => {
        dispatch({ type: 'REGISTER_BLOCK', payload: blockUid })
      },
    }
  },
]
*/
