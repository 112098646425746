import { getSecret } from '@mmd/domain-recovib/backoffice/uuid'

export const getAccountSlug = (activeLens) => {
  if (!activeLens) {
    return
  }
  const { account = {} } = activeLens || {}
  return account.slug
}

export const getAccountUrl = (activeLens) => {
  if (!activeLens) {
    return
  }
  const accountSlug = getAccountSlug(activeLens)
  return `https://tenant.recovib.io/${accountSlug}`
}

export const getAccountSecretUrl = (getUuid, activeLens) => {
  if (!activeLens) {
    return
  }

  const tenantUrl = getAccountUrl(activeLens)
  const tenantSecret = getSecret(getUuid, getAccountSlug(activeLens))
  return `${tenantUrl}/${tenantSecret}`
}
