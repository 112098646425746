import * as logger from '@mmd/io/logger'
import * as action from './ActionType'
import { asActionType } from '@mmd/flow/action-payload'
import { dispatchBackToAccounts } from './lens'
// globals
import Cache from '@mmd/fns/cache'
import { getBackoffice } from '../globals'
// io
import { fetchFormattedData } from '@mmd/io/az-cosmos/fetch-cosmos'
// domain-recovib
import { expandMonitorLens } from '@mmd/domain-recovib/lens'
import { getItemFromSlugGetter } from '@mmd/domain-recovib/backoffice'
import { takeStream, upsertStream, dispatchTimeRangeChange, dispatchAccountActive, dispatchFetchStreams } from './monitor-stat'
import { uuidTable } from '@mmd/domain-recovib/backoffice'
import { burstId } from '@mmd/domain-recovib/stream/cosmos-statement'
import { getStreamUrl } from '@mmd/domain-recovib/stream'
import { getBurstUrl } from '@mmd/domain-recovib/burst'
import { multiApp } from '@mmd/domain-recovib/stream/formatter'
import { getStreamConfig } from '@mmd/domain-recovib/stream/configs'
import { burstsInStreams } from '@mmd/fns/tlm-burst/bursts-in-streams'
import { burstAsDownload } from '@mmd/fns/tlm-burst/burst-as-tsv'
import { triggerDownload } from '@mmd/io/trigger-download'
import { toTsv } from '@mmd/fns/tsv'
import { pickValueAddPosition, burstAsTable } from '@mmd/fns/tlm-burst/utils'
import { burstAsSeries } from '@mmd/fns/tlm-burst'

// ############################################################
// ## Globals
// ############################################################

let burstCache = new Cache(({ burstId }) => {
  return `${burstId}`
})
export const takeBurst = (burstId) => burstCache.take({ burstId })
export const upsertBurst = ({ burstId, timeRange, ...data }) => {
  burstCache.upsert({ burstId }, { timeRange, ...data })
}

// ############################################################
// ## Props Injectors
// ############################################################

export const dispatchFetchBurst = (streamId, burstUid) => {
  const signalBurst = asActionType(action.BURST_DATA_CHANGE)
  return (dispatch, getState) => {
    const state = getState()
    const { activeLens } = state.lens
    const { timeRange } = state.monitorBurst
    const lensId = activeLens.slug

    const { cosmosStore } = getStreamConfig({ streamId, timeRange })
    const query = burstId(burstUid)
    const passOn = { streamId, lensId, timeRange }

    const whenBurst = (data) => upsertBurst({ burstId: burstUid, timeRange, ...data })

    const whenSuccess = (docs) => {
      multiApp(docs, ({ data }) => {
        whenBurst({ ...passOn, ...data })
        dispatch(signalBurst({ stamp: Date.now(), burstId: burstUid }))
      })
    }
    const whenError = (err) => {
      logger.log(err)
      whenBurst({ ...passOn, error: err })
      dispatch(signalBurst({ stamp: Date.now(), burstId: undefined }))
    }
    whenBurst({ ...passOn, loading: true })
    dispatch(signalBurst({ stamp: Date.now(), burstId: undefined }))
    fetchFormattedData({ query, cosmosStore }, { whenSuccess, whenError })
  }
}

// ############################################################
// ## Props Injectors
// ############################################################
const whenDownloadBurst = (slug, asChannels) => {
  var fileName = slug
  //this will remove the blank-spaces from the title and replace it with an underscore
  const { fields, rows } = burstAsDownload(asChannels)
  const tsv = toTsv(rows, fields)
  triggerDownload(fileName + '.tsv', tsv, document)
}

export const monitorBurstProps = [
  (state) => {
    const { activeLens } = state.lens
    const { timeRange, streamLastUpdate, burstLastUpdate, burstId } = state.monitorBurst

    // uuid
    const backoffice = getBackoffice()
    const getUuid = getItemFromSlugGetter(backoffice, uuidTable)

    // burst Table
    const lens = expandMonitorLens(activeLens, takeStream)
    const { i18n: title, streams, streamsData, config, burstTableName, startDate } = lens || {}
    let bursts = burstsInStreams(streamsData)

    // burst view
    let asChannels, burstSummary
    const burst = takeBurst(burstId)
    if (burst && burst.series) {
      asChannels = pickValueAddPosition(burst.series)
      const { fields, rows } = burstAsTable(burst.series, 1000)
      burstSummary = { slug: 'abc', rows, fields }
    }

    const datePickerProps = { timeRange, firstDate: startDate }
    const burstTableProps = { bursts }
    const burstTemporalProps = burstSummary
      ? {
          channels: asChannels || burstAsSeries(burstSummary),
          slug: burstSummary.slug,
        }
      : undefined

    return {
      title,
      bursts,
      asChannels,
      burstSummary,
      datePickerProps,
      burstTableProps,
      burstTemporalProps,
      streams,
      streamsData,
      config,
      burstTableName,
      startDate,
      timeRange,
      streamLastUpdate,
      burstLastUpdate,
      streamUrl: getStreamUrl(getUuid, activeLens),
      getBurstUrl: getBurstUrl(activeLens),
    }
  },
  (dispatch, ownProps) => {
    const { store } = ownProps
    return {
      whenExit: () => dispatch(dispatchBackToAccounts()),
      whenTimeRangeChange: (timeRange) => dispatch(dispatchTimeRangeChange(timeRange)),
      whenAccountActive: (activeAccount) => dispatch(dispatchAccountActive(activeAccount)),
      whenLoadTable: () => {
        const state = store.getState()
        const { activeLens } = state.lens
        const lens = expandMonitorLens(activeLens, takeStream)
        const { streams } = lens || {}

        const streamIds = streams.map((d) => d.slug)
        dispatch(dispatchFetchStreams(streamIds))
      },
      whenViewBurst: (slug) => {
        const state = store.getState()
        const { activeLens } = state.lens
        const lens = expandMonitorLens(activeLens, takeStream)
        if (lens) {
          const { streamsData } = lens || {}
          let bursts = burstsInStreams(streamsData)

          const activeBurst = bursts.find((d) => d.slug === slug)
          const { streamId, id: burstId } = activeBurst

          dispatch(dispatchFetchBurst(streamId, burstId))
        }
      },
      whenDownloadBurst: (slug) => {
        const state = store.getState()
        const { burstId } = state.monitorBurst
        const burst = takeBurst(burstId)
        const asChannels = pickValueAddPosition(burst ? burst.series : null)
        whenDownloadBurst(slug, asChannels)
      },
    }
  },
]

// ############################################################
// ## Store Reducers
// ############################################################

export const reduceTimeRangeChange = (state, payload) => {
  return { ...state, timeRange: payload }
}

export const reduceStreamDataChange = (state, payload) => {
  return { ...state, streamLastUpdate: payload }
}
export const reduceBurstDataChange = (state, payload) => {
  const { stamp, burstId } = payload
  return { ...state, burstLastUpdate: stamp, burstId }
}

export const monitorBurstReducer = [
  {
    streamLastUpdate: undefined,
    timeRange: undefined,
  },
  {
    [action.TIME_RANGE_CHANGE]: reduceTimeRangeChange,
    [action.STREAM_DATA_CHANGE]: reduceStreamDataChange,
    [action.BURST_DATA_CHANGE]: reduceBurstDataChange,
  },
]
