export const tables = {
  ORGANISATION: 'organisation',
  ROLE: 'role',
  USER: 'user',
  ACCOUNT: 'account',
  DEVICE: 'device',
  LENS: 'lens',
  LENS_BLOCKS: 'lens_blocks',
  DATA_STREAM: 'data_stream',
  CONFIG: 'config',
  SETUP: 'setup',
  TLM_PACKET: 'tlm_packet',
  UUID: 'uuid',
}

Object.freeze(tables)
