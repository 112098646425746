export const createRecords = (airTable, keyValues, whenAction) => {
  const newEntries = keyValues.map(d => {
    const { key, value } = d;
    return { fields: { key, value } };
  });
  airTable.create(newEntries, function(err, records) {
    if (typeof whenAction !== "function") {
      return;
    }
    if (err) {
      whenAction({ type: "CREATE_FAIL", payload: err });
      return;
    } else {
      const recordIds = records.map(d => d.getId());
      whenAction({ type: "CREATE_IDS", payload: recordIds });
    }
  });
};

export const findByKey = (airTable, key, whenAction) => {
  if (typeof whenAction !== "function") {
    return;
  }
  airTable.select({ filterByFormula: `SEARCH("${key}",{key})` }).eachPage(
    function page(records, fetchNextPage) {
      const [record] = records || [];
      whenAction({ type: "FIND_BY_KEY", payload: record });
    },
    function done(error) {
      whenAction({ type: "FIND_BY_KEY_FAIL", payload: error });
    }
  );
};

// 'appK7ek9FGj1IL9ms'

export const retrieveRecord = (airTable, airId, whenAction) => {
  if (typeof whenAction !== "function") {
    return;
  }
  airTable.find(airId, function(err, record) {
    if (err) {
      whenAction({ type: "RETRIEVE_FAIL", payload: err });
      return;
    } else {
      whenAction({ type: "RETRIEVE_RECORD", payload: record });
    }
  });
};

export const updateRecords = (airTable, records, whenAction) => {
  const updates = records.map(d => {
    const { airId, key, value } = d;
    return { id: airId, fields: { key, value } };
  });
  airTable.update(updates, function(err, records) {
    if (err) {
      whenAction({ type: "RETRIEVE_FAIL", payload: err });
      return;
    } else {
      whenAction({ type: "UPDATED_RECORDS", payload: records });
    }
  });
};

export const deleteRecords = (airTable, airIds, whenAction) => {
  airTable.destroy(airIds, function(err, deletedRecords) {
    if (err) {
      whenAction({ type: "RETRIEVE_FAIL", payload: err });
      return;
    } else {
      whenAction({ type: "DELETED_RECORDS", payload: deletedRecords });
    }
  });
};
