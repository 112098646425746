import React, { PureComponent, Fragment } from 'react'
import styled from 'styled-components'

const Table = styled.div``

const TableBody = styled.div`
  height: 300px;
  max-height: 300px;
  width: 100%;
  max-width: 100%;
  text-align: left;
  overflow-y: scroll;
`

const TableHeader = styled.div`
  font-weight: bold;
  display: grid;
  grid-template-columns: ${props => {
    return props.widths.join(' ')
  }};
`
const Row = styled.div`
  display: grid;
  grid-template-columns: ${props => {
    return props.widths.join(' ')
  }};
`

const D = styled.div`
  align-self: center;
  justify-self: left;
  margin: 2px;
`

class DataTable extends PureComponent {
  render() {
    const { items, renderer } = this.props

    const widths = renderer.widths
    const renderCell = (d, i) => {
      return <D key={`k_${i}`}>{d}</D>
    }
    const tableHeader = renderer.headFragments().map(renderCell)
    const tableRows = (items || []).map(row => {
      return (
        <Row widths={widths} key={renderer.rowKey(row)}>
          {renderer.rowFragments(row).map(renderCell)}
        </Row>
      )
    })

    if (!items || !items.length) {
      return <Fragment>--- no data --</Fragment>
    } else {
      return (
        <Table>
          <TableHeader widths={widths}>{tableHeader}</TableHeader>
          <TableBody>{tableRows}</TableBody>
        </Table>
      )
    }
  }
}

export default DataTable
