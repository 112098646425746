import moment from 'moment'

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  var currDate = moment(startDate).startOf('day')
  var lastDate = moment(endDate).startOf('day')

  var dates = [currDate.clone()]
  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    dates.push(currDate.clone())
  }

  return dates.map((d) => {
    return d.format().split('T').shift()
  })
}

export const startOfIsoWeek = (dt) => {
  return moment(dt).startOf('isoWeek')
}

export const dateStringAsMs = (d) => {
  return moment(d).valueOf()
}

export const msAsSeconds = (ms) => {
  return ms / 1000
}

export const secondsAsHMS = (seconds) => {
  const d = Math.abs(parseInt(seconds, 10))
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)
  return [h, m, s]
}

export const secondsToHms = (d, fmt = 1) => {
  d = Math.abs(parseInt(d, 10))
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  if (fmt === 2) {
    const str = h > 0 ? `${h} H ••••••` : m > 0 ? `${m} m •••   ` : s >= 0 ? `${s} s •     ` : ``
    return str.padStart(11, ' ')
  } else if (fmt === 3) {
    return h > 0 ? '•••' : m > 0 ? '•• ' : s > 0 ? '•  ' : '   '
  } else {
    return (h > 0 ? h.toString() + 'H' : m > 0 ? m.toString() + 'm' : s > 0 ? s.toString() + 's' : '-----').padStart(2, ' ')
  }
}

export const hms = (h, m = 0, s = 0) => (h * 60 * 60 + m * 60 + s) * 1000

/*
  const start = defaultDpc.time.replace(' ', 'T')
  const ms = moment(start).unix()
*/

/*
  const days = enumerateDaysBetweenDates(timeRange.start, timeRange.end)
  const weekStart = startOfIsoWeek(timeRange.start)
  const quarter = weekStart.quarter()
  const year = weekStart.year()
  const daysInPicker = {
    weekStart: weekStart.format('YYYY-MM-DD'),
    days,
    quarter,
    year,
  }
*/

/*
const timeStart = slug.split(/_+/).slice(1)
const [stamp_date, stamp_time] = timeStart.split('T')
const startTime = [stamp_date, stamp_time.replace(/[-:]\d{6}Z$/, '.000000Z').replace(/-/g, ':')].join('T')
 console.log('Burst', timeStart)
  2019-12-19T12:46-15.000000Z
 '2019-12-17T13:39:43.000000Z'
       const [value, nope, time] = d
const startDate = moment(channels[0].data[1][1])
*/
