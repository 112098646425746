import React, { PureComponent } from 'react'
import styled from 'styled-components'
import DataTable from '@mmd/block/data-table'
import { enumFromArray } from '@mmd/fns/enum'
// design system
import { VBox, HBox } from '@mmd/design-layout'
import Button from '@mmd/design-system/button'
// ui components
import moment from 'moment'
export const Action = enumFromArray(['BURST_SUMMARY'])

const Active = styled.div`
  font-weight: bold;
`
const Inactive = styled.div``

class BurstTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      active: undefined,
      bursts: undefined,
    }
    this.bound = {
      onPickItem: (event) => {
        const { slug } = event.currentTarget.dataset
        this.setState({ active: slug })
      },
    }
  }

  render() {
    const { bursts, ItemActions } = this.props
    const { active } = this.state
    const { onPickItem } = this.bound

    const tableRenderer = {
      headFragments: (d) => {
        return ['Time', 'Wheel', 'Max Y/Z (g)']
      },
      rowKey: (d) => {
        return d.slug
      },
      rowFragments: (d) => {
        const { slug, stamp: dt, loc } = d
        const isActive = active === slug ? true : false
        // 10A17-2020-01-24T13,13,28.000000Z__02.tsv
        // 10A17__2019-05-21T11-44-25-000001Z.tsv
        // :REVIEW: customised
        const timestamp = moment(dt).format('llll')
        const C = isActive ? Active : Inactive
        const actionProps = { slug, isActive }
        return [
          <C>{timestamp}</C>,
          <C>{loc}</C>,
          <C>{d.Y.max.toFixed(3)}</C>,
          <HBox onClick={onPickItem} data-slug={slug}>
            <ItemActions {...actionProps} />
          </HBox>,
        ]
      },
      widths: ['200px', '100px', '100px', '100px'],
    }

    return (
      <VBox>
        <DataTable renderer={tableRenderer} items={bursts} />
      </VBox>
    )
  }
}
export default BurstTable
