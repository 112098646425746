import React, { PureComponent, Fragment } from 'react'
import { VBox, HBox, Text, VSpacer, HSpacer } from '@mmd/design-layout'
import { defaultTokens, Layout, Section, Title, WidgetBox } from './styled'
// Blocks
import Button from '@mmd/design-system/button'
import DateRangePicker from '@mmd/block/week-range-picker'
import Burst from '@mmd/block-recovib/telemetry-burst-temporal-domain'
// Relative
import BurstTable from './burst-table'
import withViewAndDownloadButtons from './withViewAndDownloadButtons'

class MonitorBursts extends PureComponent {
	constructor(props) {
		super(props)
	}

	render() {
		const { tokens = defaultTokens } = this.props
		const { whenTimeRangeChange, whenLoadTable, whenExit, whenViewBurst, whenDownloadBurst } = this.props
		const { title, datePickerProps, burstTableProps, burstTemporalProps } = this.props

		return (
			<Layout>
				<HBox>
					<Button>
						{'<--'} <Text onClick={whenExit}>Back To Accounts</Text>
					</Button>
				</HBox>
				<VSpacer height={32} />
				<Section tokens={tokens}>
					<Title>{title}</Title>
					<VSpacer height={16} />
				</Section>
				<VSpacer height={32} />
				<Section tokens={tokens}>
					<HBox>
						<Button primary tokens={null} onClick={whenLoadTable}>
							Load Data
						</Button>
						<HSpacer width={16} />
						<VBox>
							<DateRangePicker whenChange={whenTimeRangeChange} showWeeks={false} {...datePickerProps} />
						</VBox>
					</HBox>
					<VSpacer height={8} />
					<BurstTable ItemActions={withViewAndDownloadButtons({ view: whenViewBurst, download: whenDownloadBurst })} {...burstTableProps} />
				</Section>
				{burstTemporalProps && (
					<Section>
						<Title>Burst Details</Title>
						<Burst {...burstTemporalProps} />
					</Section>
				)}
			</Layout>
		)
	}
}

MonitorBursts.propTypes = {}
export default MonitorBursts
