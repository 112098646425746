import React, { PureComponent } from 'react'

import styled from 'styled-components'
import Chart from 'react-apexcharts'
// mmd
import { VBox } from '@mmd/design-layout'
// local configs
import { getOptions } from './options-din-3axes'

// :TODO: move the Notification Box to design system
const Notification = {}
Notification.Warn = styled.div`
	background-color: #ef9a9a;
	border: 2px solid #d32f2f;
	padding: 16px;
`

const CHART_HEIGHT_BRUSH = 130
const CHART_HEIGHT_MAIN = 360

class TimeSeries extends PureComponent {
	constructor(props) {
		super(props)
		const { ymax = 7, ymin = 0, maxStd = 5, i18n } = props || {}
		const { main: optionsMain, brush: optionsBrush } = getOptions({
			uid: 'building',
			title: i18n || 'Vibrations DIN4150-3/L2 VB2 (mm/s)',
			colors: ['#28A138', '#F9971E', '#3B5998'],
			ymax,
			ymin,
			maxStd,
			tooltTipRounding: 5,
			yRounding: 1,
			safeLevelMarker: { color: '#0071ca', dash: 3, text: `${maxStd} mm/s` }, // maxPoint >= maxStd ? { color: '#b22200', dash: 3 } : { color: '#3ca53a', dash: 0 }
		})
		this.state = { optionsMain, optionsBrush }
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		})
	}

	render() {
		const { series } = this.props
		const { optionsMain, optionsBrush } = this.state

		// ###############################################################
		// ### If we have no serie to show, we have nothing to show
		// ###############################################################
		if (!series.length || !series[0]) {
			return <Notification.Warn>We are unable to display the graph. No data are available yet.</Notification.Warn>
		}

		// ###############################################################
		// ### Computing the maximum value for the brush
		// ###############################################################
		let seriesBrush = [{ name: 'max', data: [] }]
		let maxPoint = -1
		let firstSeries = series[0] || []
		let firstData = firstSeries ? firstSeries.data : []
		if (firstData && firstData.length) {
			for (let i = 0, n = (firstData || []).length; i < n; i++) {
				const max = Math.max(...series.map((d) => d.data[i][1]))
				if (maxPoint < max) {
					maxPoint = max
				}
				seriesBrush[0].data.push([firstData[i][0], max])
			}
		}

		const maxStd = optionsMain.annotations.yaxis[0].y
		if (maxPoint >= maxStd) {
			const line = maxPoint >= maxStd ? { color: '#b22200', dash: 3 } : { color: '#3ca53a', dash: 0 }

			optionsMain.annotations.yaxis[0].strokeDashArray = line.dash
			optionsMain.annotations.yaxis[0].borderColor = line.color
			optionsMain.annotations.yaxis[0].label.borderColor = line.color
			optionsMain.annotations.yaxis[0].label.style = {
				color: '#fff',
				background: line.color,
			}
		}

		return (
			<VBox>
				<Chart options={optionsMain} series={series} type="line" height={CHART_HEIGHT_MAIN} />
				<Chart options={optionsBrush} series={seriesBrush} type="area" height={CHART_HEIGHT_BRUSH} />
			</VBox>
		)
	}
}

export default TimeSeries

/*

*/
