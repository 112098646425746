import { getTable, getBase, createRecords, findByKey, updateRecords } from '@mmd/io/airtable-crud'

class BurstTagManager {
  constructor(tableName) {
    this.state = {
      base: getBase({
        apiKey: process.env.AIRTABLE_API_KEY || 'keyOsrKuATYlmMO99',
        baseId: process.env.USER_EDITS_BASE || 'appK7ek9FGj1IL9ms'
      }),
      tableName: window.location.href.match(/^https?:\/\/(staging|localhost)/) ? `staging__${tableName}` : `staging__${tableName}`
    }
  }
  retrieveTags(slug, asyncReturn) {
    const { base, tableName } = this.state
    let table = getTable(tableName, base)
    findByKey(table, slug, ({ type, payload: record }) => {
      if (typeof asyncReturn === 'function') {
        const { tags, _id } = record ? { tags: (record.fields || {}).value, _id: record.getId && record.getId() } : {}
        asyncReturn(tags, _id)
      }
    })

    let tags
    if (slug === '10A17__2020-01-14T13-21-35-000000Z') {
      tags = ['reactjs', 'html', 'css']
    }
    return tags
  }
  updateTags(tags, airId) {
    let table = getTable(this.state.tableName, this.state.base)
    updateRecords(table, [{ airId, value: tags }], ({ type, payload: records }) => {})
  }

  createTags(tags, burstSlug, asyncReturn) {
    let table = getTable(this.state.tableName, this.state.base)
    createRecords(table, [{ key: burstSlug, value: tags }], ({ type, payload: records }) => {
      const [airId] = records || []
      asyncReturn(airId)
    })
  }
}

export default BurstTagManager
