class Cache {
  constructor(getKey) {
    this.getKey = getKey
    this.state = { cached: {} }
  }

  take(args) {
    const key = this.getKey(args)
    return this.state.cached[key]
  }
  upsert(args, data) {
    const key = this.getKey(args)
    this.state.cached[key] = { ...args, ...data }
  }
}

export default Cache
