export const monitor = (defaultName, stat = 'peak') => {
  return (timeRange, name = defaultName) => {
    const { start, end } = timeRange
    return {
      id: '99',
      query: `select r.acq_dt, r.${stat}_data as data, r.n_channels, r.n_stats, r.p_stats, r.virtual_channel_dsf, r.virtual_channel_names, r.virtual_channel_units, r.name  from r where (r.msg_dt between @start  and @end ) AND r.tlm_type = @tlm AND r.name = @name ORDER BY r.msg_dt asc`,
      parameters: [
        { name: '@start', value: start },
        { name: '@end', value: end },
        { name: '@tlm', value: 4 },
        { name: '@name', value: name },
      ],
    }
  }
}

export const burstTable = (defaultName, stat = 'raw') => {
  return (timeRange, name = defaultName) => {
    const { start, end } = timeRange
    return {
      id: '99',
      query: `select c.id, c.acq_dt, c.${stat}_data_summary, c.n_channels, c.n_stats, c.p_stats, c.virtual_channel_dsf, c.virtual_channel_names, c.virtual_channel_units, c.name  from c where (c.msg_dt between @start  and @end ) AND c.tlm_type = @tlm AND STARTSWITH(c.name, @name) ORDER BY c.msg_dt asc`,
      parameters: [
        { name: '@start', value: start },
        { name: '@end', value: end },
        { name: '@tlm', value: 2 },
        { name: '@name', value: name },
      ],
    }
  }
}

/*
      query: `select c.id, c.acq_dt, c.${stat}_data_summary, c.n_channels, c.n_stats, c.p_stats, c.virtual_channel_dsf, c.virtual_channel_names, c.virtual_channel_units, c.name  from c where (c.msg_dt between @start  and @end ) AND c.tlm_type = @tlm AND STARTSWITH(c.name, @name) ORDER BY c.msg_dt asc`,

      `select *  from c where (c.msg_dt between @start  and @end ) AND c.tlm_type = @tlm AND STARTSWITH(c.name, @name) ORDER BY c.msg_dt asc`,

      where (c.msg_dt between "2020-06-22T00:00:00.000Z"  and "2020-06-22T23:59:59.999Z" ) AND STARTSWITH(c.name, "steam") ORDER BY c.msg_dt asc

      where (c.msg_dt between "2020-06-24T00:00:00.000Z"  and "2020-06-24T23:59:59.999Z" ) AND STARTSWITH(c.name, "steam") ORDER BY c.msg_dt asc

      where (c.msg_dt between "2020-07-01T00:00:00.000Z"  and "2020-07-01T23:59:59.999Z" ) AND STARTSWITH(c.name, "nanny") ORDER BY c.msg_dt asc
      */

export const burstId = (id, stat = 'raw') => {
  return {
    id: '99',
    query: `select r.acq_dt, r.${stat}_data as data, r.n_channels, r.n_stats, r.p_stats, r.virtual_channel_dsf, r.virtual_channel_names, r.virtual_channel_units, r.name  from r where r.id = @id`,
    parameters: [{ name: '@id', value: id }],
  }
}

export const dump = (name) => {
  return (timeRange) => {
    const { start, end } = timeRange
    const statement = name
      ? {
          id: '99',
          query: 'select * from r where (r.msg_dt between @start  and @end ) AND  r.name = @name  ORDER BY r.msg_dt asc',
          parameters: [
            { name: '@start', value: start },
            { name: '@end', value: end },
            { name: '@name', value: name },
          ],
        }
      : {
          id: '99',
          query: 'select * from r where (r.msg_dt between @start  and @end ) ORDER BY r.msg_dt asc',
          parameters: [
            { name: '@start', value: start },
            { name: '@end', value: end },
          ],
        }
    return statement
  }
}

export const dumpRetro = (name) => {
  return (timeRange) => {
    const { start, end } = timeRange
    return name
      ? {
          id: '99',
          query: 'select * from r where(r.device.metadata.msg_dt between @start  and @end ) AND r.name = @name ORDER BY r.device.metadata.msg_dt asc',
          parameters: [
            { name: '@start', value: start },
            { name: '@end', value: end },
            { name: '@name', value: name },
          ],
        }
      : {
          id: '99',
          query: 'select * from r where(r.device.metadata.msg_dt between @start  and @end ) ORDER BY r.device.metadata.msg_dt asc',
          parameters: [
            { name: '@start', value: start },
            { name: '@end', value: end },
          ],
        }
  }
}

export const vibRetro = (timeRange) => {
  const { start, end } = timeRange
  return {
    id: '99',
    query: 'select r.device.channels from r where(r.device.metadata.msg_dt between @start  and @end ) ORDER BY r.device.metadata.msg_dt asc',
    parameters: [
      { name: '@start', value: start },
      { name: '@end', value: end },
    ],
  }
}
