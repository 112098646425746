import React, { PureComponent } from 'react'
import styled from 'styled-components'
import DataTable from '@mmd/block/data-table'
import { fetchBurst, filterBurstRows, fetchIfUndefined } from '@mmd/io/dh-tables/'
import { enumFromArray } from '@mmd/fns/enum'
import { whenAction } from '@mmd/block-recovib/_block-action'
// design system
import { VBox } from '@mmd/design-layout'
import Button from '@mmd/design-system/button'
// ui components
import moment from 'moment'

export const Action = enumFromArray(['BURST_SUMMARY'])

const Active = styled.div`
	font-weight: bold;
`
const Inactive = styled.div``

let rows = undefined

class TelemetryBursts extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			active: undefined,
			bursts: undefined,
		}
		this.bound = {
			onBurstView: (event) => {
				const { slug } = event.currentTarget.dataset
				this.setState({ active: slug })
				this.loadBurst(slug)
			},
			onBurstDownload: (event) => {
				const { slug } = event.currentTarget.dataset
				this.downloadBurst(slug)
			},
			whenBurstAction: ({ type, payload }) => {
				const pickedBurst = payload
				this.setState({ pickedBurst, burstSummary: undefined }, () => {
					this.loadBurst(pickedBurst, this.state.bursts)
				})
			},
		}
	}

	componentDidMount() {
		this.loadDays()
	}
	componentDidUpdate(prevProps) {
		if (this.props.days !== prevProps.days || this.props.showAll !== prevProps.showAll) {
			this.loadDays()
		}
	}
	loadDays() {
		const { showAll, days, fetchBurstListing } = this.props
		const ymds = showAll ? undefined : days
		fetchIfUndefined(rows, fetchBurstListing, (r) => {
			rows = r
			const bursts = filterBurstRows(r, ymds)
			this.setState({ bursts })
		})
	}

	downloadBurst(slug) {
		const { getBurstUrl } = this.props

		const url = getBurstUrl(slug)
		const link = document.createElement('a')
		link.href = url
		link.target = '_blank'
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	loadBurst(slug) {
		const { getBurstUrl } = this.props
		fetchBurst(slug, getBurstUrl, ({ rows, fields }) => {
			const burst = { slug: slug, rows: rows, fields: fields }
			whenAction({ type: 'BURST_SUMMARY', payload: burst }, this)
		})
	}

	render() {
		const { onBurstView, onBurstDownload } = this.bound
		const { active, bursts } = this.state

		const tableRenderer = {
			headFragments: (d) => {
				return ['Time', 'fmgX|ppvX', 'fmgY|ppvY', 'fmgZ|ppvZ', ' ']
			},
			rowKey: (d) => {
				return d.slug
			},
			rowFragments: (d) => {
				const { slug, dt } = d
				const isActive = active === slug ? true : false
				// 10A17-2020-01-24T13,13,28.000000Z__02.tsv
				// 10A17__2019-05-21T11-44-25-000001Z.tsv
				// :REVIEW: customised
				const timestamp = moment(dt).format('llll')
				const C = isActive ? Active : Inactive
				return [
					<C>{timestamp}</C>,
					<C>
						{d.fmgX}|{d.ppvX}
					</C>,
					<C>
						{d.fmgY}|{d.ppvY}
					</C>,
					<C>
						{d.fmgZ}|{d.ppvZ}
					</C>,
					<Button primary onClick={onBurstView} data-slug={slug}>
						View
					</Button>,
					isActive ? (
						<Button primary onClick={onBurstDownload} data-slug={slug}>
							Download
						</Button>
					) : null,
				]
			},
			widths: ['200px', '100px', '100px', '100px', '60px', '60px'],
		}

		return (
			<VBox>
				<h3>Available bursts data - Frequencies in Hz | Velocities in mm/s</h3>
				<DataTable renderer={tableRenderer} items={bursts} />
			</VBox>
		)
	}
}
export default TelemetryBursts
