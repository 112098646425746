export const dbEastUS = {
  host: '',
  db: '',
  mastKey: '',
  collection: '',
}

export const dbWeuNinja = {
  host: '',
  db: '',
  mastKey: '',
  collection: '',
}

export const dbSeasiaFable = {
  host: '',
  db: '',
  mastKey: '',
  collection: '',
}

export const dbMain = {
  host: 'db-main',
  db: 'db-main',
  mastKey: 'xC6n3vAD5OEUdF40FkwGYhuqsM9eXE7NZf6O0llzK77vx6cNhKoAVntUXL3sEMaMTYKpEEoN7Pg77TZkjqR7jg==',
  collection: 'production',
}

export const dbStaging = {
  host: '',
  db: '',
  mastKey: '',
  collection: '',
}

export const prodPr202 = {
  host: 'db-pr-202-01',
  db: 'pr-202-01-db',
  mastKey: 'eczCguh7sehM5VL2dtzSZEa2C7M7v1psWwlI4tUy4JDgwRKQNZGeehn3SInZbW21EjsnwwvtH3jsPfxWTGybMg==',
  collection: 'blastinghistory',
}
