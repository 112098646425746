export const getItemFromProp = (field, attr) => {
  const item = field && field[attr]
  return (id) => {
    if (!item || !item.hasOwnProperty(id)) {
      return null
    }
    const idx = item[id]
    return field.items[idx]
  }
}

export const pickFirst = (arr) => {
  return Array.isArray(arr) ? arr.shift() : null
}

/**
 *
 * @param {*} items
 */
const indexItems = (items) => {
  if (!Array.isArray(items)) {
    return {}
  }
  const ids = items.reduce((acc, { _id }, i) => {
    acc[_id] = i
    return acc
  }, {})
  const slugs = items.reduce((acc, { slug }, i) => {
    acc[slug] = i
    return acc
  }, {})
  return { items, ids, slugs }
}

/**
 *
 * @param {*} field
 * @param {*} ids
 */
export const listItemsFromIds = (field, ids) => {
  const idGetter = getItemFromProp(field, 'ids')
  return (ids || []).map(idGetter)
}

/**
 * Sort an array of values using the specified attribute
 * name of an object
 *
 * @param {*} name
 */
export const propSort = (name) => {
  return (a, b) => {
    const d1 = a[name],
      d2 = b[name]
    return d1 > d2 ? 1 : d1 === d2 ? 0 : -1
  }
}

/**
 * Provide utility functions connected to a backoffice.
 * @param {*} backoffice
 */

export const getTable = (backoffice, tableId) => {
  const tables = backoffice.table
  const itemsOrTable = tables[tableId]
  const out = Array.isArray(itemsOrTable) ? indexItems(itemsOrTable) : itemsOrTable
  return out || {}
}
export const getItemExpander = (backoffice, tableId) => {
  const { ids, items } = getTable(backoffice, tableId)
  return (d) => {
    if (!ids) {
      return
    }
    const idx = ids[d]
    return items[idx]
  }
}

export const getItemsFromIdsGetter = (backoffice, tableId) => {
  const table = getTable(backoffice, tableId)
  return (slugs) => listItemsFromIds(table, slugs)
}
export const getItemFromSlugGetter = (backoffice, tableId) => {
  const table = getTable(backoffice, tableId)
  return getItemFromProp(table, 'slugs') || {}
}
