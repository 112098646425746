import React from 'react'
import { APP_MULTI_TENANTS } from './AppTypes'

const AppChooser = (app) => {
	if (app === APP_MULTI_TENANTS) {
		return require('./multi-tenants/App').default
	} else {
		console.error(`[Error] no app with identifier '${app}'`)
		return () => <div>No app defined</div>
	}
}

export default AppChooser
