import { expandIsoString, durationInSeconds, addMilliseconds, fromYmdHms } from '@mmd/fns/datetime-iso/isoDateString'

import { roundDec } from '@mmd/fns/math-round'

export const buildIsoTimes = (n, stamper) => {
  return Array(n)
    .fill(null)
    .map((d, i) => stamper(i))
}

export const buildMs = (n, pms) => {
  return Array(n)
    .fill(null)
    .map((d, i) => i * pms)
}

export const startTime = (d) => {
  return d.acq_dt
}

export const asInt = (d) => parseInt(d, 10)
export const splitDs = (d, asType) => {
  const ds = (d || '').split('|')
  return asType ? ds.map(asType) : ds
}

export const getStat = (d, name = 'peak_data') => {
  return splitDs(d[name])
}

/*
name: 'app1|app2|app3|app4'
n_stats: '10|10|10|10',
p_stats: '30|30|30|30',
n_channels: '2|2|2|2',
*/

export const getApps = (tlm) => {
  let ns = splitDs(tlm.n_stats, asInt)
  let ps = splitDs(tlm.p_stats, asInt)
  let cs = splitDs(tlm.n_channels, asInt)
  return splitDs(tlm.name).map((name, i) => {
    return { name, n: ns[i], p: ps[i], channelQty: cs[i] }
  })
}
/*
-- tlm
virtual_channel_dsf: '1000|1000|1000|1000|1000|1000|1000|1000',
virtual_channel_names: 'FL-Y|FL-Z|RL-Y|RL-Z|RR-Y|RR-Z|FR-Y|FR-Z',
virtual_channel_units: 'g|g|g|g|g|g|g|g',
-- burst
n_raw_data: "15000|15000|15000",
virtual_channel_ids: "3|4|5",
virtual_channel_dsf: "1000|1000|1000",
virtual_channel_names: "X|Y|Z",
virtual_channel_units: "mm/s|mm/s|mm/s",
*/
export const getChannels = (tlm) => {
  let dsfs = splitDs(tlm.virtual_channel_dsf, asInt)
  let units = splitDs(tlm.virtual_channel_units)
  let ids = splitDs(tlm.virtual_channel_ids)
  return splitDs(tlm.virtual_channel_names).map((d, i) => {
    return { name: d, unit: units[i], id: ids[i], dsf: dsfs[i], idx: i }
  })
}

export const rangeGetter = (nextStart = 0) => {
  return {
    next: (n) => {
      const start = nextStart
      const end = start + n - 1
      nextStart = end + 1
      return [start, end + 1]
    },
  }
}

const getTempo = (app, channel) => {
  const { n, p } = app
  const { dsf, unit } = channel
  // :FIXME: for stats, the dsf value should never be used.
  // data sampling frequency
  const pms = p * channel.dsf
  const times = buildMs(n, pms)
  return { n, p, dsf, pms, unit, times }
}

export const appOverChannel = (tlm) => {
  let slicer = rangeGetter(0)
  return getApps(tlm).reduce((acc, app, appIdx) => {
    Array(app.channelQty)
      .fill(null)
      .forEach((d, i) => acc.push([appIdx, i, slicer.next(app.n)]))
    return acc
  }, [])
}
export const getTempos = (tlm) => {
  const getMeta = (tlm) => {
    const apps = getApps(tlm)
    const channels = getChannels(tlm)
    return ([appIdx, chanIdx, spread]) => {
      let app = apps[appIdx]
      let channel = channels[chanIdx]
      let tempo = getTempo(app, channel)
      const stream = { name: channel.name, unit: channel.unit, app: app.name }
      return { tempo, spread, stream }
    }
  }
  // [ [ 0, 0, [ 0, 10 ] ], [ 0, 1, [ 10, 20 ] ], [ 0, 2, [ 20, 30 ] ] ]
  let tempos = appOverChannel(tlm).reduce((acc, d) => {
    const { tempo, spread, stream } = getMeta(tlm)(d)
    const n_pms = [tempo.n, tempo.pms].join('_')
    if (!acc[n_pms]) {
      acc[n_pms] = { ...tempo, fields: [] }
    }
    acc[n_pms].fields.push({ ...stream, spread })
    return acc
  }, {})
  return Object.values(tempos)
}

// #############################################
// ##  Slug and the info it contains
// #############################################

export const infoFromSlug = (slug) => {
  let m
  m = m || slug.match(/([^-]+)-(\d{4}-\d{2}-\d{2})T([^Z]+)Z/)
  m = m || slug.match(/([^-]+)-(\d{4}-\d{2}-\d{2})/)

  if (m) {
    const [app, ymd, hms] = m.slice(1)
    const isoDay = fromYmdHms(ymd, hms.replace(/[,-]/g, ':'))
    const dt = expandIsoString(isoDay)
    return { app, dt, slug, ymd }
  }
}

// #############################################
// ##  intoBands
// #############################################

export const appBands = {
  femur: [
    ['alarm', (d) => d > 15],
    ['warn', (d) => d > 10],
    ['safe', (d) => d > 5],
    ['noise', (d) => true],
  ].reverse(),
  'Air fast': [
    ['alarm', (d) => d > 35],
    ['warn', (d) => d > 30],
    ['safe', (d) => d > 25],
    ['noise', (d) => true],
  ].reverse(),
  ANY: [
    ['alarm', (d) => d > 5],
    ['warn', (d) => d > 3],
    ['safe', (d) => d > 1],
    ['noise', (d) => true],
  ].reverse(),
}

export const intoBands = (decimals = 3) => {
  let byKey = {}
  return {
    add: ({ app = 'app', dt, stream, stat, fields, rows }) => {
      const bands = appBands[app] || appBands.ANY
      let buffers = [],
        currentBuffer = undefined,
        previousBand = undefined

      rows.forEach((row) => {
        const [isoTime, ...channels] = row
        const max = Math.max(...channels)
        let band,
          fitlers = [...bands] // clone it

        while (band === undefined && fitlers.length) {
          const [name, filter] = fitlers.pop()
          if (filter(max)) {
            band = name
          }
        }

        if (previousBand !== band && currentBuffer) {
          currentBuffer.duration = durationInSeconds(currentBuffer.start, isoTime)
          buffers.push(currentBuffer)
          currentBuffer = undefined
        }
        if (currentBuffer === undefined) {
          currentBuffer = { start: isoTime, duration: 0, band, max, channels }
        } else {
          const { max: prevMax } = currentBuffer
          if (max > prevMax) {
            currentBuffer.max = max
            currentBuffer.channels = channels
          }
        }
        previousBand = band
      })

      let key = app
      if (!byKey[key]) {
        byKey[key] = { buffers: [], app, key, stream, stat }
      }
      byKey[key].buffers.push(...buffers)
    },
    forEach: (fn) => {
      Object.values(byKey).forEach(({ buffers, app, key, stream, stat }) => {
        const fields = 'max, duration, band, start, channels'.split(/, */)
        const rows = buffers
          .filter((d) => d.band !== 'noise')
          .map((d) => {
            const { max, duration, band, start, channels } = d
            return [max.toFixed(1).padStart(4, ' '), duration, band, start, channels.map((d) => d.toFixed(decimals)).join('|')]
          })
        fn({ app, rows, stream, fields })
      })
    },
  }
}

export const isTlm = (tlm) => (tlm.peak_data ? true : false)
export const hasEvent = (tlm) => (tlm.events ? true : false)

export const getRows = (dataPoints, fields, config) => {
  const { decimals } = config
  const rows = fields.reduce((acc, { spread }, channelIdx) => {
    dataPoints.slice(...spread).forEach((d, rowIdx) => {
      if (!acc[rowIdx]) {
        acc[rowIdx] = []
      }
      acc[rowIdx][channelIdx] = roundDec(d, decimals)
    })
    return acc
  }, [])
  return rows
}
