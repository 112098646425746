import React, { Component, Fragment } from 'react'
import Button from '@mmd/design-system/button'
import * as s from './styled'

class AccountLensSummary extends Component {
	constructor(props) {
		super(props)
		this.bound = {
			onLensSelected: (event) => {
				const { slug } = event.target.dataset
				const cb = this.props.whenLens
				if (typeof cb === 'function') {
					cb(slug)
				}
			},
		}
	}
	render() {
		const { slug, status, setups } = this.props
		if (!setups) {
			return (
				<s.Summary>
					<s.HBox>(No setup defined)</s.HBox>
				</s.Summary>
			)
		}

		const { onLensSelected } = this.bound
		const { triggers, hasBurstData, hasEmail, hasSms } = setups
		// const status = item.layouts ? 'status-green' : 'status-red' //  Math.round(Math.random() * 10) % 6 === 1 ? 'status-red' : 'status-green'
		// const bar = item.layouts ? 'bar-4' : 'bar-0'
		// const timestamp = new Date()
		const actionFragment =
			status === 'active' ? (
				<Button primary onClick={onLensSelected} data-slug={slug}>
					Monitor
				</Button>
			) : (
				<Button disabled data-slug={slug}>
					Inactive
				</Button>
			)
		return (
			<s.Summary>
				<s.HBox>
					<s.Outputs>
						<s.Icon src="https://assets.recovib.io/icons/sms.png" disabled={hasSms} />
						<s.Icon src="https://assets.recovib.io/icons/email.png" disabled={hasEmail} />
					</s.Outputs>
					<s.Sep />
					<s.Signals>
						{triggers.map((d) => {
							return <s.Icon key={d} src={`https://assets.recovib.io/icons/${d}.png`} />
						})}
					</s.Signals>
					<s.Sep />
					<s.Outputs>
						<s.Icon src="https://assets.recovib.io/icons/burst-data.png" disabled={hasBurstData} />
					</s.Outputs>
					<s.Actions>{actionFragment}</s.Actions>
				</s.HBox>
			</s.Summary>
		)
	}
}

export default AccountLensSummary
