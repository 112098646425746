import React, { PureComponent } from 'react'
import * as s from './styled'
import BurstTags from './BurstTags'
import { SafeLevelsDin4150_Line2 } from '@mmd/block-recovib/telemetry-burst-safety-din4150'
import { VBox, HBox, HSpacer } from '@mmd/design-layout'
import Burst from '@mmd/block-recovib/telemetry-burst-temporal-domain'
import Fourier from '@mmd/block-recovib/telemetry-burst-frequency-domain'
import { burstAsSeries } from '@mmd/fns/tlm-burst'

class TelemetryHistoryExplorer extends PureComponent {
	render() {
		const { burstSummary: burst, tableName, freqOnly, asChannels, burstTemporalTitle, burstTemporalLegend } = this.props
		console.log('[BURST]', this.props)
		if (!burst) {
			return null
		}
		const { slug, showApex } = burst
		let SafeLevels = showApex ? SafeLevelsDin4150_Line2 : null

		const channels = asChannels || burstAsSeries(burst)
		const getValue = (d) => parseFloat(d[0])
		const asData = (channels) => {
			return channels.map(({ name, data }) => {
				return { name, data: data.map(getValue) }
			})
		}

		return (
			<s.ExplorerLayout>
				<BurstTags burst={burst} tableName={tableName} />
				<VBox>
					<Burst channels={channels} slug={slug} title={burstTemporalTitle} legend={burstTemporalLegend} />
					{!freqOnly && (
						<HBox fullwidth>
							<Fourier channels={asData(channels)} />
							<HSpacer size={72} />
							{SafeLevels && <SafeLevels burst={burst} />}
						</HBox>
					)}
				</VBox>
			</s.ExplorerLayout>
		)
	}
}

export default TelemetryHistoryExplorer
