export const burstsInStreams = (streamsData) => {
  if (!streamsData) {
    return []
  }
  const burssts = Object.values(streamsData).reduce((acc, d, i) => {
    if (!d) {
      return []
    }
    const { streamId, lensId, timeRange, series = [], ...more } = d || {}
    series.forEach((d) => {
      const { app, lbl, data, id, ...rest } = d
      data.forEach((d) => {
        const [stamp, max, stamper, burstId] = d
        const [loc, axis] = lbl.split('-')
        const key = `${stamper}_${loc}`
        if (!acc[key]) {
          acc[key] = { stamper, stamp, loc, streamId, lensId, timeRange, app, lbl, ...more, ...rest, id: burstId }
        }
        acc[key][axis] = { stamp, max, axis }
      })
    })
    return acc
  }, [])
  const bursts = Object.values(burssts)
    .map((d) => {
      const { stamper, loc, ...rest } = d
      return { slug: `${stamper}_${loc}`, stamper, loc, ...rest }
    })
    .sort((a, b) => {
      return b.stamp - a.stamp
    })
  return bursts
}
