import { pickFirst, propSort } from '@mmd/domain-recovib/backoffice'
import { getItemsFromIdsGetter, getItemFromSlugGetter, getItemExpander } from '@mmd/domain-recovib/backoffice'
import { lensTable } from '@mmd/domain-recovib/lens'
import { accountTable } from '@mmd/domain-recovib/account'
import { streamTable } from '@mmd/domain-recovib/stream'
import { setupTable } from '@mmd/domain-recovib/setup'
import { getStreamComponent } from '@mmd/domain-recovib/stream/widgedFactories'
import { lensConfig, hasSafeLevels } from '@mmd/domain-recovib/stream/configs'

export const expandMonitorLens = (activeLens, getStream) => {
  if (!activeLens) {
    return
  }
  // :Q: would it make sense to put the steams data in the central store instead
  // of simply the streamId of the streams that have been loaded into view.
  activeLens.streamsData = (activeLens.streams || []).reduce((acc, d) => {
    acc[d.slug] = getStream({ lensId: activeLens.slug, streamId: d.slug })
    return acc
  }, {})

  const streams = (activeLens.streams || []).map((d) => {
    return { ...d, WidgetFactory: getStreamComponent(d.slug) }
  })
  activeLens.streams = streams
  const streamSlugs = streams.map((d) => d.slug)
  activeLens.config = lensConfig(streamSlugs)
  activeLens.burstTableName = streamSlugs.join('_')
  activeLens.startDate = streams.reduce((acc, d) => {
    if (d.startDate > acc) {
      acc = d.startDate
    }
    return acc
  }, '')
  activeLens.hasSafeLevels = hasSafeLevels(streams)
  return activeLens
}

export const expandActiveLens = (backoffice, lensId) => {
  if (!lensId) {
    throw new Error('missing lensId')
  }
  const getLens = getItemFromSlugGetter(backoffice, lensTable)
  const getStreams = getItemsFromIdsGetter(backoffice, streamTable)
  const getAccounts = getItemsFromIdsGetter(backoffice, accountTable)

  const defaultLayouts = `[{"stream":"lho-2-air","dashboard":"AirPressureTimeseries"},{"stream":"lho-1-vib","dashboard":"VibrationTimeSeries"}]`

  const lensItem = getLens(lensId) || {}
  const { slug, i18n, account: accountIds, data_streams: streamIds, layouts = defaultLayouts } = lensItem
  const account = pickFirst(getAccounts(accountIds))
  const streams = getStreams(streamIds)
  return {
    slug,
    i18n,
    account,
    streams,
    dashboardLayout: JSON.parse(layouts),
  }
}

export const expandLensToRender = (backoffice, item) => {
  if (item) {
    const { i18n, data_streams, account } = item
    const expandStream = getItemExpander(backoffice, streamTable)
    const expandAccount = getItemExpander(backoffice, accountTable)
    const expandSetup = getItemExpander(backoffice, setupTable)

    const expandedStreams = data_streams.map(expandStream).map((d) => {
      if (d) {
        const setups = d.setups.map(expandSetup).sort(propSort('slug'))
        return { ...d, setups }
      }
    })
    const expandedAccount = pickFirst(expandAccount(account))

    return {
      i18n,
      expandedStreams,
      expandedAccount,
    }
  } else {
    return null
  }
}
