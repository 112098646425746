export const getDecimated = (rowsX, decimationFactor = 5) => {
  const getTime = (d) => {
    const v = typeof d[1] === 'string' ? new Date(d[1]).getTime() : d[1]
    return v
  }

  const getValue = (d) => {
    return (d && parseFloat(d[0])) || 0
  }
  return rowsX.reduce(
    (acc, d, i, arr) => {
      let { pts, buff, first, max, min, maxIndex, minIndex } = acc
      if (first === undefined && d !== undefined) {
        first = d
      }
      if ((buff.length && i % decimationFactor === 0) || i === arr.length - 1) {
        const absSorted = buff
          .map((d, i) => {
            return { abs: Math.abs(d), i }
          })
          .sort((a, b) => {
            return b.abs - a.abs
          })

        const maxAbs = absSorted[0]
        const minAbs = absSorted.pop()
        const bufMax = buff[maxAbs.i]
        // const bufMin = buff[minAbs.i]
        if (max.abs < maxAbs.abs) {
          const maxItem = arr[i - decimationFactor + maxAbs.i]
          max = {
            abs: maxAbs.abs,
            rowIdx: maxAbs.i,
            item: maxItem,
            time: getTime(maxItem),
            value: getValue(maxItem),
            absSorted,
          }
        }
        if (min.abs > minAbs.abs) {
          min = { abs: min.abs, rowIdx: i, item: arr[i], absSorted }
        }
        pts.push([getTime(d), bufMax])
        first = undefined
        buff = []
      }
      // take Math.abs
      const v = getValue(d)
      buff.push(v)

      return { pts, buff, first, max, min, maxIndex, minIndex }
    },
    {
      pts: [],
      buff: [],
      bufffirst: undefined,
      max: { abs: 0, d: undefined },
      min: { abs: Infinity, d: undefined },
    }
  )
}
