import { getAccountUrl } from '@mmd/domain-recovib/account'
import { getFirstStreamSlug } from '@mmd/domain-recovib/stream'

// ################################
// ##  FetchText
// ################################

function responseText(response) {
  if (!response.ok) throw new Error(response.status + ' ' + response.statusText)
  return response.text()
}

var noCacheHeaders = new Headers()
noCacheHeaders.append('pragma', 'no-cache')
noCacheHeaders.append('cache-control', 'no-cache')

function fetchText(input, init) {
  return fetch(input, init)
    .then(responseText)
    .catch((err) => {
      console.log('[FETCH.error]', err, input)
    })
}

// ################################
// ##  Tables that can be fetched
// ################################

export const fetchEventListing = (activeLens) => {
  if (!activeLens) {
    return
  }
  const tenantUrl = getAccountUrl(activeLens)
  const streamSlug = getFirstStreamSlug(activeLens)

  return (asyncReturn) => {
    fetchText(`${tenantUrl}/_crons/daily-dump/__ignore/${streamSlug}/table/eventbands.tsv`, noCacheSettings)
      .then((tsv) => {
        let [head, ...tail] = tsv.split('\n')
        const fields = head.split('\t')
        let rows = tail
          .map((d) => {
            const obj = d.split('\t').reduce((acc, col, i) => {
              const k = fields[i] || `col${i}`
              acc[k] = col
              return acc
            }, {})

            const [ymd, hms] = obj.start.split(' ')
            return { ...obj, ymd, hms }
          })
          .sort((a, b) => {
            const ta = a.ymd + a.hms
            const tb = b.ymd + b.hms
            return ta > tb ? -1 : ta < tb ? +1 : 0
          })
        asyncReturn([fields, rows])
      })
      .catch((err) => {
        console.log('ERROR[fetchEventListing]', err)
      })
  }
}

const noCacheSettings = {
  method: 'GET',
  headers: noCacheHeaders,
}

export const fetchBurstListing = (activeLens) => {
  if (!activeLens) {
    return
  }
  const tenantUrl = getAccountUrl(activeLens)
  const streamSlug = getFirstStreamSlug(activeLens)
  return (asyncReturn) => {
    fetchText(`${tenantUrl}/_crons/daily-dump/__ignore/${streamSlug}/table/bursts.tsv`, noCacheSettings)
      .then((tsv) => {
        let [head, ...tail] = Array.isArray(tsv) ? tsv : (tsv || '').split('\n')
        const fields = head.split('\t')
        let rows = tail
          .map((d) => {
            const obj = (d || '').split('\t').reduce((acc, col, i) => {
              const k = fields[i] || `col${i}`
              acc[k] = col
              return acc
            }, {})
            const [ymd, hms] = obj.dt.split(' ')
            return { ...obj, ymd, hms }
          })
          .sort((a, b) => {
            const ta = a.ymd + a.hms
            const tb = b.ymd + b.hms
            return ta > tb ? -1 : ta < tb ? +1 : 0
          })

        asyncReturn([fields, rows])
      })
      .catch((err) => {
        console.log('ERROR[fetchBurstListing]', err)
      })
  }
}
