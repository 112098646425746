import React, { Component } from 'react'
import { connect } from 'react-redux'
import { configureReduxStore } from './flow/configureReduxStore'
import { appReducer } from './flow/app'
import { backendDownReducer } from './flow/backendDown'
import { monitorStatReducer } from './flow/monitor-stat'
import { monitorBurstReducer } from './flow/monitor-burst'
import { lensReducer } from './flow/lens'

import { dispatchInitializeBackOffice, pageProps, accountProps } from './flow/app'
import { monitorStatProps } from './flow/monitor-stat'
import { monitorBurstProps } from './flow/monitor-burst'
import { dispatchLetInIfAdmin, loginProps, siteProps } from './flow/site-login'

import SitePage from '@mmd/block-recovib/recovibcloud-site/'
import AccountsExplorer from '@mmd/block-recovib/accounts-explorer'
import MonitorDashboard from '@mmd/block-recovib/monitor-dashboard'
import MonitorBurstDashboard from '@mmd/block-recovib/monitor-bursts'
import RecovibLoginForm from '@mmd/block-recovib/recovibcloud-loginform'

const ConnectedAccounts = connect(...accountProps)(AccountsExplorer)
const ConnectedMonitor = connect(...monitorStatProps)(MonitorDashboard)
const ConnectedMonitorBurst = connect(...monitorBurstProps)(MonitorBurstDashboard)
const ConnectedLoginForm = connect(...loginProps)(RecovibLoginForm)
const ConnectedSite = connect(...siteProps)(SitePage)

const ConnectedPage = connect(...pageProps)((props) => {
	const { store, activeLens, authError } = props
	let Page
	if (authError) {
		Page = ConnectedLoginForm
	} else if (activeLens && activeLens.slug === 'ny-heaven-rails__1-burst') {
		Page = ConnectedMonitorBurst
	} else if (activeLens) {
		Page = ConnectedMonitor
	} else {
		Page = ConnectedAccounts
	}
	return <Page store={store} />
})
class App extends Component {
	constructor(props) {
		super(props)
		const store = configureReduxStore([
			['app', ...appReducer],
			['backendDown', ...backendDownReducer],
			['monitorStat', ...monitorStatReducer],
			['monitorBurst', ...monitorBurstReducer],
			['lens', ...lensReducer],
		])
		store.dispatch(dispatchInitializeBackOffice())
		store.dispatch(dispatchLetInIfAdmin())
		this.state = { store }
	}

	render() {
		const { store } = this.state
		return (
			<ConnectedSite store={store}>
				<ConnectedPage store={store} />
			</ConnectedSite>
		)
	}
}
export default App
