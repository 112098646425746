import styled from 'styled-components'

// :TODO: move the Notification Box to design system
export const Notification = {}
Notification.Warn = styled.div`
  background-color: #ef9a9a;
  border: 2px solid #d32f2f;
  padding: 16px;
`

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
`
