import React from 'react'
import SafeLevelsDin4150 from './ChartDin'
import { LINE2 } from '@mmd/domain-recovib/din4150/lines'

export const SafeLevelsDin4150_Line2 = (props) => {
	const { burst } = props

	return (
		<SafeLevelsDin4150
			{...{
				burst,
				dinLine: LINE2,
				Fmax: 125,
				samplingFrequency: 1000,
			}}
		/>
	)
}

export default SafeLevelsDin4150_Line2
