import React, { Component, Fragment } from 'react'
import Chart from 'react-apexcharts'
import { dinPoint } from '@mmd/fns/tlm-burst'
import { dinOptions } from './dinOptions'

const getValue = (d) => parseFloat(d[0])

const options = {
	id: 'burst-ppv',
	title: 'Din 4150-3/Line 2',
	yTitle: undefined, //'valeur F',
	xTitle: 'Frequency [Hz]',
	yTooltip: function (value) {
		return value.toFixed(5)
	},
	xFormatter: function (value) {
		return value.toFixed(1)
	},
	yFormatter: function (value) {
		return (value || 0).toFixed(2)
	},
}

class ChartDin extends Component {
	render() {
		const { dinLine, channels, burst, Fmax = 125, samplingFrequency = 1000 /*, showAll = false*/ } = this.props
		if (!burst || !dinLine) {
			return null
		}
		dinLine.push({ freq: Fmax, value: dinLine.slice(-1).shift().value })
		const dinSerie = {
			name: '',
			type: 'line',
			data: dinLine.map(({ freq, value }) => {
				return { x: freq, y: value }
			}),
		}

		let rowChannels
		if (burst.rows) {
			const shape = burst.fields.slice(1).map((d) => ({ name: d, data: [] }))
			rowChannels = burst.rows.reduce((acc, d, i) => {
				d.slice(1).forEach((d, i) => {
					acc[i].data.push([d])
				})
				return acc
			}, shape)
		}
		const dotSeries = (rowChannels || channels).map((d, i) => {
			const { name, data } = d
			const { freq, value } = dinPoint(data.map(getValue), samplingFrequency)
			return {
				name,
				type: 'scatter',
				data: [{ x: freq, y: value }],
			}
		})

		return (
			<Fragment>
				<Chart series={[dinSerie, ...dotSeries]} options={dinOptions({ ...options, Fmax })} type="line" height={420} width={420} />
			</Fragment>
		)
	}
}

export default ChartDin
