import { transform as computeFFT } from './fft'

export const maxAbs = (arr) => {
  // find maximum (index and value) of the array
  const max = Math.max(...arr.map(Math.abs))
  const index = Math.max(arr.indexOf(max), arr.indexOf(-max))
  return { max: arr[index], index }
}

export const magnitude = ([rl, im]) => {
  return rl * rl + im * im
}

export const phase = (rl, im) => {
  return Math.atan2(im, rl)
}

/*
  :INFO: NLO le step de fréquence vaut l'inverse de la durée de mesure dans le temps,
  soit un pas de temps (1/sf) * le nombre de point values.length... l'inverse de tout
  cela fait sf/values.length.
*/
export const frequencyHertz = (fft, samplingFrequency) => {
  const step = samplingFrequency / fft.length
  return (i) => i * step
}

/**
 * NLO On ne tient compte que des N/2 premier point.
 * Pour garder la même énergie, les coefficients de la FFT doivent être normalisés.
 * On divise les valeurs par (N/2). Comme le premier point de la FFT appartient
 * aux 2 moitiés, on le divise aussi par 2, sinon on le compte deux fois...
 */

export const firstHalfWithEnergyAdjustment = (v, isHalved = false) => {
  const half = Math.floor(v.length / 2)
  const z = isHalved ? (d) => d / half : (d) => d
  const adjustEnergy = ([real, imag], i, arr) => {
    return i === 0 ? [z(real / 2), z(imag / 2)] : [z(real), z(imag)]
  }
  return v.slice(0, half).map(adjustEnergy)
}

export const getFrequencyDomain = (xs, { applyWindow, adjustEnergy, isHalved = false, samplingFrequency }) => {
  const windowed = applyWindow(xs)
  const fft = computeFFT([...windowed])
  const adjusted = adjustEnergy(fft, isHalved)
  const freqAtIndex = frequencyHertz(fft, samplingFrequency)
  return { windowed, fft, adjusted, freqAtIndex }
}

export const addStats = ({ windowed, fft, adjusted, freqAtIndex }) => {
  const FTT_SAMPLE = 150

  const magnitudes = adjusted.map(magnitude)
  const maxMag = maxAbs(magnitudes).max
  const rows = adjusted.map(([rl, im], i) => {
    return [freqAtIndex(i), rl, im, magnitude([rl, im]) / maxMag, phase(rl, im)]
  })
  const withStats = { rows, fields: 'freq,real,imag,mag,pha'.split(',') }

  const frequencyAndMagnitude = (d) => {
    return [d[0], d[3]]
  }
  const freqMag = withStats.rows.slice(0, FTT_SAMPLE).map(frequencyAndMagnitude)
  return { freqMag, withStats }
}
