import styled from 'styled-components'

export const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin-left: -8px;
  margin-right: -8px;
`

export const ListItem = styled.div`
  color: #4e4e4e;
  width: 280px;
  margin: 8px;
`
