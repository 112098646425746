import { getFrequencyDomain, firstHalfWithEnergyAdjustment, maxAbs, magnitude } from '@mmd/fns/signal-fft'
import { noWindowing } from '@mmd/fns/signal-windowing'
import { permutateRowsAndColumns } from './utils'

export const dinPoint = (xs, samplingFrequency = 1000) => {
  const { max: maxPPV /*, index: indexPPV*/ } = maxAbs(xs)
  const { adjusted, freqAtIndex } = getFrequencyDomain(xs, { applyWindow: noWindowing, adjustEnergy: firstHalfWithEnergyAdjustment, samplingFrequency })
  const magnitudes = adjusted.map(magnitude)

  return {
    freq: freqAtIndex(maxAbs(magnitudes).index),
    value: Math.abs(maxPPV),
  }
}

export const intoDinPoints = ({ fields, rows, samplingFrequency = 1000 }) => {
  //  const [idxName, ...channelNames] = fields
  const [idxN, ...channels] = permutateRowsAndColumns(rows, (row) => {
    const [idx, ...channels] = row
    const mapped = channels.map(parseFloat)
    return [idx, ...mapped]
  })
  return channels.map((column) => dinPoint(column, samplingFrequency))
}
