import { rangeGetter, splitDs, getChannels, asInt } from '@mmd/fns/tlm/utils'

export const isBurst = (tlm) => {
  const fst = (tlm.n_raw_data || '').split('|').shift()
  return parseInt(fst, 10) > 0
}

export const getBurstData = (tlm) => {
  return splitDs(tlm.raw_data)
}
export const getBurstChannels = (tlm) => {
  let channels = getChannels(tlm)
  let nraw = splitDs(tlm.n_raw_data, asInt)
  let slicer = rangeGetter(0)
  return channels.map((d, i) => {
    return { ...d, spread: slicer.next(nraw[i]) }
  })
}

export const pickValueAddPosition = (arr) => {
  if (!Array.isArray(arr)) {
    return []
  }
  return arr.map((d) => {
    const { name, data } = d
    return {
      name,
      data: data.map((d, i) => {
        return [d[1], i]
      }),
    }
  })
}

export const burstAsTable = (arr, fs = 1000, startTime = 0) => {
  if (!Array.isArray(arr)) {
    return { fields: [], rows: [] }
  }
  const rows = arr[0].data.map((d, i) => {
    const e = arr[1].data[i]
    return [startTime + i * fs /*, d[0], e[0] */]
  })
  const fieldNames = arr.map((d) => d.name)
  const fields = ['isotime', ...fieldNames]
  return { fields, rows }
}

export const permutateRowsAndColumns = (rows, onEach) => {
  return rows.reduce((acc, row, ri) => {
    row = typeof onEach === 'function' ? onEach(row) : row
    if (!Array.isArray(row)) {
      throw new Error(`[Error] Each row is expected to be an array. This one is not: ${row}`)
    }
    row.forEach((d, ci) => {
      if (!acc[ci]) {
        acc[ci] = []
      }
      acc[ci][ri] = d
    })
    return acc
  }, [])
}
