export const table = [
  {
    slug: 'modyva',
    i18n: 'Modyva',
    role: ['recPBUdz726z0L5up'],
    pass: 'mod091118*',
    status: 'active',
    url_latest: 'https://staging.recovib.io/modyva-v-1-2-0-RC-2/?role=admin&user=modyva',
    url_production: 'http://modyva.recovib.io/',
    _id: 'reckRR73LtcRDabZW',
  },
  {
    slug: 'lhoist',
    i18n: 'Lhoist',
    role: ['recoo5j79K6YnGNAu'],
    pass: 'mod091118*',
    status: 'active',
    url_latest: 'https://staging.recovib.io/modyva-v-1-2-0-RC-2/?role=admin&user=lhoist',
    url_production: 'http://modyva.recovib.io/',
    _id: 'recP5J5z1roKrDmE4',
  },
  {
    slug: 'graziano.piragine@lhoist.com',
    i18n: 'Graziano Piragine',
    role: ['recoo5j79K6YnGNAu'],
    pass: 'Brigmin@Lhoist*1122',
    status: 'active',
    url_latest: 'https://staging.recovib.io/modyva-v-1-2-0-RC-2/?role=admin&user=lhoist',
    url_production: 'http://modyva.recovib.io/',
    _id: 'recP5J5z1roKrDmE4-custom',
  },
]
