import React from 'react'
import styled from 'styled-components'
import { HSpacer } from '@mmd/design-layout'
import Button from '@mmd/design-system/button'
import moment from 'moment'

import DayPickerBase from 'react-day-picker'

import { enumFromArray } from '@mmd/fns/enum'
import { whenAction } from '@mmd/block-recovib/_block-action'

export const Action = enumFromArray('YMD_RANGE'.split('|'))

const Layout = styled.div``
const Picker = styled.div`
display: flex
flex-direction: row;
justify-items: center;
align-items: center;

`

const DayPicker = styled(DayPickerBase)`
	.DayPicker-Day--selectedRange {
		background-color: #4a90e2 !important;
		color: #f0f8ff !important;
	}

	/* DayPicker styles */

	.DayPicker-wrapper {
		position: relative;
		display: flex;
		flex-direction: row;
		user-select: none;
	}

	.DayPicker-Months {
	}

	.DayPicker-Month {
		display: flex;
		flex-direction: row;
		border-collapse: collapse;
		user-select: none;
	}

	.DayPicker-NavBar {
	}

	.DayPicker-NavButton {
		display: inline-block;
		width: 1.25em;
		height: 1.25em;
		background-position: center;
		background-size: 50%;
		background-repeat: no-repeat;
		color: #8b9898;
		cursor: pointer;
	}

	.DayPicker-NavButton:hover {
		opacity: 0.8;
	}

	.DayPicker-NavButton--prev {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
	}

	.DayPicker-NavButton--next {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
	}

	.DayPicker-NavButton--interactionDisabled {
		display: none;
	}

	.DayPicker-Caption {
		padding: 0 0.8em;
		text-align: left;
	}

	.DayPicker-Weekdays {
		display: none;
	}

	.DayPicker-WeekdaysRow {
		display: flex;
	}

	.DayPicker-Weekday {
		padding: 0.5em;
		color: #8b9898;
		text-align: center;
		font-size: 0.875em;
	}

	.DayPicker-Weekday abbr[title] {
		border-bottom: none;
		text-decoration: none;
	}

	.DayPicker-Body {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.DayPicker-Week {
	}

	.DayPicker-Day {
		display: inline-block;
		padding: 0 0.2em;
		border-radius: 50%;
		vertical-align: middle;
		text-align: center;
		cursor: pointer;
	}

	.DayPicker--interactionDisabled .DayPicker-Day {
		cursor: default;
	}

	.DayPicker-Footer {
		padding-top: 0.5em;
	}

	.DayPicker-TodayButton {
		border: none;
		background-color: transparent;
		background-image: none;
		box-shadow: none;
		color: #4a90e2;
		font-size: 0.875em;
		cursor: pointer;
	}

	/* Default modifiers */

	.DayPicker-Day--today {
		color: #d0021b;
		font-weight: 700;
	}

	.DayPicker-Day--outside {
		color: #8b9898;
		cursor: default;
	}

	.DayPicker-Day--disabled {
		color: #dce0e0;
		cursor: default;
		/* background-color: #eff1f1; */
	}

	/* Example modifiers */

	.DayPicker-Day--sunday {
		background-color: #f7f8f8;
	}

	.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
		color: #dce0e0;
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
		position: relative;

		background-color: #4a90e2;
		color: #f0f8ff;
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
		background-color: #51a0fa;
	}

	.DayPicker:not(.DayPicker--interactionDisabled)
		.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
		background-color: #f0f8ff;
	}

	/* DayPickerInput */

	.DayPickerInput {
		display: inline-block;
	}

	.DayPickerInput-OverlayWrapper {
		position: relative;
	}

	.DayPickerInput-Overlay {
		position: absolute;
		left: 0;
		z-index: 1;

		background: white;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	}
`

const PeriodPicker = styled.div``

function getWeekDays(weekStart) {
	const days = [weekStart]
	for (let i = 1; i < 7; i += 1) {
		days.push(moment(weekStart).add(i, 'days').toDate())
	}
	return days
}

function getWeekRange(date) {
	return {
		from: moment(date).startOf('isoWeek').toDate(),
		to: moment(date).endOf('isoWeek').toDate(),
	}
}

class DatePickerWithWeekSelection extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hoverRange: undefined,
			selectedDays: [],
			isWeekMode: false,
		}
		this.bound = {
			onDayChange: (date) => {
				const isWeekMode = this.state.isWeekMode
				const selectedDays = isWeekMode ? getWeekDays(getWeekRange(date).from) : [date]
				this.setState({ selectedDays })
				whenAction(
					{
						type: Action.YMD_RANGE,
						payload: {
							start: selectedDays[0],
							end: isWeekMode ? selectedDays.slice(-1).shift() : undefined,
							isSingleDay: isWeekMode ? undefined : true,
						},
					},
					this
				)
			},
			onDayEnter: (date) => {
				this.setState({
					hoverRange: getWeekRange(date),
				})
			},
			onDayLeave: () => {
				this.setState({
					hoverRange: undefined,
				})
			},
			onWeekModeToggle: (event) => {
				const { mode } = event.currentTarget.dataset
				const isWeekMode = mode === 'true' ? true : false
				this.setState({ isWeekMode, selectedDays: [] })
			},
		}
	}

	render() {
		const { hoverRange, selectedDays, isWeekMode } = this.state
		const { onDayChange, onDayEnter, onDayLeave, onWeekClick, onWeekModeToggle } = this.bound
		const { showWeeks } = this.props

		const daysAreSelected = selectedDays.length > 0

		const modifiers = {
			hoverRange,
			selectedRange: daysAreSelected && {
				from: selectedDays[0],
				to: selectedDays[6],
			},
			hoverRangeStart: hoverRange && hoverRange.from,
			hoverRangeEnd: hoverRange && hoverRange.to,
			selectedRangeStart: daysAreSelected && selectedDays[0],
			selectedRangeEnd: daysAreSelected && selectedDays[6],
		}

		return (
			<Layout>
				<Picker>
					<DayPicker
						selectedDays={selectedDays}
						showOutsideDays
						modifiers={modifiers}
						onDayClick={onDayChange}
						onDayMouseEnter={onDayEnter}
						onDayMouseLeave={onDayLeave}
						firstDayOfWeek={1}
						onWeekClick={isWeekMode ? onWeekClick : undefined}
					/>
					<HSpacer size={16} />
					{showWeeks && (
						<PeriodPicker>
							<Button primary={isWeekMode ? undefined : true} data-mode={false} onClick={onWeekModeToggle}>
								Single Day
							</Button>
							<Button primary={isWeekMode ? true : undefined} data-mode={true} onClick={onWeekModeToggle}>
								Full Week
							</Button>
						</PeriodPicker>
					)}
				</Picker>
			</Layout>
		)
	}
}

export default DatePickerWithWeekSelection

/*


*/
