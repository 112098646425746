import { asActionType } from '@mmd/flow/action-payload'
import { expandAccount } from '@mmd/domain-recovib/account'
import { getUrlParam, getSubdomain } from '@mmd/io/window-location'
import * as action from './ActionType'
// import roleRules from './rbac-rules'
// import canPerformWithRules from '@mmd/domain/flow/rbac/canPerformWithRules'
import { reduceLoginRequest, reduceLogoutSuccess } from './site-login'
import { dispatchTimeRangeChange, dispatchAccountActive } from './monitor-stat'
import { getBackoffice } from '../globals'

// ############################################################
// ## Globals
// ############################################################

// const shopItemsSelector = state => state.shop.items
// const taxPercentSelector = state => state.shop.taxPercent

// ############################################################
// ## Globals
// ############################################################

// import { initialize as initializeLens } from '@mmd/domain-recovib/lens/Singleton'

export const dispatchInitializeBackOffice = () => {
  const signialInitializeBackOffice = asActionType(action.INITIALIZE_BACKOFFICE)
  return (dispatch) => dispatch(signialInitializeBackOffice())
}

/*
https://tenant.recovib.io/lhoist-quarry-marchelesdames/c767e53d-a805-5a93-ba83-47dd287d83d5-raw/quarters/q2-2019.json
*/

// ############################################################
// ## Affiliate
// ############################################################

export const affiliateProps = [
  (state) => {
    const { accounts, accountActive } = state.app
    return { accounts, accountActive }
  },
  (dispatch, ownProps) => ({
    whenAccountActive: (activeAccount) => dispatch(dispatchAccountActive(activeAccount)),
  }),
]

// ############################################################
// ## Route
// ############################################################

export const pageProps = [
  (state) => {
    const { activeLens } = state.lens
    const { login, permissions, role, screeName, branding } = state.app
    const { user, authError } = login
    return { user, authError, permissions, role, screeName, branding, activeLens }
  },
]

// ############################################################
// ## Payload dispatchers
// ############################################################

export const dispatchLens = (slug) => {
  const signal = asActionType(action.LENS_ACTIVE_CHANGE)
  return (dispatch) => dispatch(signal(slug))
}

// ############################################################
// ## State Reducers
// ############################################################

// ############################################################
// ## Props Injectors
// ############################################################

export const accountProps = [
  (state) => {
    const { accounts, affiliation } = state.app
    const backoffice = getBackoffice()
    return { accounts, expandAccount: expandAccount(backoffice), affiliation }
  },
  (dispatch, ownProps) => ({
    whenLens: (slug) => dispatch(dispatchLens(slug)),
  }),
]

// ############################################################
// ## Reduce Store
// ############################################################

export const reduceInitializeBackOffice = (state, payload) => {
  return state
}

/*
export const blockReducer = (store = { lens: undefined, blocks: {} }, action) => {
  let diff
  const { type, payload } = action
  switch (type) {
    case 'REGISTER_BLOCK': {
      diff = undefined
      break
    }
    case 'SET_LENS': {
      const lens = initializeLens(payload)
      diff = { lens }
      break
    }

    default:
      diff = undefined
      break
  }
  // this needs to be a *different* object to trigger refreshes
  return diff ? { ...store, ...diff } : store
}
*/

// ############################################################
// ## Combined Reducers
// ############################################################

export const appReducer = [
  {
    branding: getSubdomain() || getUrlParam('affiliate') || 'micromega',
    route_active: '/first',
    login: { authError: 'Please Login', user: undefined },
    permissions: {},
    backoffice: undefined,
  },
  {
    [action.INITIALIZE_BACKOFFICE]: reduceInitializeBackOffice,
    [action.LOGIN_REQUEST]: reduceLoginRequest,
    [action.LOGOUT_SUCCESS]: reduceLogoutSuccess,
  },
]
