export const isolateProps = (obj, props) => {
  let out = {}
  Object.entries(obj).forEach(([k, v]) => {
    if (props.includes(k)) {
      out[k] = v
    }
  })
  return out
}

export const packetId = (tlm) => tlm.id

export const cosmos = (tlm) => isolateProps(tlm, ['id', '_rid', '_ts', '_self', '_etag', '_attachments'])

// retro compat
export const retroTime = (tlm) => {
  if (tlm && tlm.channels) {
    return tlm.channels[0].acq_dt
  }
}

export const packetTime = (tlm) => tlm.msg_dt
export const tlmTime = (tlm) => tlm.acq_dt || retroTime(tlm)
export const tlmPeriod = (tlm) => parseInt(tlm.p_stats, 10) || 30
export const tlmApp = (tlm) => tlm.id.split('-').shift()

export const packetType = (tlm) => tlm.type
export const workspace = (tlm) => isolateProps(tlm, ['name', 'location'])
export const firmware = (tlm) => isolateProps(tlm, ['serial_number', 'n_channels'])
export const tlmGeo = (tlm) => isolateProps(tlm, ['latitude', 'longitude'])
export const tlmProcessing = (tlm) => isolateProps(tlm, ['remote_processing'])
export const tlmType = (tlm) => isolateProps(tlm, ['tlm_type', 'type', 'n'])

export const tlmEvents = (tlm) => (tlm.events && tlm.events.length ? tlm.events : null)
