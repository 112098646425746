import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Padding, VSpacer } from '@mmd/design-layout'

const Site = styled.div`
  position: relative;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  border-sizing: border-box;
  min-width: 1240px;
`

const Page = styled.div`
  display: flex;
  margin: 0px 8px;
`
const Body = styled(Padding)`
  flex: 1;
  background-color: #fbfbfb;
  margin: 0 8px;
`

export const propTypes = {
  /* helmet: PropTypes.number */
}

const SinglePageApp = props => {
  const { helmet, children, masthead /*, cookienotice*/, footer } = props
  return (
    <Fragment>
      {/*<NoticeOverlay>{cookienotice}</NoticeOverlay> */}
      <Site>
        {masthead}
        <Body all={0}>
          <VSpacer height={48} />
          <Page>{children}</Page>
          <VSpacer height={64} />
        </Body>
        {footer}
        {helmet}
      </Site>
    </Fragment>
  )
}
SinglePageApp.propTypes = propTypes

export default SinglePageApp

// https://medium.com/mtholla/build-a-framework-free-dashboard-using-css-grid-and-flexbox-53d81c4aee68
