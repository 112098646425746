import React, { Component } from 'react'
import styled from 'styled-components'

const Nav = styled.nav`
  display: flex;
`

const Main = styled.div``
const RightAnchored = styled.div`
  margin-left: auto;
`

class SplitNav extends Component {
  render() {
    const { main, right } = this.props
    return (
      <Nav>
        <Main>{main}</Main>
        <RightAnchored>{right}</RightAnchored>
      </Nav>
    )
  }
}

SplitNav.propTypes = {}
export default SplitNav
